import { Observable, BehaviorSubject, Subscription, observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, LoginState } from '../services/auth/auth.service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';

// Checks if the user is authenticated
// f.e in routes:
// const routes: Routes = [
//   { path: '', component: DummyComponent, canActivate: [ AuthGuard ] },
//   { path: 'dummy1', component: DummyComponent, canActivate: [ AuthGuard ] },
//   { path: 'dummy2', component: Dummy2Component, canActivate: [ AuthGuard ] },
//   { path: 'auth/callback', component: CallbackComponent },
//   { path: '**', redirectTo: '' }
// ];
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private sub: Subscription;

  constructor(private auth: AuthService,
              private router: Router) {}

  hasPermissions(): boolean {
      if ('permission' in environment) {
        if (!this.auth.userHasPermissions([environment['permission']])) {
            return false;
        }
      }
      return true;
  }

  canActivate(): Observable<boolean> {
    const url = window.location.pathname;
    return new Observable<boolean>((observer) => {
      if (this.auth.getLoginState() === LoginState.LOGGING_IN ||
          this.auth.getLoginState() === LoginState.LOGGING_OUT) {
        this.sub = this.auth.getLoginStateSubject().subscribe((loginState) => {
          if (loginState === LoginState.LOGGED_IN) {
            console.log('Authguard (observed) returning ', LoginState[loginState]);
            this.sub.unsubscribe();
            if (!this.hasPermissions()) {
                this.auth.logout();
            }
            observer.next(true);
          }
          if (loginState === LoginState.LOGGED_OUT) {
            console.log('Authguard (observed) returning ', LoginState[loginState]);
            this.sub.unsubscribe();
            this.auth.login(url);
            observer.next(false);
          }
        });
      } else {
        console.log('Authguard returning ', LoginState[this.auth.getLoginState()]);
        if (this.auth.getLoginState() !== LoginState.LOGGED_IN) {
            this.auth.login(url);
        }
        else if (!this.hasPermissions()) {
            this.auth.logout();
        }
        else {
            observer.next(true);
        }
      }
    });
  }
}
