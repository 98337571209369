import { Component, OnInit, OnDestroy } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as dutch } from './i18n/nl';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ProjectParent } from '../parent/project.parent';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ProjectService } from 'app/lib/services/project/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SmartlabPfdUnitLinksWhere } from 'app/graphql';
import { environment } from 'environments/environment';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';
import { Block } from 'app/lib/interfaces';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { Tag } from 'app/lib/interfaces/labo.interface';
import { BrandingService } from 'app/lib/services/branding/branding.service';
import { NgxCumulioService } from 'ngx-cumulio';
import { Subscription } from 'rxjs';

declare let Cumulio: any;

@Component({
    selector: 'wtl-technologies',
    templateUrl: './technologies.component.html',
    styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent extends ProjectParent implements OnInit, OnDestroy {

    private cumulioRefreshSubscriptions: Subscription[] = [];

    public dataLoaded = false;

    public projectUnit: Block;
    public pfdUnitLinks: SmartlabPfdUnitLinksWhere.SmartlabPfdUnitLinks[] = [];

    public projectCode = "";
    public technology = "";
    public planttype = "";
    public utilitytype = "";

    public currentTokens = new Map();
    public currentKeys = new Map();
    public blocks = '';
    public tags = '';

    private refreshDashboardsDataTimeout: any;

    public loadingText = "";

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        public brandingService: BrandingService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        _activatedRoute: ActivatedRoute,
        _router: Router,
        _fuseNavigationService: FuseNavigationService,
        public projectService: ProjectService,
        _spinner: NgxSpinnerService,
        _projectService: ProjectService,
        _smartlabService: SmartlabService,
        _authService: AuthService,
        private ngxCumulioService: NgxCumulioService,
    ) {
        super(brandingService, _activatedRoute, _router, _fuseNavigationService, projectService, _spinner, _smartlabService, _authService)
        this._fuseTranslationLoaderService.loadTranslations(english, dutch);
        // this.refreshDashboardsData();
    }

    ngOnInit() {
        super.initProject().subscribe(
            project => {
                // load your dashboard here
                this._activatedRoute.paramMap.subscribe((params: ParamMap) => {

                    this.dataLoaded = false;

                    console.log(`I get here`);

                    this.projectUnit = null;
                    this.pfdUnitLinks = [];

                    this.projectCode = params.get("project");
                    this.technology = params.get("technology");
                    this.planttype = params.get("planttype");
                    this.utilitytype = params.get("utilitytype");

                    console.log(`projectcode:`, this.projectCode);
                    console.log(`technology:`, this.technology);
                    console.log(`planttype:`, this.planttype);
                    console.log(`utilitytype:`, this.utilitytype);

                    this.blocks = this.technology;

                    if (this.planttype !== null) {

                        switch (this._projectService.cachedProject.type.toUpperCase()) {
                            case "WRP":
                                this.technology = "171";
                                break;
                            case "WWTP":
                                this.technology = "170";
                                break;
                            case "WTWP":
                                this.technology = "170";
                                break;
                            case "AD":
                                this.technology = "1537";
                                break;
                            default:
                                break;
                        }
                    }

                    if (this.utilitytype !== null) {
                        this.technology = "133";
                    }

                    // Using timeout to force a refresh upon changing the technology
                    setTimeout(() => {
                        this.loadData();
                    }, 100);

                });

            },
            err => {
                // error shown in super
            }
        );
    }

    async loadData() {
        if (!this._projectService.currentUpdateIsRunning && this._projectService.cachedProject) {

            this._spinner.show("technologiesSpinner");

            if (this.planttype !== null) {
                // if override
                if (this.brandingService.brand.PlantOverrideWithPFDUnitId && this.brandingService.brand.PlantOverrideWithPFDUnitId !== '') {
                    // get technology for PFD Unit ID
                    this.pfdUnitLinks = this._projectService.getPFDUnitLinks(this.brandingService.brand.PlantOverrideWithPFDUnitId);
                } else {
                    this.pfdUnitLinks = this._projectService.getPFDUnitLinks(this.technology);
                }
            } else if (this.utilitytype !== null) {
                this.pfdUnitLinks = this._projectService.getPFDUnitLinks(this.technology);
            } else {
                this.projectUnit = this._projectService.getProjectUnit(this.technology);
                this.pfdUnitLinks = this._projectService.getPFDUnitLinks(this.projectUnit.definitionId.toString());
            }

            console.log(`technology:`, this.pfdUnitLinks);

            // filter out PFDTagLinks which are disabled
            console.log(`technology: cachedProjectPFDTags`, this._projectService.cachedProjectPFDTags);

            const filteredPfdUnitLinks: SmartlabPfdUnitLinksWhere.SmartlabPfdUnitLinks[] = [];

            // loop the pfd unit links
            this.pfdUnitLinks.forEach(unitLink => {
                // make a marker to disable the pfd unit link tab when requirements are met
                var markerForDisabling = false;
                // check if a PFDTagLink is set
                if (unitLink.PFDTagLink) {

                    console.log(`technology: PFDTagLink`, unitLink);

                    // loop all the cached projectPFDTags and check if the PFDTagLink matches the projectPFDTag definition ID and if so, if it is disabled

                    this._projectService.cachedProjectPFDTags.forEach((projectPFDTag: Tag, key: number) => {
                        if (unitLink.PFDTagLink === projectPFDTag.definitionId.toString()) {
                            console.log(`technology: PFDTagLink + projectPFDTag FOUND`, projectPFDTag);
                            if (unitLink.PFDTagLink === projectPFDTag.definitionId.toString() && !projectPFDTag.enabled) {
                                console.log(`technology: projectPFDTag is disabled`, projectPFDTag);
                                markerForDisabling = true; // disable the tab
                            }
                        }
                    })

                    // do not push in case the project PFDTag was disabled
                    if (!markerForDisabling) {
                        filteredPfdUnitLinks.push(unitLink);
                    }
                } else {
                    // push the unitLink to show a tab ( no PFDTagLink )
                    filteredPfdUnitLinks.push(unitLink);
                }
            });

            this.pfdUnitLinks = filteredPfdUnitLinks;

            for (const key in this.pfdUnitLinks) {
                if (this.pfdUnitLinks.hasOwnProperty(key)) {
                    const pfdUnitLink = this.pfdUnitLinks[key];
                    if (!this.currentTokens.has(pfdUnitLink.Link)) {
                        try {
                            this.loadingText = `Loading "${pfdUnitLink.Name}"`
                            var token = await this._smartlabService.getCumulioTokenForDashboard(this._projectService.cachedProject.code, pfdUnitLink.Link, null, this.blocks);
                            console.log(`Set token: "${token.token}" with key: "${token.key}" for dashboardId: "${pfdUnitLink.Link}"`, token);
                            this.currentTokens.set(pfdUnitLink.Link + this.technology, token.token);
                            this.currentKeys.set(pfdUnitLink.Link + this.technology, token.key);
                        } catch (error) {
                            console.log(`Error fetching token:`, error);
                        }
                    }
                }
            }

            this.dataLoaded = true;

        } else {
            setTimeout(() => {
                this.loadData();
            }, 400);
        }
    }

    loadTab(tabIndex) {
        // Cumulio.addDashboard({
        //   dashboardId: 
        //       tabIndex == 0 ? 'eb74b56c-3e79-41b9-a8e9-122a97d0a22f' :(
        //       tabIndex == 1 ? 'e6f9fe6b-0c9d-4757-b0b3-6ef181520f72' :(
        //                       '80515eee-10cc-48dc-b099-f2f7bf58e0ad')),
        //   container: '#dashboard' + tabIndex,
        //   key: environment.cumulio.key,
        //   token: environment.cumulio.token
        // });
    }

    refreshDashboardsData() {

        console.log(`refreshDashboardsData`, this.cumulioRefreshSubscriptions.length);

        this.cumulioRefreshSubscriptions.forEach(subscription => {
            console.log(`unsubscribe`);
            subscription.unsubscribe();
        });

        this.cumulioRefreshSubscriptions = [];

        const subscription = this.ngxCumulioService.refreshData().pipe(
            // chain this subscription to perform some action
        ).subscribe();  // Also unsubscribe it in ngOnDestroy

        this.cumulioRefreshSubscriptions.push(subscription);

        // if (this.pfdUnitLinks) {
        //     this.pfdUnitLinks.forEach(pdfUnitLink => {

        //         console.log(`subscribe`, pdfUnitLink.Link);

        //         const subscription = this.ngxCumulioService.refreshData(pdfUnitLink.Link).pipe(
        //             // chain this subscription to perform some action
        //         ).subscribe();  // Also unsubscribe it in ngOnDestroy

        //         this.cumulioRefreshSubscriptions.push(subscription);
        //     });
        // }

        this.refreshDashboardsDataTimeout = setTimeout(() => {
            this.refreshDashboardsData();
        }, 120000);
    }

    ngOnDestroy() {

        console.log(`destroy`);

        if (this.refreshDashboardsDataTimeout) {
            clearTimeout(this.refreshDashboardsDataTimeout);
        }

        this.cumulioRefreshSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
}
