<div class="page-layout simple fullwidth">

    <ng-container *ngIf="laboData && laboData.dataLoaded">
        <div class="date_select_container">
            <span>
                <button mat-mini-fab color="accent" aria-label="previous" (click)="reloadDayEvent.emit(-1)" [style.background]="brandingService.getPrimaryColor()">
                    <mat-icon class="date_button_icon">chevron_left</mat-icon>
                </button>
                <span class="date-select-title">
                    <!-- {{ selectedDateString | date : 'EEEE'}}
                    {{ selectedDateString | date : 'dd-MM-yyyy'}} (Week {{ selectedDateString | week }})-->

                    <nz-date-picker nzSize="large" class="date-picker" nzAllowClear="false" [(ngModel)]="laboData == null ? '' : laboData.selectedDateString"
                    (ngModelChange)="reloadDayEvent.emit($event)" nzFormat="EEEE dd-MM-yyyy '(Week' ww')'"></nz-date-picker>
                </span> 
                <button mat-mini-fab color="accent" aria-label="next" (click)="reloadDayEvent.emit(1)" [style.background]="brandingService.getPrimaryColor()">
                    <mat-icon class="date_button_icon">chevron_right</mat-icon>
                </button>
            </span>
        </div>
    </ng-container>

    <div *ngIf="!laboData || !laboData.dataLoaded" class="center-spinner-tab" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>

    <div #labTableContainer class="lab_table_container">

        <div *ngIf="laboData && laboData.dataLoaded && laboData.noSchedule" class="no-data-message" fxLayout="row" fxLayoutAlign="center center">
            <div class="no-data-message-icon">
                <mat-icon color="accent">warning</mat-icon>
            </div>
            <div class="no-data-message-content">There was no State planned for this day.</div>
        </div>

        <nz-table class="lab_table" nzShowPagination="false" *ngIf="laboData && laboData.dataLoaded && !laboData.noSchedule"
            [nzData]="laboData.samplingPointsArrayFiltered" [nzScroll]="getNzScrollObject()">
            <thead>
                <tr>
                    <th class="lab_left_upper_corner" nzWidth="150px" nzLeft="0px">
                        <div class="lab_left_upper_corner_content"></div>
                    </th>
                    <ng-container *ngFor="let samplingPoint of laboData.samplingPointColumns | keyvalue: sortColumnByOrder">
                        <ng-container *ngFor="let date of samplingPoint.value.dates | keyvalue">
                            <th nzWidth="150px" class="lab_row_headers">
                                <div class="lab_row_headers_content">
                                    {{ samplingPoint.value.name }}
                                </div>
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th class="lab_left_upper_corner" nzWidth="150px" nzLeft="0px">
                        <div class="lab_left_upper_corner_content"></div>
                    </th>
                    <ng-container *ngFor="let samplingPoint of laboData.samplingPointColumns | keyvalue: sortColumnByOrder">
                        <ng-container *ngFor="let date of samplingPoint.value.dates | keyvalue">
                            <th nzWidth="150px" class="lab_row_headers">
                                <ng-container
                                    *ngIf="_authService.userHasPermissions(['update:labo']); else noUpdatePermissionTime">
                                    <div class="lab_row_headers_content">
                                        <nz-time-picker class="timePickerLabInput" nzAllowEmpty="false" nzSize="large"
                                            (nzOpenChange)="putTime(samplingPoint.value.idProj, samplingPoint.value.planned, date.key, $event)"
                                            [(ngModel)]="date.value" nzFormat="HH:mm"></nz-time-picker>
                                    </div>
                                </ng-container>
                                <ng-template #noUpdatePermissionTime>
                                    <div class="lab_row_headers_content">
                                        <span class="no_update_time">{{ date.value | date : 'HH:mm'}}</span>
                                        <span [style.margin-left]="'5px'"></span><i nz-icon nzType="clock-circle"
                                            nzTheme="outline" style="font-size: 16px;"></i>
                                    </div>
                                </ng-template>
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                <!-- <tr>
                    <th class="lab_left_upper_corner" nzWidth="150px" nzLeft="0px"><div class="lab_left_upper_corner_content"></div></th>
                    <ng-container *ngFor="let samplingPoint of laboData.samplingPointColumns | keyvalue: sortColumnByName">
                        <ng-container *ngFor="let date of samplingPoint.value.dates | keyvalue">
                            <th nzWidth="150px" class="lab_row_headers">
                                <ng-container *ngIf="samplingPoint.value.default === false" >
                                    <button mat-icon-button (click)="deleteColumn(samplingPoint.value.idProj, date.key)" aria-label="back">
                                        <mat-icon color="primary">clear</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="addColumn(samplingPoint.value.idProj, date.key)" aria-label="back">
                                        <mat-icon color="primary">add</mat-icon>
                                    </button>
                                </ng-container>
                            </th>    
                        </ng-container>
                    </ng-container>
                </tr> -->
            </thead>
            <tbody>
                <tr *ngFor="let spKV of laboData.samplingPointsArrayFiltered">
                    <td nzLeft="0px" nzWidth="150px" class="lab_left_column">
                        <div class="lab_left_column_content">{{ spKV.name }}</div>
                    </td>
                    <ng-container *ngFor="let samplingPoint of laboData.samplingPointColumns | keyvalue: sortColumnByOrder">
                        <ng-container *ngFor="let date of samplingPoint.value.dates | keyvalue">
                            <td nzWidth="150px">

                                <ng-container
                                    *ngIf="checkSamplingPointContainsAnalysisPoint(samplingPoint.value.planned, spKV.idProj) && spKV.tags.get(samplingPoint.value.idProj); else notAllowedTemplate">

                                    <ng-container *ngIf="_authService.userHasPermissions(['update:labo']);">
                                        <div class="table_cell"
                                            (click)="startEdit(samplingPoint.value.idProj, samplingPoint.value.planned, date.key, spKV.tags.get(samplingPoint.value.idProj).id, $event)"
                                            *ngIf="editId !== getEditId(samplingPoint.value.planned, spKV.tags.get(samplingPoint.value.idProj).id); else editTpl">

                                            <ng-container *ngTemplateOutlet="spDataTemplate"></ng-container>

                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!_authService.userHasPermissions(['update:labo']);">
                                        <div class="no_update_table_cell">
                                            
                                            <ng-container *ngTemplateOutlet="spDataTemplate"></ng-container>

                                        </div>
                                    </ng-container>

                                    <ng-template #spDataTemplate>
                                        <span class="table_cell_value_none"
                                            *ngIf="getSamplingPointData(samplingPoint.value.idProj, samplingPoint.value.planned,date.key, spKV.tags.get(samplingPoint.value.idProj).id).value !== '-'">
                                            {{ getSamplingPointData(samplingPoint.value.idProj, samplingPoint.value.planned, date.key, spKV.tags.get(samplingPoint.value.idProj).id).value }}
                                        </span>
                                        <span class="table_cell_value"
                                            *ngIf="getSamplingPointData(samplingPoint.value.idProj, samplingPoint.value.planned, date.key, spKV.tags.get(samplingPoint.value.idProj).id).value === '-'">
                                            <b>{{ getSamplingPointData(samplingPoint.value.idProj, samplingPoint.value.planned, date.key, spKV.tags.get(samplingPoint.value.idProj).id).value }}</b>
                                        </span>
                                        <span>
                                            {{ spKV.tags.get(samplingPoint.value.idProj).unit }}
                                        </span>
                                    </ng-template>
                                </ng-container>

                                <ng-template #notAllowedTemplate>
                                    <div class="table_cell_not_allowed">x</div>
                                </ng-template>

                                <ng-template #editTpl>
                                    <span>
                                        <input type="number" step="0.01" [(ngModel)]="samplingPointEnteredValue"
                                            nz-input class="samplingpoint-edit"
                                            [value]="getSamplingPointData(samplingPoint.value.idProj, date.key, spKV.tags.get(samplingPoint.value.idProj).id).value" />
                                    </span>
                                    <span>
                                        {{ spKV.tags.get(samplingPoint.value.idProj).unit }}
                                    </span>
                                    <br />

                                    <ng-container *ngIf="!isStoringDataForSamplingPoint">
                                        <button mat-flat-button class="samplingpoint-button" color="warn"
                                            aria-label="done"
                                            (click)="deleteLabAnalysis(samplingPoint.value.idProj, samplingPoint.value.planned, date.key, spKV.tags.get(samplingPoint.value.idProj).id)">
                                            <mat-icon class="date_button_icon">cancel</mat-icon>
                                        </button>
                                        <button mat-flat-button class="samplingpoint-button" color="accent"
                                            aria-label="done"
                                            (click)="putLabAnalysis(samplingPoint.value.idProj, samplingPoint.value.planned.id, samplingPoint.value.planned.label, date.key, spKV.tags.get(samplingPoint.value.idProj).id)">
                                            <mat-icon class="date_button_icon">done</mat-icon>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="isStoringDataForSamplingPoint">
                                        <mat-progress-bar mode="buffer"></mat-progress-bar>
                                    </ng-container>
                                </ng-template>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>