import { FuseNavigation } from '@fuse/types';
import { trackByHourSegment } from 'angular-calendar/modules/common/util';

export const navigation: FuseNavigation[] = [
    {
        id: 'projects',
        title: 'Projects',
        translate: 'NAV.PROJECTS.TITLE',
        brandingTitleKey: 'MyPlantsTitle',
        type: 'item',
        icon: 'business',
        url: '/projects',
    },
    {
        id: 'selectedplant',
        title: 'Selected Plant: ...',
        // translate: 'NAV.APPLICATIONS',
        translate: 'SELECTED PLANT',
        hidden: true,
        type: 'group',
        children: [
            /*{
                id       : 'admin',
                title    : 'Admin',
                translate: 'NAV.ADMIN.TITLE',
                type     : 'collapsable',
                hidden   : true,
                icon     : 'business',
                url      : '/admin',
                children : [
                    {
                        id       : 'log',
                        title    : 'Log',
                        translate: 'NAV.LOG.TITLE',
                        type     : 'item',
                        url      : '/admin/log',
                    },
                    {
                        id       : 'requests',
                        title    : 'Requests',
                        translate: 'NAV.REQUESTS.TITLE',
                        type     : 'item',
                        url      : '/admin/requests',
                    }
                ]
            },*/
            {
                id: 'plant',
                title: 'Plant',
                translate: 'NAV.PLANT.TITLE',
                brandingTitleKey: 'PlantTitle',
                type: 'item',
                hidden: true,
                enabledForBrand: 'PlantEnabled',
                icon: 'dashboard',
                url: 'project/:project/plant/p',
            },
            {
                id: 'technologies',
                title: 'Technologies',
                translate: 'NAV.TECHNOLOGIES.TITLE',
                brandingTitleKey: 'TechnologiesTitle',
                hidden: true,
                enabledForBrand: 'TechnologiesEnabled',
                type: 'collapsable',
                icon: 'opacity',
                //url      : 'project/:project/technologies',
                children: []
            },
            {
                id: 'utilities',
                title: 'Utilities',
                translate: 'NAV.UTILITIES.TITLE',
                brandingTitleKey: 'UtilitiesTitle',
                type: 'item',
                hidden: true,
                enabledForBrand: 'UtilitiesEnabled',
                icon: 'settings_application',
                url: 'project/:project/utilities/u',
            },
            {
                id: 'laboratory',
                title: 'Laboratory',
                translate: 'NAV.LABORATORY.TITLE',
                brandingTitleKey: 'LaboratoryTitle',
                type: 'item',
                permissions: ['read:labo'],
                hidden: true,
                enabledForBrand: 'LaboratoryEnabled',
                icon: 'colorize',
                url: 'project/:project/laboratory',
            },
            {
                id       : 'maintenance',
                title    : 'Maintenance',
                translate: 'NAV.MAINTENANCE.TITLE',
                brandingTitleKey: 'MaintenanceTitle',
                type     : 'item',
                hidden   : true,
                enabledForBrand: 'MaintenanceEnabled',
                icon     : 'build',
                permissions: ['upcomingfeatures'],
                url      : 'project/:project/maintenance',
            },
            {
                id       : 'file-manager',
                title    : 'Reports',
                translate: 'NAV.REPORTS.TITLE',
                brandingTitleKey: 'ReportsTitle',
                type     : 'item',
                hidden   : true,
                enabledForBrand: 'ReportsEnabled',
                icon     : 'assignment',
                permissions: ['read:reports'],
                url      : 'project/:project/reports',
            },
            {
                id       : 'alerts',
                title    : 'Alerts',
                translate: 'NAV.ALERTS.TITLE',
                brandingTitleKey: 'AlertsTitle',
                type     : 'item',
                icon     : 'warning',
                permissions: ['upcomingfeatures'],
                url      : '/alerts',
                hidden   : true,
                enabledForBrand: 'AlertsEnabled',
                badge    : {
                    title    : '4',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id: 'logbook',
                title: 'Logbook',
                translate: 'NAV.TODO.TITLE',
                brandingTitleKey: 'LogbookTitle',
                type: 'item',
                icon: 'check_box',
                url: '/apps/todo',
                permissions: ['upcomingfeatures'],
                hidden   : true,
                enabledForBrand: 'LogbookEnabled',
                badge: {
                    title: '3',
                    bg: '#039BE5',
                    fg: '#FFFFFF'
                }
            },
            {
                id: 'calendar',
                title: 'Calendar',
                translate: 'NAV.CALENDAR.TITLE',
                brandingTitleKey: 'CalendarTitle',
                type: 'item',
                hidden: true,
                enabledForBrand: 'CalendarEnabled',
                icon: 'today',
                url: 'apps/:project/calendar',
            },

        ]
    }
];
