import { BehaviorSubject } from 'rxjs';

export enum LoginState {
  LOGGED_IN,
  LOGGING_IN,
  LOGGING_OUT,
  LOGGED_OUT
}

export abstract class AuthService {
  abstract login(redirect?: string): void;
  abstract logout(): void;

  abstract getLoginState(): LoginState;
  abstract getLoginStateSubject(): BehaviorSubject<LoginState>;

  abstract handleLoginCallback(): void;

  abstract nagivateToRedirect(): void;

  abstract getUserProfile(): any;
  abstract getAccessToken(): Promise<string>;
  abstract getIdToken(): Promise<string>;

  abstract getIdTokenExpiryDate(): Date;

  abstract userHasPermissions(scopes: Array<string>): boolean;
}
