import { Injectable } from '@angular/core';
import { NotificationsGQL, CreateNotificationGQL, UpdateNotification, UpdateNotificationGQL } from 'app/graphql';

@Injectable({
    providedIn: 'root'
})
/*
    Service implementing the Generated Notification GraphQL Queries and Mutations,
    Use this as an entrypoint or implement it as required, for example:
    - Use this service for cached GraphQL calls, implementing observer patterns for data fetches
    - Directly access the implemented GQL (ex: when no intermediate logic is required)
    - Implement stubs here for testing
    - ...
*/
export class NotificationService {

    constructor(
        public queryNotifications: NotificationsGQL,
        public createNotification: CreateNotificationGQL,
        public updateNotification: UpdateNotificationGQL
    ) {
    }

    queryNotificationsTest() {

        this.queryNotifications.fetch().subscribe(result => {

            const error = result['error'];

            if (!error) {
                console.log(`queryNotificationsTest success:`, result);
            } else {
                console.log(`queryNotificationsTest error:`, error);
            }
        })

    }

    createNotificationTest() {

        const currentDate = new Date();

        this.createNotification.mutate({
            notificationInput: {
                text: `notification text ${currentDate.toLocaleDateString()}`,
                title: `notification title ${currentDate.toLocaleTimeString()}`
            }
        }).subscribe(result => {

            const error = result['error'];

            if (!error) {
                console.log(`createNotificationTest success:`, result);
            } else {
                console.log(`createNotificationTest error:`, error);
            }
        })

    }
}
