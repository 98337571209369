import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';
import { SmartlabUsersService } from 'app/lib/services/profile/smartlab-users.service';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';
import { AuthService } from 'app/lib/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class FuseSplashScreenService
{
    splashScreenEl: any;
    player: AnimationPlayer;

    /**
     * Constructor
     *
     * @param {AnimationBuilder} _animationBuilder
     * @param _document
     * @param {Router} _router
     */
    constructor(
        private _animationBuilder: AnimationBuilder,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _profileService: SmartlabUsersService,
        private _smartlabService: SmartlabService,
        private _authService: AuthService
    )
    {
        // Initialize
        this._init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void
    {
        // Get the splash screen element
        this.splashScreenEl = this._document.body.querySelector('#fuse-splash-screen');

        // If the splash screen element exists...
        if ( this.splashScreenEl )
        {
            // Hide it on the first NavigationEnd event
            this._router.events
                .pipe(
                    filter((event => event instanceof NavigationEnd)),
                    take(1)
                )
                .subscribe(() => {
                    // Hide it when the user profile is available
                    this.hideWhenProfileIsAvailableAndFirstGetProjectsIsDone();
                });
        }
    }

    private hideWhenProfileIsAvailableAndFirstGetProjectsIsDone() {
        if (this._profileService.profileLoaded) {
            if (this._smartlabService.firstGetProjectsDone) {
                this.hide();
            } else {
                this._smartlabService.getProjects(this._authService.getUserProfile()[`sub`]).
                then(
                  result => {
                      this.hide(); 
                  }
                )
                .catch(err => {
                  console.log(`Error fetching projects, trying again...`);
                  setTimeout(() => {
                    this.hideWhenProfileIsAvailableAndFirstGetProjectsIsDone();
                }, 1000);
                });                
            }
        } else {
            setTimeout(() => {
                this.hideWhenProfileIsAvailableAndFirstGetProjectsIsDone();
            }, 400);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void
    {
        this.player =
            this._animationBuilder
                .build([
                    style({
                        opacity: '0',
                        zIndex : '99999'
                    }),
                    animate('400ms ease', style({opacity: '1'}))
                ]).create(this.splashScreenEl);

        setTimeout(() => {
            this.player.play();
        }, 0);
    }

    /**
     * Hide the splash screen
     */
    hide(): void
    {
        this.player =
            this._animationBuilder
                .build([
                    style({opacity: '1'}),
                    animate('400ms ease', style({
                        opacity: '0',
                        zIndex : '-10'
                    }))
                ]).create(this.splashScreenEl);

        setTimeout(() => {
            this.player.play();
        }, 0);
    }
}
