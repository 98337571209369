import { GraphQLModule } from './../../../graphql.module';
import { Component } from '@angular/core';
import { AuthService, LoginState } from '../../services/auth/auth.service';

@Component({
  selector: 'wtl-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {
  constructor(public authService: AuthService, private graphQLModule: GraphQLModule) {
    // handle login when callback is opened by Auth0 login page:
    this.authService.handleLoginCallback();
    this.authService.getLoginStateSubject().subscribe((state) => {
      if (state === LoginState.LOGGED_IN) {
        // Fix for updating the subscriptions-transport-ws connectionParams headers
        this.graphQLModule.connect().finally(() => {
          this.authService.nagivateToRedirect();
        });
      }
    }, (error) => {
      this.authService.nagivateToRedirect();
    });
  }
}
