import { Component, OnInit, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { DefaultDay, Schedule } from 'app/lib/interfaces/laboScheduleState.interface';
import { DefinitionTag, SamplingPoint } from 'app/lib/interfaces/labo.interface';
import { UUID } from 'angular2-uuid';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { LaboScheduleService } from 'app/lib/services/labo-schedule/labo-schedule.service';
import { BrandingService } from 'app/lib/services/branding/branding.service';

@Component({
    selector: 'app-labo-schedule-day',
    templateUrl: './labo-schedule-day.component.html',
    styleUrls: ['./labo-schedule-day.component.scss']
})
export class LaboScheduleDayComponent implements OnInit {

    @Input() schedule: Schedule;
    @Input() daySamplingPoints: [DefaultDay];
    @Input() tagsArray: Array<DefinitionTag>;
    @Input() samplingPoints: Array<SamplingPoint>;
    @Input() selectedDayIndex: number;

    public isAddingSamplingPoint = false;
    public isCopyingDay = false;
    public selectedDayToCopy: [DefaultDay];

    public addedSamplingPoint: SamplingPoint;
    public addedSamplingPointLabel = '';
    
    constructor(
        public brandingService: BrandingService,
        private notification: NzNotificationService, 
        private _laboScheduleService: LaboScheduleService,
        public _authService: AuthService) { }

    ngOnInit() {

    }

    getSamplingPointName(samplingPointId): string {
        if(this.samplingPoints == null) {
            return ''
        }
        const samplingpointNameEntry = this.samplingPoints.find(x => x.id === samplingPointId);
        if (samplingpointNameEntry === undefined) {
            return '-';
        } else {
            return samplingpointNameEntry.name;
        }
    }

    getSamplingPointAnalysis(samplingPointId): Array<number> {
        if(this.samplingPoints == null) {
            return [];
        }
        // TODO: 
        const samplingpointNameEntry = this.samplingPoints.find(x => x.id === samplingPointId);
        if (samplingpointNameEntry === undefined) {
            return [];
        } else {
            return samplingpointNameEntry.labAnalysis.map(x => x.id);
        }
    }

    getTag(tagId): string {
        if(this.tagsArray == null) {
            return '';
        }
        const tagNameEntry = this.tagsArray.find(x => x.id === tagId);
        if (tagNameEntry === undefined) {
            return '-';
        } else {
            return tagNameEntry.name;
        }
    }

    deleteSamplingPoint(index) {
        this.daySamplingPoints.splice(index, 1);
        this.notification.success('Sampling Point deleted', ``);
    }

    handleAnalysisPointChange(samplingPointIndex, samplingPointId, checked: boolean, analysisPoint: number): void {

        const daySamplingPoint = this.daySamplingPoints[samplingPointIndex];

        console.log(`daySamplingPoint 1`, daySamplingPoint);
        console.log(`getSamplingPointAnalysis 1`, this.getSamplingPointAnalysis(daySamplingPoint.id));

        if (checked) {
            // this.notification.success(`Analysis "${this.getTag(analysisPoint)}" in the Sampling point "${this.getSamplingPointName(samplingPointId)}" was enabled"`, ``);
            daySamplingPoint.analysisIds.push(analysisPoint);
        } else {
            // this.notification.success(`Analysis "${this.getTag(analysisPoint)}" in the Sampling point "${this.getSamplingPointName(samplingPointId)}" was disabled"`, ``);
            daySamplingPoint.analysisIds.splice(daySamplingPoint.analysisIds.indexOf(analysisPoint), 1);
        }

        console.log(`getSamplingPointAnalysis 2`, this.getSamplingPointAnalysis(daySamplingPoint.id));
    }

    /* #region Add SamplingPoint Group */

    showModalAddSamplingPoint() {
        this.isAddingSamplingPoint = true;
    }

    cancelAddSamplingPoint() {
        this.addedSamplingPoint = null;
        this.addedSamplingPointLabel = '';

        this.isAddingSamplingPoint = false;
    }

    confirmAddSamplingPoint() {

        // TODO: Store

        this.isAddingSamplingPoint = false;

        this.daySamplingPoints.push({
            id: UUID.UUID(),
            samplingPointId: this.addedSamplingPoint.id,
            label: this.addedSamplingPointLabel,
            analysisIds: [...this.addedSamplingPoint.labAnalysis.map(x => x.id)]
        });

        this.addedSamplingPoint = null;
        this.addedSamplingPointLabel = '';

        this.notification.success('Sampling Point added', `The Sampling Point was added`);

    }

    /* #region Copy Day Group */

    showModalCopyDay() {
        // console.log(this.selectedDayIndex);
        this.isCopyingDay = true;
    }

    cancelCopyDay() {
        this.selectedDayToCopy = null;
        this.isCopyingDay = false;
    }

    confirmCopyDay() {

        // TODO: Store

        this.isCopyingDay = false;

        this.daySamplingPoints.splice(0,this.daySamplingPoints.length);

        this.selectedDayToCopy.forEach(sp => {
            const newSP: DefaultDay = {
                id: UUID.UUID(),
                label: sp.label,
                samplingPointId: sp.samplingPointId,
                analysisIds: [...sp.analysisIds]
            }

            this.daySamplingPoints.push(newSP);
        });

        // console.log(this.selectedDayToCopy);

        // this.daySamplingPoints.push({
        //     id: UUID.UUID(),
        //     samplingPointId: this.selectedDayToCopy.idProj,
        //     label: this.selectedDayToCopyLabel,
        //     analysisIds: [...this.selectedDayToCopy.labAnalysis]
        // });

        this.selectedDayToCopy = null;

        this.notification.success('Analysis Schedule copied', `The Analysis Schedule was copied`);

    }
}
