import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';

import { AlertsComponent } from './main/alerts/alerts.component';
import { MatSelectModule } from '@angular/material/select';
import { CallbackComponent } from './lib/views/auth/callback.component';
import { TokenInterceptor } from './lib/services/auth/interceptor.service';
import { AuthGuard } from './lib/guard/auth.guard';
import { FirebaseAuth0Service } from './lib/services/auth/firebase-auth0/firebase-auth0.service';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { TechnologiesComponent } from './main/project/technologies/technologies.component';
import { UtilitiesComponent } from './main/project/utilities/utilities.component';
import { MaintenanceComponent } from './main/project/maintenance/maintenance.component';
import { ReportsComponent } from './main/project/reports/reports.component';
import { WtlOperatorComponent } from './main/project/wtl-operator/wtl-operator.component';
import { FakeDbService } from './fake-db/fake-db.service';
import { environment } from 'environments/environment';
import { AuthService } from './lib/services/auth/auth.service';
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ProjectsComponent } from './main/projects/projects.component';
import { Auth0Service } from './lib/services/auth/auth0/auth0.service';
import { ProjectListComponent } from './main/projects/project-list/project-list.component';
import { AlertListComponent } from './main/alerts/alert-list/alert-list.component';
import { MaintenanceListComponent } from './main/project/maintenance/maintenance-list/maintenance-list.component';
import { ToolbarModule } from './layout/components/toolbar/toolbar.module';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeEnBe from '@angular/common/locales/en-BE';
import localeEnBeExtra from '@angular/common/locales/extra/en-BE';
import { GraphQLModule } from './graphql.module';
import { NotificationService } from './lib/services/notifications/notifications.service';
import { OperatorService } from './lib/services/operator/operator.service';
import { SmartlabUsersService } from './lib/services/profile/smartlab-users.service';
import { DialogloaderComponent } from './lib/components/dialogloader/dialogloader/dialogloader.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxCumulioModule } from 'ngx-cumulio';
import { LaboratoryComponent } from './main/project/laboratory/laboratory.component';

import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';

import { NZ_I18N, nl_BE, en_GB, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { LaboScheduleComponent } from './main/project/laboratory/labo-schedule/labo-schedule.component';
import { LaboInputComponent } from './main/project/laboratory/labo-input/labo-input.component';
import { LaboOverviewComponent } from './main/project/laboratory/labo-overview/labo-overview.component';
import { WeekPipe } from './lib/pipes/week.pipe';
import { LaboScheduleDayComponent } from './main/project/laboratory/labo-schedule/labo-schedule-day/labo-schedule-day.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { MapComponent } from './main/projects/map/map.component';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { ReportsService } from './lib/services/reports/reports.service';

registerLocaleData(localeEnBe, localeEnBeExtra);

@NgModule({
    declarations: [
        AppComponent,
        WeekPipe,
        TechnologiesComponent,
        UtilitiesComponent,
        MaintenanceComponent,
        LaboratoryComponent,
        LaboInputComponent,
        ReportsComponent,
        WtlOperatorComponent,
        AlertsComponent,
        ProjectsComponent,
        CallbackComponent,
        ProjectListComponent,
        AlertListComponent,
        MaintenanceListComponent,
        DialogloaderComponent,
        LaboScheduleComponent,
        LaboInputComponent,
        LaboOverviewComponent,
        LaboScheduleDayComponent,
        MapComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DeviceDetectorModule.forRoot(),
        HttpClientModule,
        HttpClientJsonpModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBHcVdGJHZl3H2aBAUepKhvCquS9nDnMCc'
        }),
        AgmJsMarkerClustererModule,
        NgZorroAntdModule,
        MomentModule,
        AppRoutingModule,
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatOptionModule,
        MatGridListModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        MatToolbarModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        ToolbarModule,
        NgxSpinnerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseConfirmDialogModule,

        // App modules
        LayoutModule,

        NgxDatatableModule,
        NgxCumulioModule.forRoot(),

        // Firebase modules
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFireAuthModule,
        GraphQLModule,
        FormsModule
    ],
    providers: [
        AngularFirestore,
        FirebaseAuth0Service,
        ReportsService,
        SmartlabUsersService,
        NotificationService,
        OperatorService,
        AuthGuard,
        AngularFireAuthModule,
        HttpClient,
        DatePipe,
        { provide: AuthService, useClass: Auth0Service },
        { provide: 'config', useValue: environment },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-BE' },
        { provide: LOCALE_ID, useValue: 'en-BE' },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: NZ_I18N, useValue: en_GB },
        {
            provide: NZ_DATE_CONFIG,
            useValue: {
                firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
            },
        }
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [

    ]
})
export class AppModule {
}
