import { AuthService } from 'app/lib/services/auth/auth.service';
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { Apollo, ApolloModule } from "apollo-angular";
import { HttpLinkModule } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { environment } from "environments/environment";

@NgModule({
  exports: [ApolloModule, HttpClientModule, HttpLinkModule]
})
export class GraphQLModule {
  private uri = environment.graphQLEndpointUrl; // <-- add the URL of the GraphQL server here

  private subscriptionClient: SubscriptionClient = null;
  private isConnected = false;

  constructor(private apollo: Apollo, private auth: AuthService) {
  }

  public connect(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.__getClient().then(client => {
          // Close actual connection
          // will give an error when we are not connected,
          // so we need to check first
          if (this.isConnected) {
            client.close(true, true);
          }
          // todo: set/unset session token in sessionStorage
          // Connect again
          (<any>client).connect();

          this.isConnected = true;

          resolve(true);
      }).catch(error => {
        resolve(false);
      });
    });
  }

  private __getClient(): Promise<SubscriptionClient> {
    return new Promise<SubscriptionClient>(resolve => {
      if (this.subscriptionClient) {
        resolve(this.subscriptionClient);
        return;
      }

      const wssEndpoint = this.uri;
      // It's required to define connectionParams as function, as otherwise the updated token is not transferred
      const connectionParams = async () => {
        const token = await this.auth.getIdToken();
        return token ? { headers: { Authorization: "Bearer " + token } } : {};
      };

      const wsLink = new WebSocketLink({
        uri: wssEndpoint,
        options: {
          connectionParams: connectionParams,
          reconnect: true
        }
      });
      this.subscriptionClient = (<any>wsLink).subscriptionClient;

      this.apollo.create({
        link: wsLink,
        cache: new InMemoryCache()
      });

      resolve(this.subscriptionClient);
    });
  }
}
