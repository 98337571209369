import { LabAnalysis } from './../../../../lib/interfaces/labo.interface';
import { BrandingService } from './../../../../lib/services/branding/branding.service';
import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { LaboService } from 'app/lib/services/labo/labo.service';
import { ProjectService } from 'app/lib/services/project/project.service';
import { DefaultDay } from 'app/lib/interfaces/laboScheduleState.interface';
import { AuthService } from 'app/lib/services/auth/auth.service';

@Component({
    selector: 'app-labo-input',
    templateUrl: './labo-input.component.html',
    styleUrls: ['./labo-input.component.scss']
})
export class LaboInputComponent implements OnInit, AfterViewInit {

    @ViewChild('labTableContainer') labTableContainer: ElementRef;

    private dataChangedSubscription: Subscription;
    @Input() dataChanged: Observable<void>;
    @Output() silentReloadEvent = new EventEmitter();
    @Output() reloadDayEvent = new EventEmitter();

    public laboData: any;

    /////// Other
    private labTableContainerNativeElement: HTMLElement;
    private labTableContainerHeight = 400;

    public tableViewLoaded = false;

    public samplingPointEnteredValue = 0.0;
    public samplingPointOriginalValue = null;
    public isStoringDataForSamplingPoint = false;

    // Variable needed for the value editor
    public editId: string | null;

    // There is a bug in time picker where it sends the closed event twice ...
    // So lets store if it is closed or not, and only allow 1 close after an open
    public hasTimeDialogClosed = true;

    constructor(
        private _projectService: ProjectService,
        private _authService: AuthService,
        public brandingService: BrandingService,
        private _laboService: LaboService) {
    }

    ngOnInit() {
        this.dataChangedSubscription = this.dataChanged.subscribe((data) => {
            this.laboData = data;
        });
    }

    fillTimePerSamplingPointWithSelectedDate() {

    }

    checkSamplingPointContainsAnalysisPoint(sp: DefaultDay, ap: number) {
        // console.log(`inputCheck:  sp`, sp);
        // console.log(`inputCheck:  ap`, ap);
        if (sp.analysisIds.includes(ap)) {
            // console.log(`inputCheck: includes`, true);
            return true;
        } else {
            // console.log(`inputCheck: includes`, false);
            return false;
        }
    }

    getSamplingPointData(idProj: number, plannedSamplingPoint: DefaultDay, oldDate, tagKey) {

        // return this.randomValueBetween(0, 100);
        // console.log(`getSamplingPointData: idProj`, idProj);
        // console.log(`getSamplingPointData: idProj`, tagKey);
        // console.log(`getSamplingPointData: this.samplingPointsData`, this.samplingPointsData);

        const returnValue = {
            found: false,
            value: `-`
        };


        if (this.laboData.samplingPointsData != null) {
            this.laboData.samplingPointsData.forEach(data => {
                if (data.idProject === idProj) {
                    data.labAnalysis.forEach(analysis => {
                        const date = this._projectService.addProjectTimeZoneOffset(new Date(analysis.date));
                        if (analysis.id === tagKey && date.toISOString() === oldDate && analysis.scheduleId === plannedSamplingPoint.id) {
                            returnValue.found = true;
                            returnValue.value = `${analysis.value}`;
                        }
                    });
                }
            });
        }

        return returnValue;
    }

    ngAfterViewInit() {
        this.checkTableViewLoaded();
    }

    checkTableViewLoaded() {

        if (this.laboData && this.laboData.dataLoaded) {
            this.labTableContainerNativeElement = this.labTableContainer.nativeElement;
            this.labTableContainerHeight = this.labTableContainerNativeElement.getBoundingClientRect().height;
            // console.log(`labTableContainer`, this.labTableContainerHeight);
            this.tableViewLoaded = true;
        } else {
            setTimeout(() => {
                this.checkTableViewLoaded();
            }, 500);
        }

    }

    randomValueBetween(from, to) {
        return Math.floor(Math.random() * 1000) + 1;
    }

    getNzScrollObject() {
        if (this.tableViewLoaded) {
            let columns = 0;
            // console.log('wwwww', this.samplingPointColumns);
            for (const col of this.laboData.samplingPointColumns.entries()) {
                columns += col[1].dates.size;
            }
            // console.log('cols: ' + columns);
            if (this.labTableContainerNativeElement) {
                // let height = (<HTMLElement>this.labTableContainer.nativeElement).getBoundingClientRect().height - 54;
                const height = document.documentElement.clientHeight - 355;
                const xCalc = columns * 50;
                return { x: `${xCalc}px`, y: `${height}px` };
            } else {
                const xCalc = columns * 120;
                return { x: `${xCalc}px`, y: `400px` };
            }
        } else {
            return { x: `1000px`, y: `1000px` };
        }
    }

    startEdit(spId: number, plannedSamplingPoint: DefaultDay, dateStr: string, tagId: number, event: MouseEvent): void {

        if (!this.isStoringDataForSamplingPoint) {
            event.preventDefault();
            event.stopPropagation();
            this.editId = this.getEditId(plannedSamplingPoint, tagId);

            const data = this.getSamplingPointData(spId, plannedSamplingPoint, dateStr, tagId);
            if (data.found) {
                this.samplingPointEnteredValue = Number(data.value);
                this.samplingPointOriginalValue = this.samplingPointEnteredValue;
            } else {
                this.samplingPointEnteredValue = 0;
                this.samplingPointOriginalValue = null;
            }

        }
    }

    getEditId(plannedSamplingPoint: DefaultDay, tagId: number) {
        return `${plannedSamplingPoint.id}#${tagId.toString()}`
    }

    // EDIT SAMPLING POINT

    deleteLabAnalysis(idProj: number, plannedSamplingPoint: DefaultDay, dateStr, tagKey) {

        const scheduleId = plannedSamplingPoint.id;
        const scheduleLabel = plannedSamplingPoint.label;

        this.isStoringDataForSamplingPoint = true;
        const value = this.samplingPointEnteredValue;

        let date = this.laboData.samplingPointColumns.get(scheduleId).dates.get(dateStr);
        date = this._projectService.removeProjectTimeZoneOffset(date);

        const values = this.getMatchingValues(idProj.toString(), dateStr);
        if (values.length === 0) {
            console.log('no values found to put time data for');
            return;
        }
        const labAnalysis = values.find(x => x.id === tagKey);

        this._laboService.deleteData(this._projectService.cachedProject.code, idProj, scheduleId, scheduleLabel, date, [labAnalysis])
            .then(() => {
                this.silentReloadEvent.emit();
                this.editId = ``;
                this.isStoringDataForSamplingPoint = false;
            })
            .catch(err => {
                this.isStoringDataForSamplingPoint = false;
            });
    }

    putLabAnalysis(idProj: number, scheduleId, scheduleLabel, dateStr, tagKey) {

        // console.log(`inputCheck: scheduleId`, scheduleId );
        // console.log(`inputCheck: scheduleLabel`, scheduleLabel );

        if(this.samplingPointOriginalValue === this.samplingPointEnteredValue) {
            this.editId = ``;
            this.isStoringDataForSamplingPoint = false;
            return;
        }

        this.isStoringDataForSamplingPoint = true;

        let date = this.laboData.samplingPointColumns.get(scheduleId).dates.get(dateStr);
        date = this._projectService.removeProjectTimeZoneOffset(date);

        const values = this.getMatchingValues(idProj.toString(), dateStr);
        let labAnalysis = values.find(x => x.id === tagKey);
        if(labAnalysis == null) {
            labAnalysis = {id: tagKey, value: this.samplingPointEnteredValue, date: null }
        }
        else {
            labAnalysis.value = this.samplingPointEnteredValue;
        }

        this._laboService.setData(this._projectService.cachedProject.code, idProj, scheduleId, scheduleLabel, date, [labAnalysis])
            .then(() => {
                this.silentReloadEvent.emit();
                this.editId = ``;
                this.isStoringDataForSamplingPoint = false;
            })
            .catch(err => {
                this.isStoringDataForSamplingPoint = false;
            });
    }

    putTime(idProj: number, plannedSamplingPoint: DefaultDay, dateStr, event) {
        if (event) { // True means that it was openened. We need the close event
            this.hasTimeDialogClosed = false;
            return;
        }

        // Check if it was already closed (see explanation above)
        if(this.hasTimeDialogClosed) {
            return;
        }
        this.hasTimeDialogClosed = true;

        const values = this.getMatchingValues(idProj.toString(), dateStr);
        if (values.length === 0) {
            console.log('no values found to put time data for');
            return;
        }
        this.isStoringDataForSamplingPoint = true;

        let fromDate = new Date(dateStr);
        let toDate = this.laboData.samplingPointColumns.get(plannedSamplingPoint.id).dates.get(dateStr);
        fromDate = this._projectService.removeProjectTimeZoneOffset(fromDate);
        toDate = this._projectService.removeProjectTimeZoneOffset(toDate);

        // If nothing changed, stop here
        if(fromDate.getTime() === toDate.getTime())
            return;

        this._laboService.updateTime(this._projectService.cachedProject.code, idProj, plannedSamplingPoint.id, plannedSamplingPoint.label, fromDate, toDate, values).then(() => {
            this.silentReloadEvent.emit();
            this.isStoringDataForSamplingPoint = false;
        })
            .catch(err => {
                this.isStoringDataForSamplingPoint = false;
            });
    }

    addColumn(idProj: string, plannedSamplingPoint: DefaultDay, dateStr) {
        const date = new Date(this.laboData.selectedDate);
        while (this.laboData.samplingPointColumns.get(idProj).dates.has(date.toISOString()) &&
            date.getDay() === (new Date(dateStr).getDay())) {
            date.setMinutes(date.getMinutes() + 1);
        }
        if (date.getDay() !== this.laboData.selectedDate.getDay()) {
            alert('Can not create a new measurement, reached the end of the day');
            return;
        }
        this.laboData.samplingPointColumns.get(idProj).dates.set(date.toISOString(), date);
    }

    deleteColumn(idProj: number, plannedSamplingPoint: DefaultDay, dateStr) {
        const values = this.getMatchingValues(idProj.toString(), dateStr);
        if (values.length === 0) {
            console.log('no values found to remove');
            this.laboData.samplingPointColumns.get(idProj.toString()).dates.delete(dateStr);
            return;
        }

        this.isStoringDataForSamplingPoint = true;

        let removeDate = new Date(dateStr);
        removeDate = this._projectService.removeProjectTimeZoneOffset(removeDate);

        this._laboService.deleteData(this._projectService.cachedProject.code, idProj, plannedSamplingPoint.id, plannedSamplingPoint.label, removeDate, values).then(() => {
            this.silentReloadEvent.emit();
            this.isStoringDataForSamplingPoint = false;
        })
            .catch(err => {
                this.isStoringDataForSamplingPoint = false;
            });
    }

    getMatchingValues(idProj: string, dateStr) {

        const values: {id: number, value: number, date: Date}[] = [];
        this.laboData.samplingPointsData.forEach(sp => {

            sp.labAnalysis.forEach(lab => {
                const date = this._projectService.addProjectTimeZoneOffset(new Date(lab.date));
                if (sp.idProject.toString() === idProj && date.toISOString() === dateStr) {
                    values.push({
                        id: lab.id,
                        value: lab.value,
                        date: lab.date
                    });
                }
            });
        });
        console.log('values ', values);
        return values;
    }

    sortColumnByOrder = (a, b): number => {
        return a.value.order - b.value.order
    }
}
