import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LaboScheduleService } from 'app/lib/services/labo-schedule/labo-schedule.service';
import { ProjectService } from 'app/lib/services/project/project.service';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { UUID } from 'angular2-uuid';
import { LaboScheduleState, DefaultDay } from 'app/lib/interfaces/laboScheduleState.interface';
import { LaboScheduleStatePlanning } from 'app/lib/interfaces/laboScheduleStatePlanning.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { BrandingService } from 'app/lib/services/branding/branding.service';

@Component({
    selector: 'app-labo-schedule',
    templateUrl: './labo-schedule.component.html',
    styleUrls: ['./labo-schedule.component.scss']
})
export class LaboScheduleComponent implements OnInit {


    private dataChangedSubscription: Subscription;
    @Input() dataChanged: Observable<void>;
    @Output() silentReloadEvent = new EventEmitter();

    public laboData: any;

    public currentStateId = '';
    public dataLoaded = false;

    public scheduleStates: Array<LaboScheduleState>;
    public selectedScheduleStateTemplate: LaboScheduleState;

    public selectedTabIndex = 0;
    public selectedDayIndex = 0;

    public isAddingStatePlanning = false;
    public isDeletingState = false;
    private selectedStateToDelete;

    public isChangingStateName = false;
    public editStateName = '';

    public addedStatePlanning: Date = new Date();

    public selectedScheduleStatePlannings: Array<LaboScheduleStatePlanning> = [];

    public tags = ['Lo-Rem', 'BB8', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR', 'Lo-Rem', 'XL-pC', 'TL-1', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR', 'Lo-Rem', 'Ipsum', 'do_LOR'];
    public currentEnabled = false;

    // new state
    public newStateName = '';

    constructor(
        public brandingService: BrandingService,
        private notification: NzNotificationService,
        private _projectService: ProjectService,
        private _smartlabService: SmartlabService,
        public _authService: AuthService,
        private _laboScheduleService: LaboScheduleService,
        public deviceService: DeviceDetectorService) {
    }

    /* tslint:disable-next-line:no-any */
    log(args: any[]): void {
        // console.log(args);
    }

    ngOnInit() {
        this.dataChangedSubscription = this.dataChanged.subscribe((data) => {
            this.laboData = data;
            this.loadData();
        });
    }

    loadData() {

        this.dataLoaded = false;
        this.scheduleStates = [];

        this._laboScheduleService.getCurrentState(this.laboData.selectedDate).then(result => {
            if (result == null) {
                this.currentStateId = '';
            } else {
                this.currentStateId = result.Id;
            }

            this._laboScheduleService.getLaboScheduleStates().then(result => {
                this.scheduleStates = result;
                if (this.scheduleStates.length > 0) {
                    this.loadCurrentSelectedScheduleStatePlanning();
                }
                this.dataLoaded = true;
            })
            .catch(err => {
                console.log(err);
            });
        })
        .catch(err => {
            console.log(err);
        });
    }

    isCurrent(planning: DefaultDay) {
        // console.log(`isCurrent: ${planning.id} :: ${this.currentStateId}`);

        if (planning.id === this.currentStateId) {
            return true;
        } else {
            return false;
        }
    }

    handleDeletePlanning(planning: LaboScheduleState): void {

        // this.plans = this.plans.filter(tag => tag !== removedTag);
        const selectedScheduleState = this.scheduleStates[this.selectedTabIndex];

        this._laboScheduleService.deleteStatePlanning(planning.id)
            .then(() => {
                this.notification.success('Planning deleted', `The Planning was deleted from the state "${selectedScheduleState.name}".`);
            })
            .catch(err => {
                this.notification.error('Error deleting the planning date', '');
                console.log(err);
            });
    }


    sliceTagName(tag: string): string {
        const isLongTag = tag.length > 20;
        return isLongTag ? `${tag.slice(0, 20)}...` : tag;
    }

    getTag(tagId): string {
        const tagNameEntry = this.laboData.tagsArray.find(x => x.id === tagId);
        if (tagNameEntry === undefined) {
            return '-';
        } else {
            return tagNameEntry.name;
        }
    }

    checkTagChange(event) {

    }

    stateTabChange(event) {
        // console.log(event);
        this.selectedDayIndex = 0;
        this.loadCurrentSelectedScheduleStatePlanning();
    }

    loadCurrentSelectedScheduleStatePlanning() {
        // fetch plannings for this day

        const selectedScheduleState = this.scheduleStates[this.selectedTabIndex];

        if (selectedScheduleState == null) { // this happens when adding a new Schedule State
            return;
        }

        this.selectedScheduleStatePlannings = [];
        this._laboScheduleService.getPlanningForStateId(selectedScheduleState.id)
            .then((result) => {
                this.selectedScheduleStatePlannings = result;

                this.selectedScheduleStatePlannings.sort(function (a, b) {
                    const date1 = new Date(b.planned);
                    const date2 = new Date(a.planned);;
                    return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
                });

            })
            .catch(err => {
                this.notification.error('Error fetching planning dates for the selected state', '');
                console.log(err);
            });
    }

    createNewState() {

        console.log(`template: `, this.selectedScheduleStateTemplate)

        if (this.newStateName === ``) {
            this.notification.error('Can not create a new state', `Please add the State Name in the Input Field.`);
            return;
        }

        // create template state for every day of the week

        let schedule = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        };

        const templateSchedule = [];

        if (this.selectedScheduleStateTemplate) {

            schedule = {
                monday: [...this.selectedScheduleStateTemplate.schedule.monday],
                tuesday: [...this.selectedScheduleStateTemplate.schedule.tuesday],
                wednesday: [...this.selectedScheduleStateTemplate.schedule.wednesday],
                thursday: [...this.selectedScheduleStateTemplate.schedule.thursday],
                friday: [...this.selectedScheduleStateTemplate.schedule.friday],
                saturday: [...this.selectedScheduleStateTemplate.schedule.saturday],
                sunday: [...this.selectedScheduleStateTemplate.schedule.sunday]
            };

        } else {
            this.laboData.samplingPoints.forEach(samplingPoint => {

                const templateScheduleAnalysisIds = [];
    
                samplingPoint.labAnalysis.forEach(labAnalysisId => {
                    templateScheduleAnalysisIds.push(labAnalysisId);
                });
    
                templateSchedule.push({
                    samplingPointId: samplingPoint.id,
                    label: '',
                    analysisIds: templateScheduleAnalysisIds,
                    id: UUID.UUID()
                })
            });
            
            
            schedule = {
                monday: [...templateSchedule],
                tuesday: [...templateSchedule],
                wednesday: [...templateSchedule],
                thursday: [...templateSchedule],
                friday: [...templateSchedule],
                saturday: [...templateSchedule],
                sunday: [...templateSchedule]
            };
        }

        const newState: LaboScheduleState = {
            name: this.newStateName,
            createdAt: new Date(),
            deleted: false,
            deletedAt: new Date(null),
            id: UUID.UUID(),
            projectId: this._projectService.cachedProject.code,
            schedule: schedule
        };


        this._laboScheduleService.addState(newState)
            .then(() => {
                this.scheduleStates.push(newState);
                this.selectedTabIndex = this.scheduleStates.length;
                setTimeout(() => {
                    this.selectedScheduleStateTemplate = null;
                    this.selectedTabIndex = this.scheduleStates.length - 1;
                }, 100);
                this.newStateName = ``;
                this.notification.success('New state created', `A new state with the name "${this.newStateName}" was created.`);
            })
            .catch(err => {
                // todo: show error
                this.notification.error('Can not create a new state', 'Error while saving the new State');
                console.log(err);
            });

    }

    saveState(index) {

        this._laboScheduleService.updateState(this.scheduleStates[index])
            .then(() => {
                this.notification.success('State saved', `The State "${this.scheduleStates[index].name}" was saved.`);
            })
            .catch(err => {
                this.notification.error('Error saving state', 'Error while saving the State');
                console.log(err);
            });

    }

    showModalDeleteState(index) {
        this.selectedStateToDelete = index;
        this.isDeletingState = true;
    }

    cancelDeleteState() {
        this.selectedStateToDelete = -1;
        this.isDeletingState = false;
    }

    confirmDeleteState() {
        
        this._laboScheduleService.deleteStatePlanning(this.scheduleStates[this.selectedStateToDelete].id)
        .then(() => {
            this._laboScheduleService.deleteState(this.scheduleStates[this.selectedStateToDelete])
            .then(() => {
                this.notification.success('State deleted', `The State "${this.scheduleStates[this.selectedStateToDelete].name}" was deleted.`);
                this.scheduleStates.splice(this.selectedStateToDelete, 1);

                this.selectedStateToDelete = -1;
                this.isDeletingState = false;
                this.selectedTabIndex = 0;
            })
            .catch(err => {
                this.notification.error('Error deleting state', 'Error while deleting the State');
                console.log(err);
            });
        })
        .catch(err => {
            this.notification.error('Error deleting state plannings', 'Error while deleting the State plannings');
            console.log(err);
        });

    }

    /* #region Add StatePlanning Group */

    showModalAddStatePlanning() {
        this.isAddingStatePlanning = true;
    }

    cancelAddStatePlanning() {
        this.addedStatePlanning = new Date();
        this.isAddingStatePlanning = false;
    }

    confirmAddStatePlanning() {

        this.isAddingStatePlanning = false;

        const selectedScheduleState = this.scheduleStates[this.selectedTabIndex];

        // TODO: CHECK TIME ZONE!!!!

        this.addedStatePlanning.setHours(6, 0, 0, 0);

        const newScheduleState = {
            id: UUID.UUID(),
            stateId: selectedScheduleState.id,
            planned: this.addedStatePlanning
        };

        this._laboScheduleService.addStatePlanning({
            id: UUID.UUID(),
            stateId: selectedScheduleState.id,
            planned: this.addedStatePlanning
        })
            .then(() => {
                this.selectedScheduleStatePlannings.push(newScheduleState)
                // this.loadCurrentSelectedScheduleStatePlanning();

                // TODO: Optimize this in the call
                this.selectedScheduleStatePlannings.sort(function (a, b) {
                    const date1 = new Date(b.planned);
                    const date2 = new Date(a.planned);;
                    return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
                });

                this.notification.success('Planning added', `The Planning was added to the state "${selectedScheduleState.name}".`);
            })
            .catch(err => {
                this.notification.error('Error adding the planning date', '');
                console.log(err);
            });

    }

    /* #region Add StatePlanning Group */

    showModalChangeStateName() {
        const selectedScheduleState = this.scheduleStates[this.selectedTabIndex];
        this.editStateName = selectedScheduleState.name;
        this.isChangingStateName = true;
    }

    cancelChangeStateName() {
        this.editStateName = '';
        this.isChangingStateName = false;
    }

    confirmChangeStateName() {

        this.isChangingStateName = false;

        const selectedScheduleState = this.scheduleStates[this.selectedTabIndex];
        selectedScheduleState.name = this.editStateName;

        this._laboScheduleService.updateState(selectedScheduleState)
            .then(() => {
                this.notification.success('State saved', `The State "${selectedScheduleState.name}" was saved.`);
            })
            .catch(err => {
                this.notification.error('Error saving state', 'Error while saving the State');
                console.log(err);
            });

    }

}
