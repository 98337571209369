import { DateTime } from './../../../graphql/index';
import { RestBase } from './../rest.base';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';
import { SamplingPoint, SamplingpointPut, DataGet, DefinitionTag } from 'app/lib/interfaces/labo.interface';
import { Observable } from 'apollo-link';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LaboService extends RestBase {


    constructor(private http: HttpClient, public datepipe: DatePipe) {
        super(environment.apiUrl + `v1/labo/`);
    }

    private _samplingPointsGet = new BehaviorSubject<SamplingPoint[]>([]);

    get samplingPointsGet() {
        return this._samplingPointsGet.asObservable();
    }

    private _dataChangeTriggered = new BehaviorSubject<Boolean>(false);

    get dataChangeTriggered() {
        return this._dataChangeTriggered.asObservable();
    }

    updateSamplingPoints(projectCode: string) {
        this.getSamplingPoints(projectCode).then(
            samplingPointsGet => {
                console.log(`updateSamplingPoints: `, samplingPointsGet);
                this._samplingPointsGet.next(samplingPointsGet);
            }
        )
            .catch(err => {
                console.log(`samplingPoints Error`, err);
            });
    }

    async getSamplingPoints(projectCode: string) {

        // TODO: Put a limit on this request (ex: based on time)

        return new Promise<SamplingPoint[]>((resolve, reject) => {
            this.http
                .get(this.endpoint + 'samplingpoints/' + projectCode + '/')
                .toPromise()
                .then(results => {
                    const samplingPointsGet = results as SamplingPoint[];
                    resolve(samplingPointsGet);
                })
                .catch(err => {
                    console.log(`could not fetch Sampling points, trying again in 5 seconds...`);
                    // todo: Better error handling
                    setTimeout(() => {
                        return this.getSamplingPoints(projectCode);
                    }, 5000);
                });
        });
    }

    async getDataForDate(projectCode: string, date: Date, timezoneString: string) {
        return new Promise<DataGet>((resolve, reject) => {
            const dateStr = date.toISOString().replace(/\.\d+/, '');
            this.http
                .get(
                    this.endpoint + 'data/' +
                    projectCode + '/' +
                    encodeURIComponent(dateStr) + '/' +
                    (timezoneString ? encodeURIComponent(timezoneString.replace(`/`, `___`)) + '/' : '') // Because Azure can not handle encoded forward slashes, we need to replace a forward slash with custom characters which are then reverted back on the API Side
                )
                .toPromise()
                .then(results => {
                    const dataGet = results as DataGet;
                    console.log('dataget', dataGet);
                    resolve(dataGet);
                })
                .catch(err => {
                    console.log(
                        `could not fetch Sampling points, trying again in 5 seconds...`
                    );

                    // todo: Better error handling

                    setTimeout(() => {
                        return this.getSamplingPoints(projectCode);
                    }, 5000);
                });
        });
    }

    async setData(
        projectCode: string,
        idProject: number,
        scheduleId: string,
        scheduleLabel: string,
        date: Date,
        labValues: [{id: number, value: number, date: Date}]
    ) {
        console.log('doing setData ', labValues);
        return new Promise<DataGet>((resolve, reject) => {
            this.http
                .put(
                    this.endpoint + 'data/' +
                    projectCode + '/' +
                    this.dateToURIStr(date) + '/',
                    this.dataFromLabValues(idProject, scheduleId, scheduleLabel, labValues)
                )
                .toPromise()
                .then(() => {
                    this._dataChangeTriggered.next(true);
                    resolve(null);
                })
                .catch(err => {
                    console.log(`could not update data...`, err);
                    reject();
                });
        });
    }

    async updateTime(
        projectCode: string,
        idProject: number,
        scheduleId: string,
        scheduleLabel: string,
        fromDate: Date,
        toDate: Date,
        labValues: {id: number, value: number, date: Date}[]
    ) {
        console.log('doing updateTime ', labValues);
        return new Promise<DataGet>((resolve, reject) => {
            this.http
                .put(
                    this.endpoint + 'data/updatetime/' +
                    projectCode + '/' +
                    this.dateToURIStr(fromDate) + '/' +
                    this.dateToURIStr(toDate) + '/',
                    this.dataFromLabValues(idProject, scheduleId, scheduleLabel, labValues)
                )
                .toPromise()
                .then(() => {
                    this._dataChangeTriggered.next(true);
                    resolve(null);
                })
                .catch(err => {
                    console.log(`could not update data...`, err);
                    reject();
                });
        });
    }

    async deleteData(
        projectCode: string,
        idProject: number,
        scheduleId: string,
        scheduleLabel: string,
        date: Date,
        labValues: {id: number, value: number, date: Date}[]
    ) {
        console.log('doing delete ', labValues);
        return new Promise<DataGet>((resolve, reject) => {
            this.http
                .put(
                    this.endpoint + 'data/delete/' +
                    projectCode + '/' +
                    this.dateToURIStr(date) + '/',
                    this.dataFromLabValues(idProject, scheduleId, scheduleLabel, labValues)
                )
                .toPromise()
                .then(() => {
                    this._dataChangeTriggered.next(true);
                    resolve(null);
                })
                .catch(err => {
                    console.log(`could not delete data...`, err);
                    reject();
                });
        });
    }

    dateToURIStr(date: DateTime) {
        return encodeURIComponent(date.toISOString().replace(/\.\d+/, ''));
    }

    dataFromLabValues(idProject, scheduleId, scheduleLabel, labValues: {id: number, value: number, date: Date}[]) {

        if (scheduleLabel === "" || scheduleLabel === null) {
            scheduleLabel = "default";
        }

        const valueList = [];
        labValues.forEach(vl => {
            valueList.push({
                id: vl.id,
                scheduleId: scheduleId,
                scheduleLabel: scheduleLabel,
                value: vl.value,
                date: vl.date
            });
        });

        const ret: SamplingpointPut = {
            samplingpoints: [
                {
                    idProject: idProject,
                    labAnalysis: valueList
                }
            ]
        };
        return ret;
    }

}
