import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { LaboService } from 'app/lib/services/labo/labo.service';
import { ProjectParent } from '../parent/project.parent';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ProjectService } from 'app/lib/services/project/project.service';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { BrandingService } from 'app/lib/services/branding/branding.service';
import { DefaultDay } from 'app/lib/interfaces/laboScheduleState.interface';
import { LaboScheduleService } from 'app/lib/services/labo-schedule/labo-schedule.service';
import { SamplingPoint, DefinitionTag, SamplingPointData } from 'app/lib/interfaces/labo.interface';

@Component({
  selector: 'app-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent extends ProjectParent implements OnInit {

    /////// Current date
    public selectedDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0); // always put the time at 12
    public selectedDateString = '';

    private plannedSamplingPoints: Array<DefaultDay> = [];
    public samplingPointColumns: Map<string, { name: string, idProj: number, planned: DefaultDay, default: boolean, dates: Map<string, Date>, order: number }> = new Map<string, { name: string, idProj: number, planned: DefaultDay, default: boolean, dates: Map<string, Date>, order: number }>();
    /////// Definiton of samplingpoints and tags
    public samplingPoints: Array<SamplingPoint>;
    // The tags are linked by name. the map has the samplingpoint id as key
    public samplingPointsArray: Array<{ name: string, idProj: number, tags: Map<number, DefinitionTag>, order: number }>;
    public samplingPointsArrayFiltered: Array<{ name: string, idProj: number, tags: Map<number, DefinitionTag>, order: number }>;
    // The actual data for samplingpoint's lab analysis
    public samplingPointsData: Array<SamplingPointData>;
    public tagsArray = new Array<DefinitionTag>();
    public tagsArrayFiltered = new Array<DefinitionTag>();

    public dataLoaded = false;
    public scheduleLoaded = false;
    public noSchedule = false;

    public dataReloadedSubject: Subject<any> = new Subject<any>();

    constructor(
        public brandingService: BrandingService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        _activatedRoute: ActivatedRoute,
        _router: Router,
        _fuseNavigationService: FuseNavigationService,
        _projectService: ProjectService,
        _smartlabService: SmartlabService,
        _spinner: NgxSpinnerService,
        public _authService: AuthService,
        private laboService: LaboService,
        private _laboScheduleService: LaboScheduleService) {
        super(brandingService, _activatedRoute, _router, _fuseNavigationService, _projectService, _spinner, _smartlabService, _authService);
    }

    ngOnInit() {
        this.selectedDateString = this.selectedDate.toDateString();
        this.loadData();
    }

    loadData() {

        this.dataLoaded = false;
        this.scheduleLoaded = false;

        super.initProject().subscribe(
            project => {

                this.laboService.samplingPointsGet.subscribe(samplingPointsGet => {

                    if (samplingPointsGet.length === 0) {
                        return;
                    }

                    // Store the sampling points and store by name
                    this.samplingPoints = samplingPointsGet;

                    // Store the tags in a list, and sort by name
                    this.samplingPointsArray = new Array<{ name: string, idProj: number, tags: Map<number, DefinitionTag>, order: number }>();
                    for (const samp of this.samplingPoints) {
                        for (const tag of samp.labAnalysis) {
                            const tagNameEntry = this.samplingPointsArray.find(x => x.name === tag.name);
                            if (tagNameEntry === undefined) {
                                this.samplingPointsArray.push({ name: tag.name, idProj: tag.id, tags: new Map<number, DefinitionTag>([[samp.id, tag]]), order: samp.order });
                            } else {
                                tagNameEntry.tags.set(samp.id, tag);
                            }
                        }
                    }

                    // Store the tags in a list, and sort by name
                    this.tagsArray = new Array<DefinitionTag>();
                    for (const samp of this.samplingPoints) {
                        for (const tag of samp.labAnalysis) {
                            const tagNameEntry = this.tagsArray.find(x => x.id === tag.id);
                            if (tagNameEntry === undefined) {
                                this.tagsArray.push(tag);
                            }
                        }
                    }
                    this.tagsArray.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    });

                    console.log(`check: this.tagsArray`, this.tagsArray);

                    //console.log(`this.samplingPoints`, this.samplingPoints);
                    //console.log(`this.tags`, this.samplingPointsArray);

                    this.loadDataForDay(null);
                });
            },
            err => {
                // error shown in super
            }
        );
    }

    resolveData() {
        if(this.scheduleLoaded && this.dataLoaded) {
            // console.log(`inputCheck: this.plannedSamplingPoints`, this.plannedSamplingPoints);
            // console.log(`inputCheck:  this.samplingPointsArray`,  this.samplingPointsArray);
            this.plannedSamplingPoints.forEach(planned => {

                planned.analysisIds.forEach(analysisId => {

                    const checkForTag = this.samplingPointsArray.find(x => x.idProj === analysisId);

                    // console.log(`check: this.tagsArrayFiltered`, this.tagsArrayFiltered);
                    const tagEntry = this.tagsArrayFiltered.find(x => x.id === analysisId);
                    // console.log(`check: tagEntry`, tagEntry);

                    if (tagEntry === undefined) {
                        console.log(`check: add`, tagEntry);
                        const tag = this.tagsArray.find(x => x.id === analysisId);
                        if (tag !== undefined) {
                            this.tagsArrayFiltered.push(tag);
                        } else {
                            console.log(`can not find tag with id: `, analysisId);
                        }
                    }

                    // console.log(`inputCheck: checkForTag`, checkForTag);

                    if (checkForTag !== undefined) {

                        const tagNameEntry = this.samplingPointsArrayFiltered.find(x => x.idProj === checkForTag.idProj);
                        if (tagNameEntry === undefined) {
                            if (checkForTag !== null) {
                                this.samplingPointsArrayFiltered.push(checkForTag);
                            }
                        }
                    }

                });

                // console.log(`inputCheck:  sp`, this.samplingPointsArrayFiltered);
                // filter tags based on the sampling points


                // for (var i = 0; i < planned.analysisIds.length; i++) {
                //     if (this.samplingPointsArrayFiltered.filter(x => x.tags)

                //     if (planned.analysisIds[i] not in this.samplingPointsArrayFiltered) {
                //         this.samplingPointsArrayFiltered.push(array[i].age)
                //     }
                // }

                const sp = this.samplingPoints.filter(x => x.id === planned.samplingPointId)[0];

                // console.log(`inputCheck: planned`, planned)
                // console.log(`inputCheck: sp`, sp)

                const columnId = planned.id;
                let name = sp.name;

                if (planned.label !== null && planned.label !== "") {
                    name = `${sp.name} - ${planned.label}`;
                }

                this.samplingPointColumns.set(columnId, {
                    name: name,
                    idProj: sp.id,
                    default: true,
                    planned: planned,
                    order: sp.order,
                    dates: new Map<string, Date>([[this.selectedDate.toISOString(), new Date(this.selectedDate)]])
                });

            });


            // Set the dates 
            this.samplingPointsData.forEach(sp => {
                sp.labAnalysis.forEach(lab => {
                    const col = this.samplingPointColumns.get(lab.scheduleId);
                    if (col != null) {
                        if (lab.scheduleId === col.planned.id) {

                            if (col.default === true) {
                                col.default = false;
                                col.dates.clear();
                            }
                            const date = this._projectService.addProjectTimeZoneOffset(new Date(lab.date));
                            col.dates.set(date.toISOString(), new Date(date));
                            this.samplingPointColumns.set(lab.scheduleId, col);
                        }
                    }
                });
            });            

            this.dataReloadedSubject.next({
                selectedDate: this.selectedDate, 
                selectedDateString: this.selectedDateString,
                plannedSamplingPoints: this.plannedSamplingPoints,
                samplingPointColumns: this.samplingPointColumns,
                samplingPoints: this.samplingPoints,
                samplingPointsArray: this.samplingPointsArray,
                samplingPointsArrayFiltered: this.samplingPointsArrayFiltered,
                samplingPointsData: this.samplingPointsData,
                tagsArray: this.tagsArray,
                tagsArrayFiltered: this.tagsArrayFiltered,
                dataLoaded: this.dataLoaded,
                scheduleLoaded: this.scheduleLoaded,
                noSchedule: this.noSchedule
            });
            console.log('resolveData done');
        }
        else {
            console.log('resolveData: ', this.dataLoaded, this.scheduleLoaded);
        }
    }

    loadDataForDay(event) {   
        if(event != null) {
            if(event instanceof  Date) {
                console.log('loadDataForDay Date: ', event);
                this.selectedDate = new Date(event.getFullYear(), event.getMonth(), event.getDate(), 0); // always put the time at 0
            }
            else if(!isNaN(event)) {
                console.log('loadDataForDay day offset: ', event);
                this.selectedDate.setDate(this.selectedDate.getDate() + event);
            }
            this.selectedDateString = this.selectedDate.toDateString();
        }

        this.dataLoaded = false;
        this.scheduleLoaded = false;

        this.dataReloadedSubject.next({
            selectedDate: this.selectedDate, 
            selectedDateString: this.selectedDateString,
            dataLoaded: this.dataLoaded,
            scheduleLoaded: this.scheduleLoaded,
        });

        this.silentLoadDataForDay();

        this._laboScheduleService.getPlanningForDate(this.selectedDate)
            .then(result => {

                // set planned sampling points from schedule
                this.plannedSamplingPoints = result;

                if (this.plannedSamplingPoints == null || this.plannedSamplingPoints.length == 0) {
                    this.noSchedule = true;
                    this.plannedSamplingPoints = [];
                } else {
                    this.noSchedule = false;
                    // console.log(`inputCheck: this.plannedSamplingPoints`, this.plannedSamplingPoints);
                    // console.log(this.plannedSamplingPoints);
                }

                this.scheduleLoaded = true;
                this.resolveData();
            })
            .catch(err => {
                console.log(err);
            });

    }

    silentLoadDataForDay() {
        this.laboService.getDataForDate(this._projectService.cachedProject.code, this._projectService.removeProjectTimeZoneOffset(this.selectedDate), this._projectService.getTimeZoneString()).then(
            dataGet => {

                // console.log(`inputCheck: dataGet`, dataGet);
                if(this.plannedSamplingPoints === undefined) {
                    return;
                }

                // Reset all columns

                this.samplingPointColumns = new Map<string, { name: string, idProj: number, planned: DefaultDay, default: boolean, dates: Map<string, Date>, order: number }>();
                this.samplingPointsArrayFiltered = new Array<{ name: string, idProj: number, tags: Map<number, DefinitionTag>, order: number }>();
                this.tagsArrayFiltered = new Array<DefinitionTag>();

                // this.samplingPoints.forEach(sp => {     
                //     this.samplingPointColumns.set(sp.idProj, {
                //         name: sp.name,
                //         default: true,
                //         dates: new Map<string, Date>([[this.selectedDate.toISOString(), new Date(this.selectedDate)]])
                //     });
                // });

                // // Remove the sampling points that are not in selectedTimePerSamplingPoint (enable_for_lab)
                // this.samplingPointsData = dataGet.samplingpoints.filter(x => this.samplingPointColumns.has(x.idProject));

                // Filter out according to schedule

                // this.samplingPointsData = dataGet.samplingpoints.filter(x => this.samplingPointColumns.has(x.idProject));

                this.samplingPointsData = dataGet.samplingpoints;

                this.dataLoaded = true;
                this.resolveData();

            }).catch(err => {
                console.log(`samplingPoints Error`, err);
            });
    }
}
