import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as dutch } from './i18n/nl';
import { environment } from 'environments/environment';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { BrandingService } from 'app/lib/services/branding/branding.service';

declare let Cumulio: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public projects: any[];

  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    public brandingService: BrandingService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _authService: AuthService,
    private _router: Router,
    private _smartlabService: SmartlabService,
    private _ngxSpinner: NgxSpinnerService) {
    this._fuseTranslationLoaderService.loadTranslations(english, dutch);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {

    if (!this._smartlabService.firstGetProjectsDone) {
      setTimeout(() => {
        this.loadData();
      }, 400);
    } else {
      console.log(`user profile:`, this._authService.getUserProfile());

      this._ngxSpinner.show("projectsLoader");
  
      this._smartlabService.getProjects(this._authService.getUserProfile()[`sub`]).
        then(
          result => {
            console.log(`this._smartlabService.getProjects`, result);
            this.projects = result;

            // filter duplicates

            this.projects = this.projects.reduce((arr, item) => {
                let exists = !!arr.find(x => x.id === item.id);
                if(!exists){
                    arr.push(item);
                }
                return arr;
            }, []);
          
            // filter if there are 

            if (this.brandingService.brand.AllowedProjects) {

                const filteredProjectsForBrand: any[] = [];

                this.projects.forEach(project => {
                    this.brandingService.brand.AllowedProjects.forEach(allowedProject => {
                        if (project.code === allowedProject.projectCode) {
                            project.name = allowedProject.projectLabel;
                            filteredProjectsForBrand.push(project);
                        }
                    });
                });

                this.projects = filteredProjectsForBrand;
            }
  
            if (this.projects.length === 1 && !this._smartlabService.firstNavigationToSingleProjectDone) {
              this._smartlabService.firstNavigationToSingleProjectDone = true;
              this._router.navigate(['project/' + this.projects[0].code + '/plant/' + 'p'], { state: this.projects[0] });
            }
          }
        )
        .catch(err => {
          console.log(`Error fetching projects, trying again...`);
          setTimeout(() => {
            this.loadData();
          }, 5000);
        });
    }

  }

  loadTab(tabIndex) {
    if (tabIndex == 1) {
      Cumulio.addDashboard({
        dashboardId: '117dec8d-7177-455c-8623-fd2ce08ed53d', //for Aquarama
        container: '#dashboard'
      });
    }
  }
}
