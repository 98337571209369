import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingService } from 'app/lib/services/branding/branding.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';

interface SmartlabMarker {
    id: string;
    project: any;
    lat: number;
    lng: number;
    label?: {
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        text: string;
    };
    location: string;
    draggable: boolean;
    icon: {
        url: string;
        labelOrigin: {
            x: number;
            y: number;
        }
    };
}

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    @Input() projects: any;

    apiLoaded: Observable<boolean>;

    public markers: SmartlabMarker[] = [];

    // google maps zoom level
    zoom: number = 3;

    public lat = 0;
    public lng = 0;

    public clusterStyles: ClusterStyle[] = [
        {
            textColor: "#FFFFFF",
            url: "assets/markers/marker_green.png",
            height: 30,
            width: 30
        },
        {
            textColor: "#FFFFFF",
            url: "assets/markers/marker_yellow.png",
            height: 30,
            width: 30
        },
        {
            textColor: "#FFFFFF",
            url: "assets/markers/marker_red.png",
            height: 30,
            width: 30
        },
        {
            textColor: "#FFFFFF",
            url: "assets/markers/marker_grey.png",
            height: 30,
            width: 30
        }
    ];

    public markerCalc = (markers) => {

        console.log(`markers: `, markers);

        for (let i = 0; i < markers.length; i++) {
            // you have access all the markers from each cluster
        }

        // TODO: Check KPI of the projects and assign correct icon to the cluster

        return { text: markers.length, index: 1 };

        // if (markers.length > 2) {
        //   return { text: markers.length, index: 1 };
        // } else {
        //   return { text: markers.length, index: 3 };
        // }

        // index: 1 -> for green icon
        // index: 2 -> for red icon
    }

    public smartlabStyle = [
        {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [{ visibility: "off" }]
        },
        {
            featureType: "road",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
        },
        { elementType: 'labels.text.stroke', stylers: [{ visibility: 'off', }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
        {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
                { visibility: "off" }
            ]
        }
    ];

    constructor(private httpClient: HttpClient, private _router: Router, public brandingService: BrandingService) {
        this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBHcVdGJHZl3H2aBAUepKhvCquS9nDnMCc', 'callback')
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {

        if (!this.projects) {
            setTimeout(() => {
                this.loadData();
            }, 500);
            return;
        }
        console.log(`map projects: `, this.projects);

        const addedCoordinates = [];

        this.projects.forEach(project => {

            let lat = 0;
            let lng = 0;

            let location = `unknown`;

            if (project.locations && project.locations.length > 0 && project.locations[0]) {

                location = `${project.locations[0].city}, ${project.locations[0].country}`;

                if (project.locations[0].coordinates && project.locations[0].coordinates.length > 0) {

                    const coordinatesArray = project.locations[0].coordinates.split(`,`);

                    lat = +coordinatesArray[0];
                    lng = +coordinatesArray[1];

                    addedCoordinates.forEach(c => {
                        if (c.lat === lat && c.lng === lng) {
                            lng = lng + 0.0006;
                            lat = lat + 0.0006;
                        }
                    });

                    addedCoordinates.push({ lat: lat, lng: lng });

                    // TODO: Get KPI of project and assign color;
                    let icon = `assets/markers/marker_green.png`

                    if (project.kpiValue) {
                        if (project.kpiValue < 0.8) { // L
                            icon = `assets/markers/marker_yellow.png`;
                        }
                        if (project.kpiValue < 0.6) { // LL
                            icon = `assets/markers/marker_red.png`;
                        }
                    } else {
                        icon = `assets/markers/marker_grey.png`;
                    }

                    // switch (project.id) {
                    //     case "54":
                    //         icon = `assets/markers/marker_yellow.png`
                    //         break;
                    //     case "12":
                    //         icon = `assets/markers/marker_grey.png`
                    //         break;
                    //     case "25":
                    //         icon = `assets/markers/marker_grey.png`
                    //         break;
                    //     case "42":
                    //         icon = `assets/markers/marker_grey.png`
                    //         break;
                    //     default:
                    //         break;
                    // }

                    this.markers.push({
                        id: project.id,
                        project: project,
                        lat: lat,
                        lng: lng,
                        label: {
                            color: 'black',
                            fontFamily: '',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            text: project.name
                        },
                        location: location,
                        draggable: false,
                        icon: {
                            url: icon,
                            labelOrigin: {
                                x: 12,
                                y: 40
                            }
                        }
                    })

                }
            }
        });
    }

    clickedMarker(marker: SmartlabMarker, index: number) {
        console.log(`clicked the marker: ${marker.label || index}`)
        this.openProject(marker.project);
    }

    mapClicked($event) {

        console.log($event);

        // this.markers.push({
        //   lat: $event.coords.lat,
        //   lng: $event.coords.lng,
        //   draggable: true
        // });
    }

    markerDragEnd(m: SmartlabMarker, $event: MouseEvent) {
        console.log('dragEnd', m, $event);
    }

    clusterChange(event) {
        console.log(`cluster change: `, event);
    }

    infoWindowOnMouseOver(infoWindow, gm) {

        if (gm.lastOpen != null) {
            gm.lastOpen.close();
        }

        gm.lastOpen = infoWindow;

        infoWindow.open();

    }

    infoWindowOnMouseOut(infoWindow, gm) {

        if (gm.lastOpen != null) {
            gm.lastOpen.close();
        }

    }

    openProject(project) {
        this._router.navigate(['project/' + project.code + '/plant/' + 'p'], { state: project });
    }

    getMapHeight() {
        const height = document.documentElement.clientHeight - 150;
        return height;
    }
}
