import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

import { navigation } from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ProjectService } from 'app/lib/services/project/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';

import * as moment from 'moment-timezone';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { BrandingService } from 'app/lib/services/branding/branding.service';

export class ProjectParent {

    constructor(
        protected _brandingService: BrandingService,
        protected _activatedRoute: ActivatedRoute,
        protected _router: Router,
        protected _fuseNavigationService: FuseNavigationService,
        protected _projectService: ProjectService,
        protected _spinner: NgxSpinnerService,
        protected _smartlabService: SmartlabService,
        protected _authService: AuthService) {
    }

    initProject(): Observable<any> {
        const simpleObservable = new Observable((observer) => {
            // Without this timeout an error would occur indicating that something has been
            // changed after the 'hidden' property of the nav item has been checked
            setTimeout(() => {

                this._activatedRoute.paramMap.subscribe((params: ParamMap) => {

                    // Fetch the project code from the url
                    // There should be a project code available
                    const projectCode = params.get("project")
                    if (projectCode == null) {
                        observer.error('No project code defined')
                        console.error('No project code defined')
                        return
                    }

                    //console.log('project code ' + projectCode)

                    // Update navigation items
                    if (this._projectService.cachedProject == null ||
                        this._projectService.cachedProject.code != projectCode) {
                        // update
                        let nav = JSON.parse(JSON.stringify(navigation));
                        this.updateNavigationItem(nav[1], projectCode)

                        console.log(nav[1]);
                    }

                    // Check if we need to fetch the project object from WEP
                    if (this._projectService.cachedProject == null ||
                        this._projectService.cachedProject.code != projectCode) {

                        // https://github.com/ilDug/dag-mat-spinner#readme
                        this._spinner.show(`loadProjectSpinner`);

                        // fetch the wep project
                        this._smartlabService.getProject(projectCode).subscribe(
                            project => {
                                this._projectService.cachedProject = project;

                                if (this._projectService.cachedProject) {

                                    this._projectService.updateProjectLocalDateAndTime();

                                    const plantName = this._projectService.cachedProject.name;

                                    if (this._authService.userHasPermissions(['demomode'])) {
                                        this._fuseNavigationService.updateNavigationItem('selectedplant', {
                                            hidden: false,
                                            translate: "Demo Plant"
                                        });
                                    } else {
                                        this._fuseNavigationService.updateNavigationItem('selectedplant', {
                                            hidden: false,
                                            translate: plantName
                                        });
                                    }

                                    // update children

                                    const technologiesNavItem = this._fuseNavigationService.getNavigationItem('technologies');
                                    technologiesNavItem.children = [];

                                    this._projectService.updateAndCachePFDUnits(projectCode).then(result => {
                                        // console.log(`getPFDUnitsForProjectId result:`, result);
                                        // console.log(`cached project result:`, this._projectService.cachedProject);
                                        // console.log(`this._projectService.cachedProjectUnits:`, this._projectService.cachedProjectUnits);

                                        const newTechnologiesChildren = [];

                                        this._projectService.getProjectUnitsForTarget("client_technologies").forEach(block => {
                                            newTechnologiesChildren.push({
                                                id: block.id,
                                                title: block.name,
                                                hidden: false,
                                                type: 'item',
                                                url: `project/${this._projectService.cachedProject.code}/technologies/${block.id}`,
                                            });
                                        });

                                        this._fuseNavigationService.updateNavigationItem('technologies', {
                                            children: newTechnologiesChildren
                                        })

                                        this._spinner.hide(`loadProjectSpinner`);
                                    })
                                        .catch(err => {
                                            this._spinner.hide(`loadProjectSpinner`);
                                            console.log(`getPFDUnitsForProjectId error:`, err);
                                        });

                                }

                                setTimeout(() => {
                                    this._projectService.updateLaboService(projectCode);
                                }, 200);

                                observer.next(this._projectService.cachedProject);
                                observer.complete();
                            },
                            err => {
                                this._spinner.hide(`loadProjectSpinner`);
                                console.error('Failed to fetch wep project ' + projectCode + ':' + err)
                                observer.error('Failed to fetch wep project ' + projectCode)
                            }
                        );
                    } else {
                        this._spinner.hide(`loadProjectSpinner`);
                        observer.next(this._projectService.cachedProject)
                        observer.complete()
                    }
                });

            }, 500);
        });
        return simpleObservable;
    }

    updateNavigationItem(item, projectCode) {

        // console.log(`item.enabledForBrand:`, item.enabledForBrand);

        if (item.enabledForBrand) {
            console.log(`item.enabledForBrand: item`, this._brandingService.brand[item.enabledForBrand]);

            if (this._brandingService.brand[item.enabledForBrand] === false) {
                return;
            }
        }
        if (item.permissions !== undefined) {
            console.log(`checkPermissions: `, item);
            if (this._authService.userHasPermissions(item.permissions)) {
                this.updateNavigationItemWithPermission(item, projectCode);
            }
        } else {
            this.updateNavigationItemWithPermission(item, projectCode);
        }
    }

    updateNavigationItemWithPermission(item, projectCode) {
        if (item.url && item.url.includes(':project')) {

            this._fuseNavigationService.updateNavigationItem(item.id, {
                hidden: false,
                url: item.url.replace(":project", projectCode)
            });

        } else if (item.hidden) {
            this._fuseNavigationService.updateNavigationItem(item.id, {
                hidden: false
            });
        }
        if (item.children) {
            item.children.forEach(element => {
                this.updateNavigationItem(element, projectCode)
            });
        }
    }
}
