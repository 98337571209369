export type Maybe<T> = T | null;

/** ordering options when selecting data from "Branding" */
export interface BrandingOrderBy {
    AlertsEnabled?: Maybe<OrderBy>;

    AlertsTitle?: Maybe<OrderBy>;

    AllowedProjects?: Maybe<OrderBy>;

    CalendarEnabled?: Maybe<OrderBy>;

    CalendarTitle?: Maybe<OrderBy>;

    Domain?: Maybe<OrderBy>;

    EnableBigLogo?: Maybe<OrderBy>;

    FavIcon?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    LaboratoryEnabled?: Maybe<OrderBy>;

    LaboratoryTitle?: Maybe<OrderBy>;

    LogbookEnabled?: Maybe<OrderBy>;

    LogbookTitle?: Maybe<OrderBy>;

    Logo?: Maybe<OrderBy>;

    MaintenanceEnabled?: Maybe<OrderBy>;

    MaintenanceTitle?: Maybe<OrderBy>;

    MyPlantsTableTitle?: Maybe<OrderBy>;

    MyPlantsTitle?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    OperatorEnabled?: Maybe<OrderBy>;

    OperatorTitle?: Maybe<OrderBy>;

    PlantEnabled?: Maybe<OrderBy>;

    PlantOverrideWithPFDUnitId?: Maybe<OrderBy>;

    PlantTitle?: Maybe<OrderBy>;

    PrimaryColor?: Maybe<OrderBy>;

    ReportsEnabled?: Maybe<OrderBy>;

    ReportsTitle?: Maybe<OrderBy>;

    SecondaryColor?: Maybe<OrderBy>;

    TechnologiesEnabled?: Maybe<OrderBy>;

    TechnologiesTitle?: Maybe<OrderBy>;

    UtilitiesEnabled?: Maybe<OrderBy>;

    UtilitiesTitle?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "Branding". All fields are combined with a logical 'AND'. */
export interface BrandingBoolExp {
    AlertsEnabled?: Maybe<BooleanComparisonExp>;

    AlertsTitle?: Maybe<StringComparisonExp>;

    AllowedProjects?: Maybe<JsonbComparisonExp>;

    CalendarEnabled?: Maybe<BooleanComparisonExp>;

    CalendarTitle?: Maybe<StringComparisonExp>;

    Domain?: Maybe<StringComparisonExp>;

    EnableBigLogo?: Maybe<BooleanComparisonExp>;

    FavIcon?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    LaboratoryEnabled?: Maybe<BooleanComparisonExp>;

    LaboratoryTitle?: Maybe<StringComparisonExp>;

    LogbookEnabled?: Maybe<BooleanComparisonExp>;

    LogbookTitle?: Maybe<StringComparisonExp>;

    Logo?: Maybe<StringComparisonExp>;

    MaintenanceEnabled?: Maybe<BooleanComparisonExp>;

    MaintenanceTitle?: Maybe<StringComparisonExp>;

    MyPlantsTableTitle?: Maybe<StringComparisonExp>;

    MyPlantsTitle?: Maybe<StringComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    OperatorEnabled?: Maybe<BooleanComparisonExp>;

    OperatorTitle?: Maybe<StringComparisonExp>;

    PlantEnabled?: Maybe<BooleanComparisonExp>;

    PlantOverrideWithPFDUnitId?: Maybe<StringComparisonExp>;

    PlantTitle?: Maybe<StringComparisonExp>;

    PrimaryColor?: Maybe<StringComparisonExp>;

    ReportsEnabled?: Maybe<BooleanComparisonExp>;

    ReportsTitle?: Maybe<StringComparisonExp>;

    SecondaryColor?: Maybe<StringComparisonExp>;

    TechnologiesEnabled?: Maybe<BooleanComparisonExp>;

    TechnologiesTitle?: Maybe<StringComparisonExp>;

    UtilitiesEnabled?: Maybe<BooleanComparisonExp>;

    UtilitiesTitle?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<BrandingBoolExp>)[]>;

    _not?: Maybe<BrandingBoolExp>;

    _or?: Maybe<(Maybe<BrandingBoolExp>)[]>;
}
/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export interface BooleanComparisonExp {
    _eq?: Maybe<boolean>;

    _gt?: Maybe<boolean>;

    _gte?: Maybe<boolean>;

    _in?: Maybe<boolean[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<boolean>;

    _lte?: Maybe<boolean>;

    _neq?: Maybe<boolean>;

    _nin?: Maybe<boolean[]>;
}
/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
    _eq?: Maybe<string>;

    _gt?: Maybe<string>;

    _gte?: Maybe<string>;

    _ilike?: Maybe<string>;

    _in?: Maybe<string[]>;

    _is_null?: Maybe<boolean>;

    _like?: Maybe<string>;

    _lt?: Maybe<string>;

    _lte?: Maybe<string>;

    _neq?: Maybe<string>;

    _nilike?: Maybe<string>;

    _nin?: Maybe<string[]>;

    _nlike?: Maybe<string>;

    _nsimilar?: Maybe<string>;

    _similar?: Maybe<string>;
}
/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export interface JsonbComparisonExp {
    /** is the column contained in the given json value */
    _contained_in?: Maybe<Jsonb>;
    /** does the column contain the given json value at the top level */
    _contains?: Maybe<Jsonb>;

    _eq?: Maybe<Jsonb>;

    _gt?: Maybe<Jsonb>;

    _gte?: Maybe<Jsonb>;
    /** does the string exist as a top-level key in the column */
    _has_key?: Maybe<string>;
    /** do all of these strings exist as top-level keys in the column */
    _has_keys_all?: Maybe<string[]>;
    /** do any of these strings exist as top-level keys in the column */
    _has_keys_any?: Maybe<string[]>;

    _in?: Maybe<Jsonb[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Jsonb>;

    _lte?: Maybe<Jsonb>;

    _neq?: Maybe<Jsonb>;

    _nin?: Maybe<Jsonb[]>;
}
/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export interface UuidComparisonExp {
    _eq?: Maybe<Uuid>;

    _gt?: Maybe<Uuid>;

    _gte?: Maybe<Uuid>;

    _in?: Maybe<Uuid[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Uuid>;

    _lte?: Maybe<Uuid>;

    _neq?: Maybe<Uuid>;

    _nin?: Maybe<Uuid[]>;
}
/** ordering options when selecting data from "CalendarEvents" */
export interface CalendarEventsOrderBy {
    AllDay?: Maybe<OrderBy>;

    ColorPrimary?: Maybe<OrderBy>;

    ColorSecondary?: Maybe<OrderBy>;

    Draggable?: Maybe<OrderBy>;

    End?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MetaLocation?: Maybe<OrderBy>;

    MetaNotes?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ResizableAfterEnd?: Maybe<OrderBy>;

    ResizableBeforeStart?: Maybe<OrderBy>;

    Start?: Maybe<OrderBy>;

    Title?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "CalendarEvents". All fields are combined with a logical 'AND'. */
export interface CalendarEventsBoolExp {
    AllDay?: Maybe<BooleanComparisonExp>;

    ColorPrimary?: Maybe<StringComparisonExp>;

    ColorSecondary?: Maybe<StringComparisonExp>;

    Draggable?: Maybe<BooleanComparisonExp>;

    End?: Maybe<TimestamptzComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    MetaLocation?: Maybe<StringComparisonExp>;

    MetaNotes?: Maybe<StringComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    ResizableAfterEnd?: Maybe<BooleanComparisonExp>;

    ResizableBeforeStart?: Maybe<BooleanComparisonExp>;

    Start?: Maybe<TimestamptzComparisonExp>;

    Title?: Maybe<StringComparisonExp>;

    UserId?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<CalendarEventsBoolExp>)[]>;

    _not?: Maybe<CalendarEventsBoolExp>;

    _or?: Maybe<(Maybe<CalendarEventsBoolExp>)[]>;
}
/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export interface TimestamptzComparisonExp {
    _eq?: Maybe<Timestamptz>;

    _gt?: Maybe<Timestamptz>;

    _gte?: Maybe<Timestamptz>;

    _in?: Maybe<Timestamptz[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Timestamptz>;

    _lte?: Maybe<Timestamptz>;

    _neq?: Maybe<Timestamptz>;

    _nin?: Maybe<Timestamptz[]>;
}
/** ordering options when selecting data from "ClientGroups" */
export interface ClientGroupsOrderBy {
    ClientGroupsSmartlabUsers_aggregate?: Maybe<
        ClientGroupsSmartlabUsersAggregateOrderBy
    >;

    ClientIds?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ClientGroupsSmartlabUsersMaxOrderBy>;

    min?: Maybe<ClientGroupsSmartlabUsersMinOrderBy>;
}
/** order by max() on columns of table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersMaxOrderBy {
    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersMinOrderBy {
    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "ClientGroups". All fields are combined with a logical 'AND'. */
export interface ClientGroupsBoolExp {
    ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsersBoolExp>;

    ClientIds?: Maybe<_TextComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<ClientGroupsBoolExp>)[]>;

    _not?: Maybe<ClientGroupsBoolExp>;

    _or?: Maybe<(Maybe<ClientGroupsBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "ClientGroupsSmartlabUsers". All fields are combined with a logical 'AND'. */
export interface ClientGroupsSmartlabUsersBoolExp {
    ClientGroup?: Maybe<ClientGroupsBoolExp>;

    ClientGroupId?: Maybe<UuidComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    SmartlabUser?: Maybe<SmartlabUsersBoolExp>;

    SmartlabUserId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<ClientGroupsSmartlabUsersBoolExp>)[]>;

    _not?: Maybe<ClientGroupsSmartlabUsersBoolExp>;

    _or?: Maybe<(Maybe<ClientGroupsSmartlabUsersBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "SmartlabUsers". All fields are combined with a logical 'AND'. */
export interface SmartlabUsersBoolExp {
    ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsersBoolExp>;

    Id?: Maybe<UuidComparisonExp>;

    ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;

    UserId?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<SmartlabUsersBoolExp>)[]>;

    _not?: Maybe<SmartlabUsersBoolExp>;

    _or?: Maybe<(Maybe<SmartlabUsersBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "ProjectGroupsSmartlabUsers". All fields are combined with a logical 'AND'. */
export interface ProjectGroupsSmartlabUsersBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    ProjectGroup?: Maybe<ProjectGroupsBoolExp>;

    ProjectGroupId?: Maybe<UuidComparisonExp>;

    SmartlabUser?: Maybe<SmartlabUsersBoolExp>;

    SmartlabUserId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<ProjectGroupsSmartlabUsersBoolExp>)[]>;

    _not?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;

    _or?: Maybe<(Maybe<ProjectGroupsSmartlabUsersBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "ProjectGroups". All fields are combined with a logical 'AND'. */
export interface ProjectGroupsBoolExp {
    ClientGroup?: Maybe<ClientGroupsBoolExp>;

    ClientGroupId?: Maybe<UuidComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    ProjectCodes?: Maybe<_TextComparisonExp>;

    ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;

    _and?: Maybe<(Maybe<ProjectGroupsBoolExp>)[]>;

    _not?: Maybe<ProjectGroupsBoolExp>;

    _or?: Maybe<(Maybe<ProjectGroupsBoolExp>)[]>;
}
/** expression to compare columns of type _text. All fields are combined with logical 'AND'. */
export interface _TextComparisonExp {
    _eq?: Maybe<_Text>;

    _gt?: Maybe<_Text>;

    _gte?: Maybe<_Text>;

    _in?: Maybe<_Text[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<_Text>;

    _lte?: Maybe<_Text>;

    _neq?: Maybe<_Text>;

    _nin?: Maybe<_Text[]>;
}
/** ordering options when selecting data from "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersOrderBy {
    ClientGroup?: Maybe<ClientGroupsOrderBy>;

    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SmartlabUser?: Maybe<SmartlabUsersOrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "SmartlabUsers" */
export interface SmartlabUsersOrderBy {
    ClientGroupsSmartlabUsers_aggregate?: Maybe<
        ClientGroupsSmartlabUsersAggregateOrderBy
    >;

    Id?: Maybe<OrderBy>;

    ProjectGroupsSmartlabUsers_aggregate?: Maybe<
        ProjectGroupsSmartlabUsersAggregateOrderBy
    >;

    UserId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ProjectGroupsSmartlabUsersMaxOrderBy>;

    min?: Maybe<ProjectGroupsSmartlabUsersMinOrderBy>;
}
/** order by max() on columns of table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersMaxOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersMinOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectGroup?: Maybe<ProjectGroupsOrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    SmartlabUser?: Maybe<SmartlabUsersOrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "ProjectGroups" */
export interface ProjectGroupsOrderBy {
    ClientGroup?: Maybe<ClientGroupsOrderBy>;

    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectCodes?: Maybe<OrderBy>;

    ProjectGroupsSmartlabUsers_aggregate?: Maybe<
        ProjectGroupsSmartlabUsersAggregateOrderBy
    >;
}
/** ordering options when selecting data from "CumulTokens" */
export interface CumulTokensOrderBy {
    AuthorizationId?: Maybe<OrderBy>;

    AuthorizationToken?: Maybe<OrderBy>;

    CreatedAt?: Maybe<OrderBy>;

    HashedRequestSignature?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Revoked?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;

    ValidUntil?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "CumulTokens". All fields are combined with a logical 'AND'. */
export interface CumulTokensBoolExp {
    AuthorizationId?: Maybe<StringComparisonExp>;

    AuthorizationToken?: Maybe<StringComparisonExp>;

    CreatedAt?: Maybe<TimestampComparisonExp>;

    HashedRequestSignature?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Revoked?: Maybe<BooleanComparisonExp>;

    UserId?: Maybe<StringComparisonExp>;

    ValidUntil?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<(Maybe<CumulTokensBoolExp>)[]>;

    _not?: Maybe<CumulTokensBoolExp>;

    _or?: Maybe<(Maybe<CumulTokensBoolExp>)[]>;
}
/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export interface TimestampComparisonExp {
    _eq?: Maybe<Timestamp>;

    _gt?: Maybe<Timestamp>;

    _gte?: Maybe<Timestamp>;

    _in?: Maybe<Timestamp[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Timestamp>;

    _lte?: Maybe<Timestamp>;

    _neq?: Maybe<Timestamp>;

    _nin?: Maybe<Timestamp[]>;
}
/** ordering options when selecting data from "DataProcConfig" */
export interface DataProcConfigOrderBy {
    DataProcConfigCopyDataForDemos_aggregate?: Maybe<
        DataProcConfigCopyDataForDemoAggregateOrderBy
    >;

    DataProcConfigCopyInflux_aggregate?: Maybe<
        DataProcConfigCopyInfluxAggregateOrderBy
    >;

    DataProcConfigEwon_aggregate?: Maybe<DataProcConfig1AggregateOrderBy>;

    DataProcConfigInflux_aggregate?: Maybe<DataProcConfig2AggregateOrderBy>;

    DataProcConfigPostgres_aggregate?: Maybe<DataProcConfig3AggregateOrderBy>;

    DataProcStates_aggregate?: Maybe<DataProcStateAggregateOrderBy>;

    DataProcTagStates_aggregate?: Maybe<DataProcTagStateAggregateOrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsStateEnabled?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfigCopyDataForDemoMaxOrderBy>;

    min?: Maybe<DataProcConfigCopyDataForDemoMinOrderBy>;
}
/** order by max() on columns of table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoMaxOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    CopyFromDate?: Maybe<OrderBy>;

    CopyToDate?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoMinOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    CopyFromDate?: Maybe<OrderBy>;

    CopyToDate?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxAggregateOrderBy {
    avg?: Maybe<DataProcConfigCopyInfluxAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfigCopyInfluxMaxOrderBy>;

    min?: Maybe<DataProcConfigCopyInfluxMinOrderBy>;

    stddev?: Maybe<DataProcConfigCopyInfluxStddevOrderBy>;

    stddev_pop?: Maybe<DataProcConfigCopyInfluxStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcConfigCopyInfluxStddevSampOrderBy>;

    sum?: Maybe<DataProcConfigCopyInfluxSumOrderBy>;

    var_pop?: Maybe<DataProcConfigCopyInfluxVarPopOrderBy>;

    var_samp?: Maybe<DataProcConfigCopyInfluxVarSampOrderBy>;

    variance?: Maybe<DataProcConfigCopyInfluxVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxAvgOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxMaxOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;

    Database?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxMinOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;

    Database?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxStddevOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxStddevPopOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxStddevSampOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxSumOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxVarPopOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxVarSampOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxVarianceOrderBy {
    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcConfig1" */
export interface DataProcConfig1AggregateOrderBy {
    avg?: Maybe<DataProcConfig1AvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfig1MaxOrderBy>;

    min?: Maybe<DataProcConfig1MinOrderBy>;

    stddev?: Maybe<DataProcConfig1StddevOrderBy>;

    stddev_pop?: Maybe<DataProcConfig1StddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcConfig1StddevSampOrderBy>;

    sum?: Maybe<DataProcConfig1SumOrderBy>;

    var_pop?: Maybe<DataProcConfig1VarPopOrderBy>;

    var_samp?: Maybe<DataProcConfig1VarSampOrderBy>;

    variance?: Maybe<DataProcConfig1VarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcConfig1" */
export interface DataProcConfig1AvgOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcConfig1" */
export interface DataProcConfig1MaxOrderBy {
    Account?: Maybe<OrderBy>;

    ConfigurationModelId?: Maybe<OrderBy>;

    DevId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Password?: Maybe<OrderBy>;

    RowThreshold?: Maybe<OrderBy>;

    Uri?: Maybe<OrderBy>;

    Username?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfig1" */
export interface DataProcConfig1MinOrderBy {
    Account?: Maybe<OrderBy>;

    ConfigurationModelId?: Maybe<OrderBy>;

    DevId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Password?: Maybe<OrderBy>;

    RowThreshold?: Maybe<OrderBy>;

    Uri?: Maybe<OrderBy>;

    Username?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcConfig1" */
export interface DataProcConfig1StddevOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcConfig1" */
export interface DataProcConfig1StddevPopOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcConfig1" */
export interface DataProcConfig1StddevSampOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcConfig1" */
export interface DataProcConfig1SumOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcConfig1" */
export interface DataProcConfig1VarPopOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcConfig1" */
export interface DataProcConfig1VarSampOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcConfig1" */
export interface DataProcConfig1VarianceOrderBy {
    RowThreshold?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcConfig2" */
export interface DataProcConfig2AggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfig2MaxOrderBy>;

    min?: Maybe<DataProcConfig2MinOrderBy>;
}
/** order by max() on columns of table "DataProcConfig2" */
export interface DataProcConfig2MaxOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    Database?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfig2" */
export interface DataProcConfig2MinOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    Database?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcConfig3" */
export interface DataProcConfig3AggregateOrderBy {
    avg?: Maybe<DataProcConfig3AvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfig3MaxOrderBy>;

    min?: Maybe<DataProcConfig3MinOrderBy>;

    stddev?: Maybe<DataProcConfig3StddevOrderBy>;

    stddev_pop?: Maybe<DataProcConfig3StddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcConfig3StddevSampOrderBy>;

    sum?: Maybe<DataProcConfig3SumOrderBy>;

    var_pop?: Maybe<DataProcConfig3VarPopOrderBy>;

    var_samp?: Maybe<DataProcConfig3VarSampOrderBy>;

    variance?: Maybe<DataProcConfig3VarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcConfig3" */
export interface DataProcConfig3AvgOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcConfig3" */
export interface DataProcConfig3MaxOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    HourOffset?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfig3" */
export interface DataProcConfig3MinOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    HourOffset?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcConfig3" */
export interface DataProcConfig3StddevOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcConfig3" */
export interface DataProcConfig3StddevPopOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcConfig3" */
export interface DataProcConfig3StddevSampOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcConfig3" */
export interface DataProcConfig3SumOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcConfig3" */
export interface DataProcConfig3VarPopOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcConfig3" */
export interface DataProcConfig3VarSampOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcConfig3" */
export interface DataProcConfig3VarianceOrderBy {
    HourOffset?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcState" */
export interface DataProcStateAggregateOrderBy {
    avg?: Maybe<DataProcStateAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcStateMaxOrderBy>;

    min?: Maybe<DataProcStateMinOrderBy>;

    stddev?: Maybe<DataProcStateStddevOrderBy>;

    stddev_pop?: Maybe<DataProcStateStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcStateStddevSampOrderBy>;

    sum?: Maybe<DataProcStateSumOrderBy>;

    var_pop?: Maybe<DataProcStateVarPopOrderBy>;

    var_samp?: Maybe<DataProcStateVarSampOrderBy>;

    variance?: Maybe<DataProcStateVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcState" */
export interface DataProcStateAvgOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcState" */
export interface DataProcStateMaxOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    CopyDataForDemoEndDate?: Maybe<OrderBy>;

    EndedDate?: Maybe<OrderBy>;

    EwonErrorMessage?: Maybe<OrderBy>;

    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonProcessingEndedDate?: Maybe<OrderBy>;

    EwonProcessingStartedDate?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiDate?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    StartedDate?: Maybe<OrderBy>;

    TagProcessingEndedDate?: Maybe<OrderBy>;

    TagProcessingErrorMessage?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingStartedDate?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcState" */
export interface DataProcStateMinOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    CopyDataForDemoEndDate?: Maybe<OrderBy>;

    EndedDate?: Maybe<OrderBy>;

    EwonErrorMessage?: Maybe<OrderBy>;

    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonProcessingEndedDate?: Maybe<OrderBy>;

    EwonProcessingStartedDate?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiDate?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    StartedDate?: Maybe<OrderBy>;

    TagProcessingEndedDate?: Maybe<OrderBy>;

    TagProcessingErrorMessage?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingStartedDate?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcState" */
export interface DataProcStateStddevOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcState" */
export interface DataProcStateStddevPopOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcState" */
export interface DataProcStateStddevSampOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcState" */
export interface DataProcStateSumOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcState" */
export interface DataProcStateVarPopOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcState" */
export interface DataProcStateVarSampOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcState" */
export interface DataProcStateVarianceOrderBy {
    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcTagState" */
export interface DataProcTagStateAggregateOrderBy {
    avg?: Maybe<DataProcTagStateAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcTagStateMaxOrderBy>;

    min?: Maybe<DataProcTagStateMinOrderBy>;

    stddev?: Maybe<DataProcTagStateStddevOrderBy>;

    stddev_pop?: Maybe<DataProcTagStateStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcTagStateStddevSampOrderBy>;

    sum?: Maybe<DataProcTagStateSumOrderBy>;

    var_pop?: Maybe<DataProcTagStateVarPopOrderBy>;

    var_samp?: Maybe<DataProcTagStateVarSampOrderBy>;

    variance?: Maybe<DataProcTagStateVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcTagState" */
export interface DataProcTagStateAvgOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcTagState" */
export interface DataProcTagStateMaxOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    BlockName?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    ErrorString?: Maybe<OrderBy>;

    FirstPendingDate?: Maybe<OrderBy>;

    Formula?: Maybe<OrderBy>;

    InfluxTable?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    LastDependencyDate?: Maybe<OrderBy>;

    LastPendingDate?: Maybe<OrderBy>;

    LastProcessedDate?: Maybe<OrderBy>;

    OutputSignalId?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    ProcessedRangeDone?: Maybe<OrderBy>;

    ProcessedRangeFrom?: Maybe<OrderBy>;

    ProcessedRangeTo?: Maybe<OrderBy>;

    ProcessingEndedDate?: Maybe<OrderBy>;

    ProcessingStartedDate?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;

    TagName?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcTagState" */
export interface DataProcTagStateMinOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    BlockName?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    ErrorString?: Maybe<OrderBy>;

    FirstPendingDate?: Maybe<OrderBy>;

    Formula?: Maybe<OrderBy>;

    InfluxTable?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    LastDependencyDate?: Maybe<OrderBy>;

    LastPendingDate?: Maybe<OrderBy>;

    LastProcessedDate?: Maybe<OrderBy>;

    OutputSignalId?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    ProcessedRangeDone?: Maybe<OrderBy>;

    ProcessedRangeFrom?: Maybe<OrderBy>;

    ProcessedRangeTo?: Maybe<OrderBy>;

    ProcessingEndedDate?: Maybe<OrderBy>;

    ProcessingStartedDate?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;

    TagName?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcTagState" */
export interface DataProcTagStateStddevOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcTagState" */
export interface DataProcTagStateStddevPopOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcTagState" */
export interface DataProcTagStateStddevSampOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcTagState" */
export interface DataProcTagStateSumOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcTagState" */
export interface DataProcTagStateVarPopOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcTagState" */
export interface DataProcTagStateVarSampOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcTagState" */
export interface DataProcTagStateVarianceOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcConfig". All fields are combined with a logical 'AND'. */
export interface DataProcConfigBoolExp {
    DataProcConfigCopyDataForDemos?: Maybe<
        DataProcConfigCopyDataForDemoBoolExp
    >;

    DataProcConfigCopyInflux?: Maybe<DataProcConfigCopyInfluxBoolExp>;

    DataProcConfigEwon?: Maybe<DataProcConfig1BoolExp>;

    DataProcConfigInflux?: Maybe<DataProcConfig2BoolExp>;

    DataProcConfigPostgres?: Maybe<DataProcConfig3BoolExp>;

    DataProcStates?: Maybe<DataProcStateBoolExp>;

    DataProcTagStates?: Maybe<DataProcTagStateBoolExp>;

    FrequencyMinutes?: Maybe<BigintComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsStateEnabled?: Maybe<BooleanComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<DataProcConfigBoolExp>)[]>;

    _not?: Maybe<DataProcConfigBoolExp>;

    _or?: Maybe<(Maybe<DataProcConfigBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcConfigCopyDataForDemo". All fields are combined with a logical 'AND'. */
export interface DataProcConfigCopyDataForDemoBoolExp {
    ConfigurationModelId?: Maybe<UuidComparisonExp>;

    CopyFromDate?: Maybe<TimestampComparisonExp>;

    CopyToDate?: Maybe<TimestampComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    Enabled?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<DataProcConfigCopyDataForDemoBoolExp>)[]>;

    _not?: Maybe<DataProcConfigCopyDataForDemoBoolExp>;

    _or?: Maybe<(Maybe<DataProcConfigCopyDataForDemoBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcConfigCopyInflux". All fields are combined with a logical 'AND'. */
export interface DataProcConfigCopyInfluxBoolExp {
    ConfigurationModelId?: Maybe<UuidComparisonExp>;

    CopyRangeInSecondsPerRun?: Maybe<IntComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    Database?: Maybe<StringComparisonExp>;

    Enabled?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<DataProcConfigCopyInfluxBoolExp>)[]>;

    _not?: Maybe<DataProcConfigCopyInfluxBoolExp>;

    _or?: Maybe<(Maybe<DataProcConfigCopyInfluxBoolExp>)[]>;
}
/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export interface IntComparisonExp {
    _eq?: Maybe<number>;

    _gt?: Maybe<number>;

    _gte?: Maybe<number>;

    _in?: Maybe<number[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<number>;

    _lte?: Maybe<number>;

    _neq?: Maybe<number>;

    _nin?: Maybe<number[]>;
}
/** Boolean expression to filter rows from the table "DataProcConfig1". All fields are combined with a logical 'AND'. */
export interface DataProcConfig1BoolExp {
    Account?: Maybe<StringComparisonExp>;

    ConfigurationModelId?: Maybe<UuidComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    DevId?: Maybe<StringComparisonExp>;

    Enabled?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Password?: Maybe<StringComparisonExp>;

    RowThreshold?: Maybe<IntComparisonExp>;

    Uri?: Maybe<StringComparisonExp>;

    Username?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<DataProcConfig1BoolExp>)[]>;

    _not?: Maybe<DataProcConfig1BoolExp>;

    _or?: Maybe<(Maybe<DataProcConfig1BoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcConfig2". All fields are combined with a logical 'AND'. */
export interface DataProcConfig2BoolExp {
    ConfigurationModelId?: Maybe<UuidComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    Database?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<DataProcConfig2BoolExp>)[]>;

    _not?: Maybe<DataProcConfig2BoolExp>;

    _or?: Maybe<(Maybe<DataProcConfig2BoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcConfig3". All fields are combined with a logical 'AND'. */
export interface DataProcConfig3BoolExp {
    ConfigurationModelId?: Maybe<UuidComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    Enabled?: Maybe<BooleanComparisonExp>;

    HourOffset?: Maybe<IntComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<DataProcConfig3BoolExp>)[]>;

    _not?: Maybe<DataProcConfig3BoolExp>;

    _or?: Maybe<(Maybe<DataProcConfig3BoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcState". All fields are combined with a logical 'AND'. */
export interface DataProcStateBoolExp {
    ConfigurationId?: Maybe<UuidComparisonExp>;

    CopyDataForDemoEndDate?: Maybe<TimestampComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    EndedDate?: Maybe<TimestampComparisonExp>;

    EwonErrorMessage?: Maybe<StringComparisonExp>;

    EwonLoops?: Maybe<IntComparisonExp>;

    EwonPointsReceived?: Maybe<IntComparisonExp>;

    EwonProcessingEndedDate?: Maybe<TimestampComparisonExp>;

    EwonProcessingStartedDate?: Maybe<TimestampComparisonExp>;

    EwonResult?: Maybe<IntComparisonExp>;

    EwonSuccess?: Maybe<BooleanComparisonExp>;

    EwonTotalPointsReceived?: Maybe<NumericComparisonExp>;

    EwonTransactionId?: Maybe<IntComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    JobNr?: Maybe<NumericComparisonExp>;

    KpiDate?: Maybe<TimestampComparisonExp>;

    KpiValue?: Maybe<Float8ComparisonExp>;

    StartedDate?: Maybe<TimestampComparisonExp>;

    TagProcessingEndedDate?: Maybe<TimestampComparisonExp>;

    TagProcessingErrorMessage?: Maybe<StringComparisonExp>;

    TagProcessingPointsProcessed?: Maybe<BigintComparisonExp>;

    TagProcessingStartedDate?: Maybe<TimestampComparisonExp>;

    TagProcessingTagsDoneProcessing?: Maybe<BigintComparisonExp>;

    TagProcessingTagsProcessed?: Maybe<BigintComparisonExp>;

    TagProcessingTotalPointsProcessed?: Maybe<NumericComparisonExp>;

    Updated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<(Maybe<DataProcStateBoolExp>)[]>;

    _not?: Maybe<DataProcStateBoolExp>;

    _or?: Maybe<(Maybe<DataProcStateBoolExp>)[]>;
}
/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export interface NumericComparisonExp {
    _eq?: Maybe<Numeric>;

    _gt?: Maybe<Numeric>;

    _gte?: Maybe<Numeric>;

    _in?: Maybe<Numeric[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Numeric>;

    _lte?: Maybe<Numeric>;

    _neq?: Maybe<Numeric>;

    _nin?: Maybe<Numeric[]>;
}
/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export interface Float8ComparisonExp {
    _eq?: Maybe<Float8>;

    _gt?: Maybe<Float8>;

    _gte?: Maybe<Float8>;

    _in?: Maybe<Float8[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Float8>;

    _lte?: Maybe<Float8>;

    _neq?: Maybe<Float8>;

    _nin?: Maybe<Float8[]>;
}
/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export interface BigintComparisonExp {
    _eq?: Maybe<Bigint>;

    _gt?: Maybe<Bigint>;

    _gte?: Maybe<Bigint>;

    _in?: Maybe<Bigint[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Bigint>;

    _lte?: Maybe<Bigint>;

    _neq?: Maybe<Bigint>;

    _nin?: Maybe<Bigint[]>;
}
/** Boolean expression to filter rows from the table "DataProcTagState". All fields are combined with a logical 'AND'. */
export interface DataProcTagStateBoolExp {
    AggregationTime?: Maybe<IntComparisonExp>;

    AggregationType?: Maybe<IntComparisonExp>;

    BlockId?: Maybe<IntComparisonExp>;

    BlockName?: Maybe<StringComparisonExp>;

    Children?: Maybe<DataProcTagStateDepsBoolExp>;

    ConfigurationId?: Maybe<UuidComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    ErrorString?: Maybe<StringComparisonExp>;

    FirstPendingDate?: Maybe<TimestampComparisonExp>;

    Formula?: Maybe<StringComparisonExp>;

    InfluxTable?: Maybe<StringComparisonExp>;

    IsProcessing?: Maybe<BooleanComparisonExp>;

    JobNr?: Maybe<NumericComparisonExp>;

    LastDependencyDate?: Maybe<TimestampComparisonExp>;

    LastPendingDate?: Maybe<TimestampComparisonExp>;

    LastProcessedDate?: Maybe<TimestampComparisonExp>;

    OutputSignal?: Maybe<DataProcSignalBoolExp>;

    OutputSignalId?: Maybe<UuidComparisonExp>;

    Parents?: Maybe<DataProcTagStateDepsBoolExp>;

    PointsProcessed?: Maybe<BigintComparisonExp>;

    ProcessedRangeDone?: Maybe<TimestampComparisonExp>;

    ProcessedRangeFrom?: Maybe<TimestampComparisonExp>;

    ProcessedRangeTo?: Maybe<TimestampComparisonExp>;

    ProcessingEndedDate?: Maybe<TimestampComparisonExp>;

    ProcessingStartedDate?: Maybe<TimestampComparisonExp>;

    Result?: Maybe<IntComparisonExp>;

    TagId?: Maybe<IntComparisonExp>;

    TagName?: Maybe<StringComparisonExp>;

    Updated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<(Maybe<DataProcTagStateBoolExp>)[]>;

    _not?: Maybe<DataProcTagStateBoolExp>;

    _or?: Maybe<(Maybe<DataProcTagStateBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcTagStateDeps". All fields are combined with a logical 'AND'. */
export interface DataProcTagStateDepsBoolExp {
    Child?: Maybe<DataProcTagStateBoolExp>;

    ChildAggregationTime?: Maybe<IntComparisonExp>;

    ChildAggregationType?: Maybe<IntComparisonExp>;

    ChildTagId?: Maybe<IntComparisonExp>;

    Parent?: Maybe<DataProcTagStateBoolExp>;

    ParentAggregationTime?: Maybe<IntComparisonExp>;

    ParentAggregationType?: Maybe<IntComparisonExp>;

    ParentTagId?: Maybe<IntComparisonExp>;

    _and?: Maybe<(Maybe<DataProcTagStateDepsBoolExp>)[]>;

    _not?: Maybe<DataProcTagStateDepsBoolExp>;

    _or?: Maybe<(Maybe<DataProcTagStateDepsBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "DataProcSignal". All fields are combined with a logical 'AND'. */
export interface DataProcSignalBoolExp {
    AggregationTime?: Maybe<IntComparisonExp>;

    AggregationType?: Maybe<IntComparisonExp>;

    BlockId?: Maybe<IntComparisonExp>;

    DataProcTagStates?: Maybe<DataProcTagStateBoolExp>;

    DataType?: Maybe<IntComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    TableName?: Maybe<StringComparisonExp>;

    TagId?: Maybe<IntComparisonExp>;

    _and?: Maybe<(Maybe<DataProcSignalBoolExp>)[]>;

    _not?: Maybe<DataProcSignalBoolExp>;

    _or?: Maybe<(Maybe<DataProcSignalBoolExp>)[]>;
}
/** ordering options when selecting data from "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    CopyFromDate?: Maybe<OrderBy>;

    CopyToDate?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    Enabled?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxOrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    CopyRangeInSecondsPerRun?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    Database?: Maybe<OrderBy>;

    Enabled?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcConfig1" */
export interface DataProcConfig1OrderBy {
    Account?: Maybe<OrderBy>;

    ConfigurationModelId?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    DevId?: Maybe<OrderBy>;

    Enabled?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Password?: Maybe<OrderBy>;

    RowThreshold?: Maybe<OrderBy>;

    Uri?: Maybe<OrderBy>;

    Username?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcConfig2" */
export interface DataProcConfig2OrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    Database?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcConfig3" */
export interface DataProcConfig3OrderBy {
    ConfigurationModelId?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    Enabled?: Maybe<OrderBy>;

    HourOffset?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcState" */
export interface DataProcStateOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    CopyDataForDemoEndDate?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    EndedDate?: Maybe<OrderBy>;

    EwonErrorMessage?: Maybe<OrderBy>;

    EwonLoops?: Maybe<OrderBy>;

    EwonPointsReceived?: Maybe<OrderBy>;

    EwonProcessingEndedDate?: Maybe<OrderBy>;

    EwonProcessingStartedDate?: Maybe<OrderBy>;

    EwonResult?: Maybe<OrderBy>;

    EwonSuccess?: Maybe<OrderBy>;

    EwonTotalPointsReceived?: Maybe<OrderBy>;

    EwonTransactionId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiDate?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    StartedDate?: Maybe<OrderBy>;

    TagProcessingEndedDate?: Maybe<OrderBy>;

    TagProcessingErrorMessage?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingStartedDate?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcTagState" */
export interface DataProcTagStateOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    BlockName?: Maybe<OrderBy>;

    Children_aggregate?: Maybe<DataProcTagStateDepsAggregateOrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    ErrorString?: Maybe<OrderBy>;

    FirstPendingDate?: Maybe<OrderBy>;

    Formula?: Maybe<OrderBy>;

    InfluxTable?: Maybe<OrderBy>;

    IsProcessing?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    LastDependencyDate?: Maybe<OrderBy>;

    LastPendingDate?: Maybe<OrderBy>;

    LastProcessedDate?: Maybe<OrderBy>;

    OutputSignal?: Maybe<DataProcSignalOrderBy>;

    OutputSignalId?: Maybe<OrderBy>;

    Parents_aggregate?: Maybe<DataProcTagStateDepsAggregateOrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    ProcessedRangeDone?: Maybe<OrderBy>;

    ProcessedRangeFrom?: Maybe<OrderBy>;

    ProcessedRangeTo?: Maybe<OrderBy>;

    ProcessingEndedDate?: Maybe<OrderBy>;

    ProcessingStartedDate?: Maybe<OrderBy>;

    Result?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;

    TagName?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsAggregateOrderBy {
    avg?: Maybe<DataProcTagStateDepsAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcTagStateDepsMaxOrderBy>;

    min?: Maybe<DataProcTagStateDepsMinOrderBy>;

    stddev?: Maybe<DataProcTagStateDepsStddevOrderBy>;

    stddev_pop?: Maybe<DataProcTagStateDepsStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcTagStateDepsStddevSampOrderBy>;

    sum?: Maybe<DataProcTagStateDepsSumOrderBy>;

    var_pop?: Maybe<DataProcTagStateDepsVarPopOrderBy>;

    var_samp?: Maybe<DataProcTagStateDepsVarSampOrderBy>;

    variance?: Maybe<DataProcTagStateDepsVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsAvgOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsMaxOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsMinOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsStddevOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsStddevPopOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsStddevSampOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsSumOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsVarPopOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsVarSampOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsVarianceOrderBy {
    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcSignal" */
export interface DataProcSignalOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataProcTagStates_aggregate?: Maybe<DataProcTagStateAggregateOrderBy>;

    DataType?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    TableName?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "DataProcTagStateDeps" */
export interface DataProcTagStateDepsOrderBy {
    Child?: Maybe<DataProcTagStateOrderBy>;

    ChildAggregationTime?: Maybe<OrderBy>;

    ChildAggregationType?: Maybe<OrderBy>;

    ChildTagId?: Maybe<OrderBy>;

    Parent?: Maybe<DataProcTagStateOrderBy>;

    ParentAggregationTime?: Maybe<OrderBy>;

    ParentAggregationType?: Maybe<OrderBy>;

    ParentTagId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "GeneratedReports" */
export interface GeneratedReportsOrderBy {
    Created?: Maybe<OrderBy>;

    DocUrl?: Maybe<OrderBy>;

    DynamicDataSnapshot?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Finished?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectConfigurationSnapshot?: Maybe<OrderBy>;

    ProjectReportTemplateId?: Maybe<OrderBy>;

    ProjectReportTemplateSnapshot?: Maybe<OrderBy>;

    ReportDateTime?: Maybe<OrderBy>;

    ReportTemplate?: Maybe<ReportTemplatesOrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;

    Warnings?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "ReportTemplates" */
export interface ReportTemplatesOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    GeneratedReports_aggregate?: Maybe<GeneratedReportsAggregateOrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectReportTemplates_aggregate?: Maybe<
        ProjectReportTemplatesAggregateOrderBy
    >;

    ReportTemplateBlocks?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;
}
/** order by aggregate values of table "GeneratedReports" */
export interface GeneratedReportsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<GeneratedReportsMaxOrderBy>;

    min?: Maybe<GeneratedReportsMinOrderBy>;
}
/** order by max() on columns of table "GeneratedReports" */
export interface GeneratedReportsMaxOrderBy {
    Created?: Maybe<OrderBy>;

    DocUrl?: Maybe<OrderBy>;

    Finished?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectReportTemplateId?: Maybe<OrderBy>;

    ReportDateTime?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by min() on columns of table "GeneratedReports" */
export interface GeneratedReportsMinOrderBy {
    Created?: Maybe<OrderBy>;

    DocUrl?: Maybe<OrderBy>;

    Finished?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectReportTemplateId?: Maybe<OrderBy>;

    ReportDateTime?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ProjectReportTemplates" */
export interface ProjectReportTemplatesAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ProjectReportTemplatesMaxOrderBy>;

    min?: Maybe<ProjectReportTemplatesMinOrderBy>;
}
/** order by max() on columns of table "ProjectReportTemplates" */
export interface ProjectReportTemplatesMaxOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Frequency?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ProjectReportTemplates" */
export interface ProjectReportTemplatesMinOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Frequency?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "GeneratedReports". All fields are combined with a logical 'AND'. */
export interface GeneratedReportsBoolExp {
    Created?: Maybe<TimestampComparisonExp>;

    DocUrl?: Maybe<StringComparisonExp>;

    DynamicDataSnapshot?: Maybe<JsonbComparisonExp>;

    Errors?: Maybe<_TextComparisonExp>;

    Finished?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ProjectConfigurationSnapshot?: Maybe<JsonbComparisonExp>;

    ProjectReportTemplateId?: Maybe<UuidComparisonExp>;

    ProjectReportTemplateSnapshot?: Maybe<JsonbComparisonExp>;

    ReportDateTime?: Maybe<TimestampComparisonExp>;

    ReportTemplate?: Maybe<ReportTemplatesBoolExp>;

    ReportTemplateId?: Maybe<UuidComparisonExp>;

    State?: Maybe<StringComparisonExp>;

    Version?: Maybe<StringComparisonExp>;

    Warnings?: Maybe<_TextComparisonExp>;

    WebUrl?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<GeneratedReportsBoolExp>)[]>;

    _not?: Maybe<GeneratedReportsBoolExp>;

    _or?: Maybe<(Maybe<GeneratedReportsBoolExp>)[]>;

    projectCode?: Maybe<StringComparisonExp>;
}
/** Boolean expression to filter rows from the table "ReportTemplates". All fields are combined with a logical 'AND'. */
export interface ReportTemplatesBoolExp {
    CreatedDate?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DeletedDate?: Maybe<TimestampComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    GeneratedReports?: Maybe<GeneratedReportsBoolExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModifiedDate?: Maybe<TimestampComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    ProjectReportTemplates?: Maybe<ProjectReportTemplatesBoolExp>;

    ReportTemplateBlocks?: Maybe<JsonbComparisonExp>;

    Version?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<ReportTemplatesBoolExp>)[]>;

    _not?: Maybe<ReportTemplatesBoolExp>;

    _or?: Maybe<(Maybe<ReportTemplatesBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "ProjectReportTemplates". All fields are combined with a logical 'AND'. */
export interface ProjectReportTemplatesBoolExp {
    CreatedDate?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DeletedDate?: Maybe<TimestampComparisonExp>;

    Frequency?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModifiedDate?: Maybe<TimestampComparisonExp>;

    Parameters?: Maybe<JsonbComparisonExp>;

    ReportTemplate?: Maybe<ReportTemplatesBoolExp>;

    ReportTemplateId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<ProjectReportTemplatesBoolExp>)[]>;

    _not?: Maybe<ProjectReportTemplatesBoolExp>;

    _or?: Maybe<(Maybe<ProjectReportTemplatesBoolExp>)[]>;

    projectCode?: Maybe<StringComparisonExp>;
}
/** ordering options when selecting data from "ProjectReportTemplates" */
export interface ProjectReportTemplatesOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Frequency?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    Parameters?: Maybe<OrderBy>;

    ReportTemplate?: Maybe<ReportTemplatesOrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "LaboScheduleStates" */
export interface LaboScheduleStatesOrderBy {
    CreatedAt?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DeletedAt?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    LaboScheduleStatesPlannings_aggregate?: Maybe<
        LaboScheduleStatesPlanningAggregateOrderBy
    >;

    Name?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    Schedule?: Maybe<OrderBy>;
}
/** order by aggregate values of table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<LaboScheduleStatesPlanningMaxOrderBy>;

    min?: Maybe<LaboScheduleStatesPlanningMinOrderBy>;
}
/** order by max() on columns of table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningMaxOrderBy {
    Id?: Maybe<OrderBy>;

    Planned?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    StateId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningMinOrderBy {
    Id?: Maybe<OrderBy>;

    Planned?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    StateId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "LaboScheduleStates". All fields are combined with a logical 'AND'. */
export interface LaboScheduleStatesBoolExp {
    CreatedAt?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DeletedAt?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    LaboScheduleStatesPlannings?: Maybe<LaboScheduleStatesPlanningBoolExp>;

    Name?: Maybe<StringComparisonExp>;

    ProjectId?: Maybe<StringComparisonExp>;

    Schedule?: Maybe<JsonbComparisonExp>;

    _and?: Maybe<(Maybe<LaboScheduleStatesBoolExp>)[]>;

    _not?: Maybe<LaboScheduleStatesBoolExp>;

    _or?: Maybe<(Maybe<LaboScheduleStatesBoolExp>)[]>;
}
/** Boolean expression to filter rows from the table "LaboScheduleStatesPlanning". All fields are combined with a logical 'AND'. */
export interface LaboScheduleStatesPlanningBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    LaboScheduleState?: Maybe<LaboScheduleStatesBoolExp>;

    Planned?: Maybe<TimestampComparisonExp>;

    ProjectId?: Maybe<StringComparisonExp>;

    StateId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<(Maybe<LaboScheduleStatesPlanningBoolExp>)[]>;

    _not?: Maybe<LaboScheduleStatesPlanningBoolExp>;

    _or?: Maybe<(Maybe<LaboScheduleStatesPlanningBoolExp>)[]>;
}
/** ordering options when selecting data from "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningOrderBy {
    Id?: Maybe<OrderBy>;

    LaboScheduleState?: Maybe<LaboScheduleStatesOrderBy>;

    Planned?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    StateId?: Maybe<OrderBy>;
}
/** ordering options when selecting data from "ProjectConfigurations" */
export interface ProjectConfigurationsOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ProjectLogo?: Maybe<OrderBy>;

    ProjectName?: Maybe<OrderBy>;

    ProjectType?: Maybe<OrderBy>;

    ReportsDrive?: Maybe<OrderBy>;

    ReportsItem?: Maybe<OrderBy>;

    ReportsSite?: Maybe<OrderBy>;

    TimeZone?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "ProjectConfigurations". All fields are combined with a logical 'AND'. */
export interface ProjectConfigurationsBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    ProjectLogo?: Maybe<StringComparisonExp>;

    ProjectName?: Maybe<StringComparisonExp>;

    ProjectType?: Maybe<StringComparisonExp>;

    ReportsDrive?: Maybe<StringComparisonExp>;

    ReportsItem?: Maybe<StringComparisonExp>;

    ReportsSite?: Maybe<StringComparisonExp>;

    TimeZone?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<ProjectConfigurationsBoolExp>)[]>;

    _not?: Maybe<ProjectConfigurationsBoolExp>;

    _or?: Maybe<(Maybe<ProjectConfigurationsBoolExp>)[]>;
}
/** ordering options when selecting data from "ReportProjectFiles" */
export interface ReportProjectFilesOrderBy {
    Created?: Maybe<OrderBy>;

    DriveItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Modified?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    Size?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "ReportProjectFiles". All fields are combined with a logical 'AND'. */
export interface ReportProjectFilesBoolExp {
    Created?: Maybe<TimestamptzComparisonExp>;

    DriveItemId?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Modified?: Maybe<TimestamptzComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    Size?: Maybe<BigintComparisonExp>;

    Type?: Maybe<StringComparisonExp>;

    WebUrl?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<ReportProjectFilesBoolExp>)[]>;

    _not?: Maybe<ReportProjectFilesBoolExp>;

    _or?: Maybe<(Maybe<ReportProjectFilesBoolExp>)[]>;
}
/** ordering options when selecting data from "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesOrderBy {
    AllowedPermissions?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "SmartlabFunctionalities". All fields are combined with a logical 'AND'. */
export interface SmartlabFunctionalitiesBoolExp {
    AllowedPermissions?: Maybe<JsonbComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<SmartlabFunctionalitiesBoolExp>)[]>;

    _not?: Maybe<SmartlabFunctionalitiesBoolExp>;

    _or?: Maybe<(Maybe<SmartlabFunctionalitiesBoolExp>)[]>;
}
/** ordering options when selecting data from "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Link?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    PFDTagLink?: Maybe<OrderBy>;

    PFDUnitId?: Maybe<OrderBy>;

    Targets?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "SmartlabPFDUnitLinks". All fields are combined with a logical 'AND'. */
export interface SmartlabPfdUnitLinksBoolExp {
    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Link?: Maybe<StringComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    Order?: Maybe<IntComparisonExp>;

    PFDTagLink?: Maybe<StringComparisonExp>;

    PFDUnitId?: Maybe<StringComparisonExp>;

    Targets?: Maybe<_TextComparisonExp>;

    Type?: Maybe<StringComparisonExp>;

    _and?: Maybe<(Maybe<SmartlabPfdUnitLinksBoolExp>)[]>;

    _not?: Maybe<SmartlabPfdUnitLinksBoolExp>;

    _or?: Maybe<(Maybe<SmartlabPfdUnitLinksBoolExp>)[]>;
}

export interface ConfigurationInput {
    ewon?: Maybe<EwonConfigurationInput>;

    frequencyseconds?: Maybe<number>;

    influx?: Maybe<InfluxConfigurationInput>;
}

export interface EwonConfigurationInput {
    account?: Maybe<string>;

    devid?: Maybe<string>;

    password?: Maybe<string>;

    rowthreshold?: Maybe<string>;

    uri?: Maybe<string>;

    username?: Maybe<string>;
}

export interface InfluxConfigurationInput {
    database?: Maybe<string>;
}

export interface NotificationInput {
    data?: Maybe<string>;

    recipients?: Maybe<(Maybe<string>)[]>;

    sender?: Maybe<string>;

    text?: Maybe<string>;

    title?: Maybe<string>;
}
/** input type for inserting data into table "Branding" */
export interface BrandingInsertInput {
    AlertsEnabled?: Maybe<boolean>;

    AlertsTitle?: Maybe<string>;

    AllowedProjects?: Maybe<Jsonb>;

    CalendarEnabled?: Maybe<boolean>;

    CalendarTitle?: Maybe<string>;

    Domain?: Maybe<string>;

    EnableBigLogo?: Maybe<boolean>;

    FavIcon?: Maybe<string>;

    Id?: Maybe<Uuid>;

    LaboratoryEnabled?: Maybe<boolean>;

    LaboratoryTitle?: Maybe<string>;

    LogbookEnabled?: Maybe<boolean>;

    LogbookTitle?: Maybe<string>;

    Logo?: Maybe<string>;

    MaintenanceEnabled?: Maybe<boolean>;

    MaintenanceTitle?: Maybe<string>;

    MyPlantsTableTitle?: Maybe<string>;

    MyPlantsTitle?: Maybe<string>;

    Name?: Maybe<string>;

    OperatorEnabled?: Maybe<boolean>;

    OperatorTitle?: Maybe<string>;

    PlantEnabled?: Maybe<boolean>;

    PlantOverrideWithPFDUnitId?: Maybe<string>;

    PlantTitle?: Maybe<string>;

    PrimaryColor?: Maybe<string>;

    ReportsEnabled?: Maybe<boolean>;

    ReportsTitle?: Maybe<string>;

    SecondaryColor?: Maybe<string>;

    TechnologiesEnabled?: Maybe<boolean>;

    TechnologiesTitle?: Maybe<string>;

    UtilitiesEnabled?: Maybe<boolean>;

    UtilitiesTitle?: Maybe<string>;
}
/** on conflict condition type for table "Branding" */
export interface BrandingOnConflict {
    constraint: BrandingConstraint;

    update_columns: BrandingUpdateColumn[];

    where?: Maybe<BrandingBoolExp>;
}
/** input type for inserting data into table "CalendarEvents" */
export interface CalendarEventsInsertInput {
    AllDay?: Maybe<boolean>;

    ColorPrimary?: Maybe<string>;

    ColorSecondary?: Maybe<string>;

    Draggable?: Maybe<boolean>;

    End?: Maybe<Timestamptz>;

    Id?: Maybe<Uuid>;

    MetaLocation?: Maybe<string>;

    MetaNotes?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    ResizableAfterEnd?: Maybe<boolean>;

    ResizableBeforeStart?: Maybe<boolean>;

    Start?: Maybe<Timestamptz>;

    Title?: Maybe<string>;

    UserId?: Maybe<string>;
}
/** on conflict condition type for table "CalendarEvents" */
export interface CalendarEventsOnConflict {
    constraint: CalendarEventsConstraint;

    update_columns: CalendarEventsUpdateColumn[];

    where?: Maybe<CalendarEventsBoolExp>;
}
/** input type for inserting data into table "ClientGroups" */
export interface ClientGroupsInsertInput {
    ClientGroupsSmartlabUsers?: Maybe<
        ClientGroupsSmartlabUsersArrRelInsertInput
    >;

    ClientIds?: Maybe<_Text>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** input type for inserting array relation for remote table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersArrRelInsertInput {
    data: ClientGroupsSmartlabUsersInsertInput[];

    on_conflict?: Maybe<ClientGroupsSmartlabUsersOnConflict>;
}
/** input type for inserting data into table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersInsertInput {
    ClientGroup?: Maybe<ClientGroupsObjRelInsertInput>;

    ClientGroupId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    SmartlabUser?: Maybe<SmartlabUsersObjRelInsertInput>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "ClientGroups" */
export interface ClientGroupsObjRelInsertInput {
    data: ClientGroupsInsertInput;

    on_conflict?: Maybe<ClientGroupsOnConflict>;
}
/** on conflict condition type for table "ClientGroups" */
export interface ClientGroupsOnConflict {
    constraint: ClientGroupsConstraint;

    update_columns: ClientGroupsUpdateColumn[];

    where?: Maybe<ClientGroupsBoolExp>;
}
/** input type for inserting object relation for remote table "SmartlabUsers" */
export interface SmartlabUsersObjRelInsertInput {
    data: SmartlabUsersInsertInput;

    on_conflict?: Maybe<SmartlabUsersOnConflict>;
}
/** input type for inserting data into table "SmartlabUsers" */
export interface SmartlabUsersInsertInput {
    ClientGroupsSmartlabUsers?: Maybe<
        ClientGroupsSmartlabUsersArrRelInsertInput
    >;

    Id?: Maybe<Uuid>;

    ProjectGroupsSmartlabUsers?: Maybe<
        ProjectGroupsSmartlabUsersArrRelInsertInput
    >;

    UserId?: Maybe<string>;
}
/** input type for inserting array relation for remote table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersArrRelInsertInput {
    data: ProjectGroupsSmartlabUsersInsertInput[];

    on_conflict?: Maybe<ProjectGroupsSmartlabUsersOnConflict>;
}
/** input type for inserting data into table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersInsertInput {
    Id?: Maybe<Uuid>;

    ProjectGroup?: Maybe<ProjectGroupsObjRelInsertInput>;

    ProjectGroupId?: Maybe<Uuid>;

    SmartlabUser?: Maybe<SmartlabUsersObjRelInsertInput>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "ProjectGroups" */
export interface ProjectGroupsObjRelInsertInput {
    data: ProjectGroupsInsertInput;

    on_conflict?: Maybe<ProjectGroupsOnConflict>;
}
/** input type for inserting data into table "ProjectGroups" */
export interface ProjectGroupsInsertInput {
    ClientGroup?: Maybe<ClientGroupsObjRelInsertInput>;

    ClientGroupId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    ProjectCodes?: Maybe<_Text>;

    ProjectGroupsSmartlabUsers?: Maybe<
        ProjectGroupsSmartlabUsersArrRelInsertInput
    >;
}
/** on conflict condition type for table "ProjectGroups" */
export interface ProjectGroupsOnConflict {
    constraint: ProjectGroupsConstraint;

    update_columns: ProjectGroupsUpdateColumn[];

    where?: Maybe<ProjectGroupsBoolExp>;
}
/** on conflict condition type for table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersOnConflict {
    constraint: ProjectGroupsSmartlabUsersConstraint;

    update_columns: ProjectGroupsSmartlabUsersUpdateColumn[];

    where?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;
}
/** on conflict condition type for table "SmartlabUsers" */
export interface SmartlabUsersOnConflict {
    constraint: SmartlabUsersConstraint;

    update_columns: SmartlabUsersUpdateColumn[];

    where?: Maybe<SmartlabUsersBoolExp>;
}
/** on conflict condition type for table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersOnConflict {
    constraint: ClientGroupsSmartlabUsersConstraint;

    update_columns: ClientGroupsSmartlabUsersUpdateColumn[];

    where?: Maybe<ClientGroupsSmartlabUsersBoolExp>;
}
/** input type for inserting data into table "CumulTokens" */
export interface CumulTokensInsertInput {
    AuthorizationId?: Maybe<string>;

    AuthorizationToken?: Maybe<string>;

    CreatedAt?: Maybe<Timestamp>;

    HashedRequestSignature?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Revoked?: Maybe<boolean>;

    UserId?: Maybe<string>;

    ValidUntil?: Maybe<Timestamp>;
}
/** on conflict condition type for table "CumulTokens" */
export interface CumulTokensOnConflict {
    constraint: CumulTokensConstraint;

    update_columns: CumulTokensUpdateColumn[];

    where?: Maybe<CumulTokensBoolExp>;
}
/** input type for inserting data into table "DataProcConfig" */
export interface DataProcConfigInsertInput {
    DataProcConfigCopyDataForDemos?: Maybe<
        DataProcConfigCopyDataForDemoArrRelInsertInput
    >;

    DataProcConfigCopyInflux?: Maybe<DataProcConfigCopyInfluxArrRelInsertInput>;

    DataProcConfigEwon?: Maybe<DataProcConfig1ArrRelInsertInput>;

    DataProcConfigInflux?: Maybe<DataProcConfig2ArrRelInsertInput>;

    DataProcConfigPostgres?: Maybe<DataProcConfig3ArrRelInsertInput>;

    DataProcStates?: Maybe<DataProcStateArrRelInsertInput>;

    DataProcTagStates?: Maybe<DataProcTagStateArrRelInsertInput>;

    FrequencyMinutes?: Maybe<Bigint>;

    Id?: Maybe<Uuid>;

    IsStateEnabled?: Maybe<boolean>;

    ProjectCode?: Maybe<string>;
}
/** input type for inserting array relation for remote table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoArrRelInsertInput {
    data: DataProcConfigCopyDataForDemoInsertInput[];

    on_conflict?: Maybe<DataProcConfigCopyDataForDemoOnConflict>;
}
/** input type for inserting data into table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoInsertInput {
    ConfigurationModelId?: Maybe<Uuid>;

    CopyFromDate?: Maybe<Timestamp>;

    CopyToDate?: Maybe<Timestamp>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "DataProcConfig" */
export interface DataProcConfigObjRelInsertInput {
    data: DataProcConfigInsertInput;

    on_conflict?: Maybe<DataProcConfigOnConflict>;
}
/** on conflict condition type for table "DataProcConfig" */
export interface DataProcConfigOnConflict {
    constraint: DataProcConfigConstraint;

    update_columns: DataProcConfigUpdateColumn[];

    where?: Maybe<DataProcConfigBoolExp>;
}
/** on conflict condition type for table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoOnConflict {
    constraint: DataProcConfigCopyDataForDemoConstraint;

    update_columns: DataProcConfigCopyDataForDemoUpdateColumn[];

    where?: Maybe<DataProcConfigCopyDataForDemoBoolExp>;
}
/** input type for inserting array relation for remote table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxArrRelInsertInput {
    data: DataProcConfigCopyInfluxInsertInput[];

    on_conflict?: Maybe<DataProcConfigCopyInfluxOnConflict>;
}
/** input type for inserting data into table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxInsertInput {
    ConfigurationModelId?: Maybe<Uuid>;

    CopyRangeInSecondsPerRun?: Maybe<number>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    Database?: Maybe<string>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;
}
/** on conflict condition type for table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxOnConflict {
    constraint: DataProcConfigCopyInfluxConstraint;

    update_columns: DataProcConfigCopyInfluxUpdateColumn[];

    where?: Maybe<DataProcConfigCopyInfluxBoolExp>;
}
/** input type for inserting array relation for remote table "DataProcConfig1" */
export interface DataProcConfig1ArrRelInsertInput {
    data: DataProcConfig1InsertInput[];

    on_conflict?: Maybe<DataProcConfig1OnConflict>;
}
/** input type for inserting data into table "DataProcConfig1" */
export interface DataProcConfig1InsertInput {
    Account?: Maybe<string>;

    ConfigurationModelId?: Maybe<Uuid>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    DevId?: Maybe<string>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    Password?: Maybe<string>;

    RowThreshold?: Maybe<number>;

    Uri?: Maybe<string>;

    Username?: Maybe<string>;
}
/** on conflict condition type for table "DataProcConfig1" */
export interface DataProcConfig1OnConflict {
    constraint: DataProcConfig1Constraint;

    update_columns: DataProcConfig1UpdateColumn[];

    where?: Maybe<DataProcConfig1BoolExp>;
}
/** input type for inserting array relation for remote table "DataProcConfig2" */
export interface DataProcConfig2ArrRelInsertInput {
    data: DataProcConfig2InsertInput[];

    on_conflict?: Maybe<DataProcConfig2OnConflict>;
}
/** input type for inserting data into table "DataProcConfig2" */
export interface DataProcConfig2InsertInput {
    ConfigurationModelId?: Maybe<Uuid>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    Database?: Maybe<string>;

    Id?: Maybe<Uuid>;
}
/** on conflict condition type for table "DataProcConfig2" */
export interface DataProcConfig2OnConflict {
    constraint: DataProcConfig2Constraint;

    update_columns: DataProcConfig2UpdateColumn[];

    where?: Maybe<DataProcConfig2BoolExp>;
}
/** input type for inserting array relation for remote table "DataProcConfig3" */
export interface DataProcConfig3ArrRelInsertInput {
    data: DataProcConfig3InsertInput[];

    on_conflict?: Maybe<DataProcConfig3OnConflict>;
}
/** input type for inserting data into table "DataProcConfig3" */
export interface DataProcConfig3InsertInput {
    ConfigurationModelId?: Maybe<Uuid>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    Enabled?: Maybe<boolean>;

    HourOffset?: Maybe<number>;

    Id?: Maybe<Uuid>;
}
/** on conflict condition type for table "DataProcConfig3" */
export interface DataProcConfig3OnConflict {
    constraint: DataProcConfig3Constraint;

    update_columns: DataProcConfig3UpdateColumn[];

    where?: Maybe<DataProcConfig3BoolExp>;
}
/** input type for inserting array relation for remote table "DataProcState" */
export interface DataProcStateArrRelInsertInput {
    data: DataProcStateInsertInput[];

    on_conflict?: Maybe<DataProcStateOnConflict>;
}
/** input type for inserting data into table "DataProcState" */
export interface DataProcStateInsertInput {
    ConfigurationId?: Maybe<Uuid>;

    CopyDataForDemoEndDate?: Maybe<Timestamp>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    EndedDate?: Maybe<Timestamp>;

    EwonErrorMessage?: Maybe<string>;

    EwonLoops?: Maybe<number>;

    EwonPointsReceived?: Maybe<number>;

    EwonProcessingEndedDate?: Maybe<Timestamp>;

    EwonProcessingStartedDate?: Maybe<Timestamp>;

    EwonResult?: Maybe<number>;

    EwonSuccess?: Maybe<boolean>;

    EwonTotalPointsReceived?: Maybe<Numeric>;

    EwonTransactionId?: Maybe<number>;

    Id?: Maybe<Uuid>;

    JobNr?: Maybe<Numeric>;

    KpiDate?: Maybe<Timestamp>;

    KpiValue?: Maybe<Float8>;

    StartedDate?: Maybe<Timestamp>;

    TagProcessingEndedDate?: Maybe<Timestamp>;

    TagProcessingErrorMessage?: Maybe<string>;

    TagProcessingPointsProcessed?: Maybe<Bigint>;

    TagProcessingStartedDate?: Maybe<Timestamp>;

    TagProcessingTagsDoneProcessing?: Maybe<Bigint>;

    TagProcessingTagsProcessed?: Maybe<Bigint>;

    TagProcessingTotalPointsProcessed?: Maybe<Numeric>;

    Updated?: Maybe<Timestamp>;
}
/** on conflict condition type for table "DataProcState" */
export interface DataProcStateOnConflict {
    constraint: DataProcStateConstraint;

    update_columns: DataProcStateUpdateColumn[];

    where?: Maybe<DataProcStateBoolExp>;
}
/** input type for inserting array relation for remote table "DataProcTagState" */
export interface DataProcTagStateArrRelInsertInput {
    data: DataProcTagStateInsertInput[];

    on_conflict?: Maybe<DataProcTagStateOnConflict>;
}
/** input type for inserting data into table "DataProcTagState" */
export interface DataProcTagStateInsertInput {
    AggregationTime?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<number>;

    BlockName?: Maybe<string>;

    Children?: Maybe<DataProcTagStateDepsArrRelInsertInput>;

    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    ErrorString?: Maybe<string>;

    FirstPendingDate?: Maybe<Timestamp>;

    Formula?: Maybe<string>;

    InfluxTable?: Maybe<string>;

    IsProcessing?: Maybe<boolean>;

    JobNr?: Maybe<Numeric>;

    LastDependencyDate?: Maybe<Timestamp>;

    LastPendingDate?: Maybe<Timestamp>;

    LastProcessedDate?: Maybe<Timestamp>;

    OutputSignal?: Maybe<DataProcSignalObjRelInsertInput>;

    OutputSignalId?: Maybe<Uuid>;

    Parents?: Maybe<DataProcTagStateDepsArrRelInsertInput>;

    PointsProcessed?: Maybe<Bigint>;

    ProcessedRangeDone?: Maybe<Timestamp>;

    ProcessedRangeFrom?: Maybe<Timestamp>;

    ProcessedRangeTo?: Maybe<Timestamp>;

    ProcessingEndedDate?: Maybe<Timestamp>;

    ProcessingStartedDate?: Maybe<Timestamp>;

    Result?: Maybe<number>;

    TagId?: Maybe<number>;

    TagName?: Maybe<string>;

    Updated?: Maybe<Timestamp>;
}
/** input type for inserting array relation for remote table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsArrRelInsertInput {
    data: DataProcTagStateDepsInsertInput[];

    on_conflict?: Maybe<DataProcTagStateDepsOnConflict>;
}
/** input type for inserting data into table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsInsertInput {
    Child?: Maybe<DataProcTagStateObjRelInsertInput>;

    ChildAggregationTime?: Maybe<number>;

    ChildAggregationType?: Maybe<number>;

    ChildTagId?: Maybe<number>;

    Parent?: Maybe<DataProcTagStateObjRelInsertInput>;

    ParentAggregationTime?: Maybe<number>;

    ParentAggregationType?: Maybe<number>;

    ParentTagId?: Maybe<number>;
}
/** input type for inserting object relation for remote table "DataProcTagState" */
export interface DataProcTagStateObjRelInsertInput {
    data: DataProcTagStateInsertInput;

    on_conflict?: Maybe<DataProcTagStateOnConflict>;
}
/** on conflict condition type for table "DataProcTagState" */
export interface DataProcTagStateOnConflict {
    constraint: DataProcTagStateConstraint;

    update_columns: DataProcTagStateUpdateColumn[];

    where?: Maybe<DataProcTagStateBoolExp>;
}
/** on conflict condition type for table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsOnConflict {
    constraint: DataProcTagStateDepsConstraint;

    update_columns: DataProcTagStateDepsUpdateColumn[];

    where?: Maybe<DataProcTagStateDepsBoolExp>;
}
/** input type for inserting object relation for remote table "DataProcSignal" */
export interface DataProcSignalObjRelInsertInput {
    data: DataProcSignalInsertInput;

    on_conflict?: Maybe<DataProcSignalOnConflict>;
}
/** input type for inserting data into table "DataProcSignal" */
export interface DataProcSignalInsertInput {
    AggregationTime?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<number>;

    DataProcTagStates?: Maybe<DataProcTagStateArrRelInsertInput>;

    DataType?: Maybe<number>;

    Id?: Maybe<Uuid>;

    TableName?: Maybe<string>;

    TagId?: Maybe<number>;
}
/** on conflict condition type for table "DataProcSignal" */
export interface DataProcSignalOnConflict {
    constraint: DataProcSignalConstraint;

    update_columns: DataProcSignalUpdateColumn[];

    where?: Maybe<DataProcSignalBoolExp>;
}
/** input type for inserting data into table "GeneratedReports" */
export interface GeneratedReportsInsertInput {
    Created?: Maybe<Timestamp>;

    DocUrl?: Maybe<string>;

    DynamicDataSnapshot?: Maybe<Jsonb>;

    Errors?: Maybe<_Text>;

    Finished?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateId?: Maybe<Uuid>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;

    ReportDateTime?: Maybe<Timestamp>;

    ReportTemplate?: Maybe<ReportTemplatesObjRelInsertInput>;

    ReportTemplateId?: Maybe<Uuid>;

    State?: Maybe<string>;

    Version?: Maybe<string>;

    Warnings?: Maybe<_Text>;

    WebUrl?: Maybe<string>;

    projectCode?: Maybe<string>;
}
/** input type for inserting object relation for remote table "ReportTemplates" */
export interface ReportTemplatesObjRelInsertInput {
    data: ReportTemplatesInsertInput;

    on_conflict?: Maybe<ReportTemplatesOnConflict>;
}
/** input type for inserting data into table "ReportTemplates" */
export interface ReportTemplatesInsertInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    GeneratedReports?: Maybe<GeneratedReportsArrRelInsertInput>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    Name?: Maybe<string>;

    ProjectReportTemplates?: Maybe<ProjectReportTemplatesArrRelInsertInput>;

    ReportTemplateBlocks?: Maybe<Jsonb>;

    Version?: Maybe<string>;
}
/** input type for inserting array relation for remote table "GeneratedReports" */
export interface GeneratedReportsArrRelInsertInput {
    data: GeneratedReportsInsertInput[];

    on_conflict?: Maybe<GeneratedReportsOnConflict>;
}
/** on conflict condition type for table "GeneratedReports" */
export interface GeneratedReportsOnConflict {
    constraint: GeneratedReportsConstraint;

    update_columns: GeneratedReportsUpdateColumn[];

    where?: Maybe<GeneratedReportsBoolExp>;
}
/** input type for inserting array relation for remote table "ProjectReportTemplates" */
export interface ProjectReportTemplatesArrRelInsertInput {
    data: ProjectReportTemplatesInsertInput[];

    on_conflict?: Maybe<ProjectReportTemplatesOnConflict>;
}
/** input type for inserting data into table "ProjectReportTemplates" */
export interface ProjectReportTemplatesInsertInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    Frequency?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    Parameters?: Maybe<Jsonb>;

    ReportTemplate?: Maybe<ReportTemplatesObjRelInsertInput>;

    ReportTemplateId?: Maybe<Uuid>;

    projectCode?: Maybe<string>;
}
/** on conflict condition type for table "ProjectReportTemplates" */
export interface ProjectReportTemplatesOnConflict {
    constraint: ProjectReportTemplatesConstraint;

    update_columns: ProjectReportTemplatesUpdateColumn[];

    where?: Maybe<ProjectReportTemplatesBoolExp>;
}
/** on conflict condition type for table "ReportTemplates" */
export interface ReportTemplatesOnConflict {
    constraint: ReportTemplatesConstraint;

    update_columns: ReportTemplatesUpdateColumn[];

    where?: Maybe<ReportTemplatesBoolExp>;
}
/** input type for inserting data into table "LaboScheduleStates" */
export interface LaboScheduleStatesInsertInput {
    CreatedAt?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedAt?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    LaboScheduleStatesPlannings?: Maybe<
        LaboScheduleStatesPlanningArrRelInsertInput
    >;

    Name?: Maybe<string>;

    ProjectId?: Maybe<string>;

    Schedule?: Maybe<Jsonb>;
}
/** input type for inserting array relation for remote table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningArrRelInsertInput {
    data: LaboScheduleStatesPlanningInsertInput[];

    on_conflict?: Maybe<LaboScheduleStatesPlanningOnConflict>;
}
/** input type for inserting data into table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningInsertInput {
    Id?: Maybe<Uuid>;

    LaboScheduleState?: Maybe<LaboScheduleStatesObjRelInsertInput>;

    Planned?: Maybe<Timestamp>;

    ProjectId?: Maybe<string>;

    StateId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "LaboScheduleStates" */
export interface LaboScheduleStatesObjRelInsertInput {
    data: LaboScheduleStatesInsertInput;

    on_conflict?: Maybe<LaboScheduleStatesOnConflict>;
}
/** on conflict condition type for table "LaboScheduleStates" */
export interface LaboScheduleStatesOnConflict {
    constraint: LaboScheduleStatesConstraint;

    update_columns: LaboScheduleStatesUpdateColumn[];

    where?: Maybe<LaboScheduleStatesBoolExp>;
}
/** on conflict condition type for table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningOnConflict {
    constraint: LaboScheduleStatesPlanningConstraint;

    update_columns: LaboScheduleStatesPlanningUpdateColumn[];

    where?: Maybe<LaboScheduleStatesPlanningBoolExp>;
}
/** input type for inserting data into table "ProjectConfigurations" */
export interface ProjectConfigurationsInsertInput {
    Id?: Maybe<Uuid>;

    ProjectCode?: Maybe<string>;

    ProjectLogo?: Maybe<string>;

    ProjectName?: Maybe<string>;

    ProjectType?: Maybe<string>;

    ReportsDrive?: Maybe<string>;

    ReportsItem?: Maybe<string>;

    ReportsSite?: Maybe<string>;

    TimeZone?: Maybe<string>;
}
/** on conflict condition type for table "ProjectConfigurations" */
export interface ProjectConfigurationsOnConflict {
    constraint: ProjectConfigurationsConstraint;

    update_columns: ProjectConfigurationsUpdateColumn[];

    where?: Maybe<ProjectConfigurationsBoolExp>;
}
/** input type for inserting data into table "ReportProjectFiles" */
export interface ReportProjectFilesInsertInput {
    Created?: Maybe<Timestamptz>;

    DriveItemId?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Modified?: Maybe<Timestamptz>;

    Name?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    Size?: Maybe<Bigint>;

    Type?: Maybe<string>;

    WebUrl?: Maybe<string>;
}
/** on conflict condition type for table "ReportProjectFiles" */
export interface ReportProjectFilesOnConflict {
    constraint: ReportProjectFilesConstraint;

    update_columns: ReportProjectFilesUpdateColumn[];

    where?: Maybe<ReportProjectFilesBoolExp>;
}
/** input type for inserting data into table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesInsertInput {
    AllowedPermissions?: Maybe<Jsonb>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** on conflict condition type for table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesOnConflict {
    constraint: SmartlabFunctionalitiesConstraint;

    update_columns: SmartlabFunctionalitiesUpdateColumn[];

    where?: Maybe<SmartlabFunctionalitiesBoolExp>;
}
/** input type for inserting data into table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksInsertInput {
    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Link?: Maybe<string>;

    Name?: Maybe<string>;

    Order?: Maybe<number>;

    PFDTagLink?: Maybe<string>;

    PFDUnitId?: Maybe<string>;

    Targets?: Maybe<_Text>;

    Type?: Maybe<string>;
}
/** on conflict condition type for table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksOnConflict {
    constraint: SmartlabPfdUnitLinksConstraint;

    update_columns: SmartlabPfdUnitLinksUpdateColumn[];

    where?: Maybe<SmartlabPfdUnitLinksBoolExp>;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface BrandingAppendInput {
    AllowedProjects?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface BrandingDeleteAtPathInput {
    AllowedProjects?: Maybe<(Maybe<string>)[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface BrandingDeleteElemInput {
    AllowedProjects?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface BrandingDeleteKeyInput {
    AllowedProjects?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface BrandingPrependInput {
    AllowedProjects?: Maybe<Jsonb>;
}
/** input type for updating data in table "Branding" */
export interface BrandingSetInput {
    AlertsEnabled?: Maybe<boolean>;

    AlertsTitle?: Maybe<string>;

    AllowedProjects?: Maybe<Jsonb>;

    CalendarEnabled?: Maybe<boolean>;

    CalendarTitle?: Maybe<string>;

    Domain?: Maybe<string>;

    EnableBigLogo?: Maybe<boolean>;

    FavIcon?: Maybe<string>;

    Id?: Maybe<Uuid>;

    LaboratoryEnabled?: Maybe<boolean>;

    LaboratoryTitle?: Maybe<string>;

    LogbookEnabled?: Maybe<boolean>;

    LogbookTitle?: Maybe<string>;

    Logo?: Maybe<string>;

    MaintenanceEnabled?: Maybe<boolean>;

    MaintenanceTitle?: Maybe<string>;

    MyPlantsTableTitle?: Maybe<string>;

    MyPlantsTitle?: Maybe<string>;

    Name?: Maybe<string>;

    OperatorEnabled?: Maybe<boolean>;

    OperatorTitle?: Maybe<string>;

    PlantEnabled?: Maybe<boolean>;

    PlantOverrideWithPFDUnitId?: Maybe<string>;

    PlantTitle?: Maybe<string>;

    PrimaryColor?: Maybe<string>;

    ReportsEnabled?: Maybe<boolean>;

    ReportsTitle?: Maybe<string>;

    SecondaryColor?: Maybe<string>;

    TechnologiesEnabled?: Maybe<boolean>;

    TechnologiesTitle?: Maybe<string>;

    UtilitiesEnabled?: Maybe<boolean>;

    UtilitiesTitle?: Maybe<string>;
}
/** primary key columns input for table: "Branding" */
export interface BrandingPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "CalendarEvents" */
export interface CalendarEventsSetInput {
    AllDay?: Maybe<boolean>;

    ColorPrimary?: Maybe<string>;

    ColorSecondary?: Maybe<string>;

    Draggable?: Maybe<boolean>;

    End?: Maybe<Timestamptz>;

    Id?: Maybe<Uuid>;

    MetaLocation?: Maybe<string>;

    MetaNotes?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    ResizableAfterEnd?: Maybe<boolean>;

    ResizableBeforeStart?: Maybe<boolean>;

    Start?: Maybe<Timestamptz>;

    Title?: Maybe<string>;

    UserId?: Maybe<string>;
}
/** primary key columns input for table: "CalendarEvents" */
export interface CalendarEventsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "ClientGroups" */
export interface ClientGroupsSetInput {
    ClientIds?: Maybe<_Text>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** input type for updating data in table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersSetInput {
    ClientGroupId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** primary key columns input for table: "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: "ClientGroups" */
export interface ClientGroupsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "CumulTokens" */
export interface CumulTokensSetInput {
    AuthorizationId?: Maybe<string>;

    AuthorizationToken?: Maybe<string>;

    CreatedAt?: Maybe<Timestamp>;

    HashedRequestSignature?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Revoked?: Maybe<boolean>;

    UserId?: Maybe<string>;

    ValidUntil?: Maybe<Timestamp>;
}
/** primary key columns input for table: "CumulTokens" */
export interface CumulTokensPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "DataProcConfig" */
export interface DataProcConfigIncInput {
    FrequencyMinutes?: Maybe<Bigint>;
}
/** input type for updating data in table "DataProcConfig" */
export interface DataProcConfigSetInput {
    FrequencyMinutes?: Maybe<Bigint>;

    Id?: Maybe<Uuid>;

    IsStateEnabled?: Maybe<boolean>;

    ProjectCode?: Maybe<string>;
}
/** input type for incrementing integer column in table "DataProcConfig1" */
export interface DataProcConfig1IncInput {
    RowThreshold?: Maybe<number>;
}
/** input type for updating data in table "DataProcConfig1" */
export interface DataProcConfig1SetInput {
    Account?: Maybe<string>;

    ConfigurationModelId?: Maybe<Uuid>;

    DevId?: Maybe<string>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    Password?: Maybe<string>;

    RowThreshold?: Maybe<number>;

    Uri?: Maybe<string>;

    Username?: Maybe<string>;
}
/** primary key columns input for table: "DataProcConfig1" */
export interface DataProcConfig1PkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "DataProcConfig2" */
export interface DataProcConfig2SetInput {
    ConfigurationModelId?: Maybe<Uuid>;

    Database?: Maybe<string>;

    Id?: Maybe<Uuid>;
}
/** primary key columns input for table: "DataProcConfig2" */
export interface DataProcConfig2PkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "DataProcConfig3" */
export interface DataProcConfig3IncInput {
    HourOffset?: Maybe<number>;
}
/** input type for updating data in table "DataProcConfig3" */
export interface DataProcConfig3SetInput {
    ConfigurationModelId?: Maybe<Uuid>;

    Enabled?: Maybe<boolean>;

    HourOffset?: Maybe<number>;

    Id?: Maybe<Uuid>;
}
/** primary key columns input for table: "DataProcConfig3" */
export interface DataProcConfig3PkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoSetInput {
    ConfigurationModelId?: Maybe<Uuid>;

    CopyFromDate?: Maybe<Timestamp>;

    CopyToDate?: Maybe<Timestamp>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;
}
/** primary key columns input for table: "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxIncInput {
    CopyRangeInSecondsPerRun?: Maybe<number>;
}
/** input type for updating data in table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxSetInput {
    ConfigurationModelId?: Maybe<Uuid>;

    CopyRangeInSecondsPerRun?: Maybe<number>;

    Database?: Maybe<string>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;
}
/** primary key columns input for table: "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: "DataProcConfig" */
export interface DataProcConfigPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "DataProcSignal" */
export interface DataProcSignalIncInput {
    AggregationTime?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<number>;

    DataType?: Maybe<number>;

    TagId?: Maybe<number>;
}
/** input type for updating data in table "DataProcSignal" */
export interface DataProcSignalSetInput {
    AggregationTime?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<number>;

    DataType?: Maybe<number>;

    Id?: Maybe<Uuid>;

    TableName?: Maybe<string>;

    TagId?: Maybe<number>;
}
/** primary key columns input for table: "DataProcSignal" */
export interface DataProcSignalPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "DataProcState" */
export interface DataProcStateIncInput {
    EwonLoops?: Maybe<number>;

    EwonPointsReceived?: Maybe<number>;

    EwonResult?: Maybe<number>;

    EwonTotalPointsReceived?: Maybe<Numeric>;

    EwonTransactionId?: Maybe<number>;

    JobNr?: Maybe<Numeric>;

    KpiValue?: Maybe<Float8>;

    TagProcessingPointsProcessed?: Maybe<Bigint>;

    TagProcessingTagsDoneProcessing?: Maybe<Bigint>;

    TagProcessingTagsProcessed?: Maybe<Bigint>;

    TagProcessingTotalPointsProcessed?: Maybe<Numeric>;
}
/** input type for updating data in table "DataProcState" */
export interface DataProcStateSetInput {
    ConfigurationId?: Maybe<Uuid>;

    CopyDataForDemoEndDate?: Maybe<Timestamp>;

    EndedDate?: Maybe<Timestamp>;

    EwonErrorMessage?: Maybe<string>;

    EwonLoops?: Maybe<number>;

    EwonPointsReceived?: Maybe<number>;

    EwonProcessingEndedDate?: Maybe<Timestamp>;

    EwonProcessingStartedDate?: Maybe<Timestamp>;

    EwonResult?: Maybe<number>;

    EwonSuccess?: Maybe<boolean>;

    EwonTotalPointsReceived?: Maybe<Numeric>;

    EwonTransactionId?: Maybe<number>;

    Id?: Maybe<Uuid>;

    JobNr?: Maybe<Numeric>;

    KpiDate?: Maybe<Timestamp>;

    KpiValue?: Maybe<Float8>;

    StartedDate?: Maybe<Timestamp>;

    TagProcessingEndedDate?: Maybe<Timestamp>;

    TagProcessingErrorMessage?: Maybe<string>;

    TagProcessingPointsProcessed?: Maybe<Bigint>;

    TagProcessingStartedDate?: Maybe<Timestamp>;

    TagProcessingTagsDoneProcessing?: Maybe<Bigint>;

    TagProcessingTagsProcessed?: Maybe<Bigint>;

    TagProcessingTotalPointsProcessed?: Maybe<Numeric>;

    Updated?: Maybe<Timestamp>;
}
/** primary key columns input for table: "DataProcState" */
export interface DataProcStatePkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "DataProcTagState" */
export interface DataProcTagStateIncInput {
    AggregationTime?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<number>;

    JobNr?: Maybe<Numeric>;

    PointsProcessed?: Maybe<Bigint>;

    Result?: Maybe<number>;

    TagId?: Maybe<number>;
}
/** input type for updating data in table "DataProcTagState" */
export interface DataProcTagStateSetInput {
    AggregationTime?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<number>;

    BlockName?: Maybe<string>;

    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    ErrorString?: Maybe<string>;

    FirstPendingDate?: Maybe<Timestamp>;

    Formula?: Maybe<string>;

    InfluxTable?: Maybe<string>;

    IsProcessing?: Maybe<boolean>;

    JobNr?: Maybe<Numeric>;

    LastDependencyDate?: Maybe<Timestamp>;

    LastPendingDate?: Maybe<Timestamp>;

    LastProcessedDate?: Maybe<Timestamp>;

    OutputSignalId?: Maybe<Uuid>;

    PointsProcessed?: Maybe<Bigint>;

    ProcessedRangeDone?: Maybe<Timestamp>;

    ProcessedRangeFrom?: Maybe<Timestamp>;

    ProcessedRangeTo?: Maybe<Timestamp>;

    ProcessingEndedDate?: Maybe<Timestamp>;

    ProcessingStartedDate?: Maybe<Timestamp>;

    Result?: Maybe<number>;

    TagId?: Maybe<number>;

    TagName?: Maybe<string>;

    Updated?: Maybe<Timestamp>;
}
/** input type for incrementing integer column in table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsIncInput {
    ChildAggregationTime?: Maybe<number>;

    ChildAggregationType?: Maybe<number>;

    ChildTagId?: Maybe<number>;

    ParentAggregationTime?: Maybe<number>;

    ParentAggregationType?: Maybe<number>;

    ParentTagId?: Maybe<number>;
}
/** input type for updating data in table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsSetInput {
    ChildAggregationTime?: Maybe<number>;

    ChildAggregationType?: Maybe<number>;

    ChildTagId?: Maybe<number>;

    ParentAggregationTime?: Maybe<number>;

    ParentAggregationType?: Maybe<number>;

    ParentTagId?: Maybe<number>;
}
/** primary key columns input for table: "DataProcTagStateDeps" */
export interface DataProcTagStateDepsPkColumnsInput {
    ChildAggregationTime: number;

    ChildAggregationType: number;

    ChildTagId: number;

    ParentAggregationTime: number;

    ParentAggregationType: number;

    ParentTagId: number;
}
/** primary key columns input for table: "DataProcTagState" */
export interface DataProcTagStatePkColumnsInput {
    AggregationTime: number;

    AggregationType: number;

    TagId: number;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface GeneratedReportsAppendInput {
    DynamicDataSnapshot?: Maybe<Jsonb>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface GeneratedReportsDeleteAtPathInput {
    DynamicDataSnapshot?: Maybe<(Maybe<string>)[]>;

    ProjectConfigurationSnapshot?: Maybe<(Maybe<string>)[]>;

    ProjectReportTemplateSnapshot?: Maybe<(Maybe<string>)[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface GeneratedReportsDeleteElemInput {
    DynamicDataSnapshot?: Maybe<number>;

    ProjectConfigurationSnapshot?: Maybe<number>;

    ProjectReportTemplateSnapshot?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface GeneratedReportsDeleteKeyInput {
    DynamicDataSnapshot?: Maybe<string>;

    ProjectConfigurationSnapshot?: Maybe<string>;

    ProjectReportTemplateSnapshot?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface GeneratedReportsPrependInput {
    DynamicDataSnapshot?: Maybe<Jsonb>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;
}
/** input type for updating data in table "GeneratedReports" */
export interface GeneratedReportsSetInput {
    Created?: Maybe<Timestamp>;

    DocUrl?: Maybe<string>;

    DynamicDataSnapshot?: Maybe<Jsonb>;

    Errors?: Maybe<_Text>;

    Finished?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateId?: Maybe<Uuid>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;

    ReportDateTime?: Maybe<Timestamp>;

    ReportTemplateId?: Maybe<Uuid>;

    State?: Maybe<string>;

    Version?: Maybe<string>;

    Warnings?: Maybe<_Text>;

    WebUrl?: Maybe<string>;

    projectCode?: Maybe<string>;
}
/** primary key columns input for table: "GeneratedReports" */
export interface GeneratedReportsPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface LaboScheduleStatesAppendInput {
    Schedule?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface LaboScheduleStatesDeleteAtPathInput {
    Schedule?: Maybe<(Maybe<string>)[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface LaboScheduleStatesDeleteElemInput {
    Schedule?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface LaboScheduleStatesDeleteKeyInput {
    Schedule?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface LaboScheduleStatesPrependInput {
    Schedule?: Maybe<Jsonb>;
}
/** input type for updating data in table "LaboScheduleStates" */
export interface LaboScheduleStatesSetInput {
    CreatedAt?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedAt?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    ProjectId?: Maybe<string>;

    Schedule?: Maybe<Jsonb>;
}
/** input type for updating data in table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningSetInput {
    Id?: Maybe<Uuid>;

    Planned?: Maybe<Timestamp>;

    ProjectId?: Maybe<string>;

    StateId?: Maybe<Uuid>;
}
/** primary key columns input for table: "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: "LaboScheduleStates" */
export interface LaboScheduleStatesPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "ProjectConfigurations" */
export interface ProjectConfigurationsSetInput {
    Id?: Maybe<Uuid>;

    ProjectCode?: Maybe<string>;

    ProjectLogo?: Maybe<string>;

    ProjectName?: Maybe<string>;

    ProjectType?: Maybe<string>;

    ReportsDrive?: Maybe<string>;

    ReportsItem?: Maybe<string>;

    ReportsSite?: Maybe<string>;

    TimeZone?: Maybe<string>;
}
/** primary key columns input for table: "ProjectConfigurations" */
export interface ProjectConfigurationsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "ProjectGroups" */
export interface ProjectGroupsSetInput {
    ClientGroupId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    ProjectCodes?: Maybe<_Text>;
}
/** input type for updating data in table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersSetInput {
    Id?: Maybe<Uuid>;

    ProjectGroupId?: Maybe<Uuid>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** primary key columns input for table: "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: "ProjectGroups" */
export interface ProjectGroupsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "ReportProjectFiles" */
export interface ReportProjectFilesIncInput {
    Size?: Maybe<Bigint>;
}
/** input type for updating data in table "ReportProjectFiles" */
export interface ReportProjectFilesSetInput {
    Created?: Maybe<Timestamptz>;

    DriveItemId?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Modified?: Maybe<Timestamptz>;

    Name?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    Size?: Maybe<Bigint>;

    Type?: Maybe<string>;

    WebUrl?: Maybe<string>;
}
/** primary key columns input for table: "ReportProjectFiles" */
export interface ReportProjectFilesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface ProjectReportTemplatesAppendInput {
    Parameters?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ProjectReportTemplatesDeleteAtPathInput {
    Parameters?: Maybe<(Maybe<string>)[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ProjectReportTemplatesDeleteElemInput {
    Parameters?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ProjectReportTemplatesDeleteKeyInput {
    Parameters?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ProjectReportTemplatesPrependInput {
    Parameters?: Maybe<Jsonb>;
}
/** input type for updating data in table "ProjectReportTemplates" */
export interface ProjectReportTemplatesSetInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    Frequency?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    Parameters?: Maybe<Jsonb>;

    ReportTemplateId?: Maybe<Uuid>;

    projectCode?: Maybe<string>;
}
/** primary key columns input for table: "ProjectReportTemplates" */
export interface ProjectReportTemplatesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface ReportTemplatesAppendInput {
    ReportTemplateBlocks?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ReportTemplatesDeleteAtPathInput {
    ReportTemplateBlocks?: Maybe<(Maybe<string>)[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ReportTemplatesDeleteElemInput {
    ReportTemplateBlocks?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ReportTemplatesDeleteKeyInput {
    ReportTemplateBlocks?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ReportTemplatesPrependInput {
    ReportTemplateBlocks?: Maybe<Jsonb>;
}
/** input type for updating data in table "ReportTemplates" */
export interface ReportTemplatesSetInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    Name?: Maybe<string>;

    ReportTemplateBlocks?: Maybe<Jsonb>;

    Version?: Maybe<string>;
}
/** primary key columns input for table: "ReportTemplates" */
export interface ReportTemplatesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface SmartlabFunctionalitiesAppendInput {
    AllowedPermissions?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface SmartlabFunctionalitiesDeleteAtPathInput {
    AllowedPermissions?: Maybe<(Maybe<string>)[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface SmartlabFunctionalitiesDeleteElemInput {
    AllowedPermissions?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface SmartlabFunctionalitiesDeleteKeyInput {
    AllowedPermissions?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface SmartlabFunctionalitiesPrependInput {
    AllowedPermissions?: Maybe<Jsonb>;
}
/** input type for updating data in table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesSetInput {
    AllowedPermissions?: Maybe<Jsonb>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** primary key columns input for table: "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing integer column in table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksIncInput {
    Order?: Maybe<number>;
}
/** input type for updating data in table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksSetInput {
    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Link?: Maybe<string>;

    Name?: Maybe<string>;

    Order?: Maybe<number>;

    PFDTagLink?: Maybe<string>;

    PFDUnitId?: Maybe<string>;

    Targets?: Maybe<_Text>;

    Type?: Maybe<string>;
}
/** primary key columns input for table: "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "SmartlabUsers" */
export interface SmartlabUsersSetInput {
    Id?: Maybe<Uuid>;

    UserId?: Maybe<string>;
}
/** primary key columns input for table: "SmartlabUsers" */
export interface SmartlabUsersPkColumnsInput {
    Id: Uuid;
}
/** order by aggregate values of table "Branding" */
export interface BrandingAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<BrandingMaxOrderBy>;

    min?: Maybe<BrandingMinOrderBy>;
}
/** order by max() on columns of table "Branding" */
export interface BrandingMaxOrderBy {
    AlertsTitle?: Maybe<OrderBy>;

    CalendarTitle?: Maybe<OrderBy>;

    Domain?: Maybe<OrderBy>;

    FavIcon?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    LaboratoryTitle?: Maybe<OrderBy>;

    LogbookTitle?: Maybe<OrderBy>;

    Logo?: Maybe<OrderBy>;

    MaintenanceTitle?: Maybe<OrderBy>;

    MyPlantsTableTitle?: Maybe<OrderBy>;

    MyPlantsTitle?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    OperatorTitle?: Maybe<OrderBy>;

    PlantOverrideWithPFDUnitId?: Maybe<OrderBy>;

    PlantTitle?: Maybe<OrderBy>;

    PrimaryColor?: Maybe<OrderBy>;

    ReportsTitle?: Maybe<OrderBy>;

    SecondaryColor?: Maybe<OrderBy>;

    TechnologiesTitle?: Maybe<OrderBy>;

    UtilitiesTitle?: Maybe<OrderBy>;
}
/** order by min() on columns of table "Branding" */
export interface BrandingMinOrderBy {
    AlertsTitle?: Maybe<OrderBy>;

    CalendarTitle?: Maybe<OrderBy>;

    Domain?: Maybe<OrderBy>;

    FavIcon?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    LaboratoryTitle?: Maybe<OrderBy>;

    LogbookTitle?: Maybe<OrderBy>;

    Logo?: Maybe<OrderBy>;

    MaintenanceTitle?: Maybe<OrderBy>;

    MyPlantsTableTitle?: Maybe<OrderBy>;

    MyPlantsTitle?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    OperatorTitle?: Maybe<OrderBy>;

    PlantOverrideWithPFDUnitId?: Maybe<OrderBy>;

    PlantTitle?: Maybe<OrderBy>;

    PrimaryColor?: Maybe<OrderBy>;

    ReportsTitle?: Maybe<OrderBy>;

    SecondaryColor?: Maybe<OrderBy>;

    TechnologiesTitle?: Maybe<OrderBy>;

    UtilitiesTitle?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "Branding" */
export interface BrandingArrRelInsertInput {
    data: BrandingInsertInput[];

    on_conflict?: Maybe<BrandingOnConflict>;
}
/** input type for inserting object relation for remote table "Branding" */
export interface BrandingObjRelInsertInput {
    data: BrandingInsertInput;

    on_conflict?: Maybe<BrandingOnConflict>;
}
/** order by aggregate values of table "CalendarEvents" */
export interface CalendarEventsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<CalendarEventsMaxOrderBy>;

    min?: Maybe<CalendarEventsMinOrderBy>;
}
/** order by max() on columns of table "CalendarEvents" */
export interface CalendarEventsMaxOrderBy {
    ColorPrimary?: Maybe<OrderBy>;

    ColorSecondary?: Maybe<OrderBy>;

    End?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MetaLocation?: Maybe<OrderBy>;

    MetaNotes?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    Start?: Maybe<OrderBy>;

    Title?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "CalendarEvents" */
export interface CalendarEventsMinOrderBy {
    ColorPrimary?: Maybe<OrderBy>;

    ColorSecondary?: Maybe<OrderBy>;

    End?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MetaLocation?: Maybe<OrderBy>;

    MetaNotes?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    Start?: Maybe<OrderBy>;

    Title?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "CalendarEvents" */
export interface CalendarEventsArrRelInsertInput {
    data: CalendarEventsInsertInput[];

    on_conflict?: Maybe<CalendarEventsOnConflict>;
}
/** input type for inserting object relation for remote table "CalendarEvents" */
export interface CalendarEventsObjRelInsertInput {
    data: CalendarEventsInsertInput;

    on_conflict?: Maybe<CalendarEventsOnConflict>;
}
/** order by aggregate values of table "ClientGroups" */
export interface ClientGroupsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ClientGroupsMaxOrderBy>;

    min?: Maybe<ClientGroupsMinOrderBy>;
}
/** order by max() on columns of table "ClientGroups" */
export interface ClientGroupsMaxOrderBy {
    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ClientGroups" */
export interface ClientGroupsMinOrderBy {
    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "ClientGroups" */
export interface ClientGroupsArrRelInsertInput {
    data: ClientGroupsInsertInput[];

    on_conflict?: Maybe<ClientGroupsOnConflict>;
}
/** input type for inserting object relation for remote table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersObjRelInsertInput {
    data: ClientGroupsSmartlabUsersInsertInput;

    on_conflict?: Maybe<ClientGroupsSmartlabUsersOnConflict>;
}
/** order by aggregate values of table "CumulTokens" */
export interface CumulTokensAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<CumulTokensMaxOrderBy>;

    min?: Maybe<CumulTokensMinOrderBy>;
}
/** order by max() on columns of table "CumulTokens" */
export interface CumulTokensMaxOrderBy {
    AuthorizationId?: Maybe<OrderBy>;

    AuthorizationToken?: Maybe<OrderBy>;

    CreatedAt?: Maybe<OrderBy>;

    HashedRequestSignature?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;

    ValidUntil?: Maybe<OrderBy>;
}
/** order by min() on columns of table "CumulTokens" */
export interface CumulTokensMinOrderBy {
    AuthorizationId?: Maybe<OrderBy>;

    AuthorizationToken?: Maybe<OrderBy>;

    CreatedAt?: Maybe<OrderBy>;

    HashedRequestSignature?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;

    ValidUntil?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "CumulTokens" */
export interface CumulTokensArrRelInsertInput {
    data: CumulTokensInsertInput[];

    on_conflict?: Maybe<CumulTokensOnConflict>;
}
/** input type for inserting object relation for remote table "CumulTokens" */
export interface CumulTokensObjRelInsertInput {
    data: CumulTokensInsertInput;

    on_conflict?: Maybe<CumulTokensOnConflict>;
}
/** order by aggregate values of table "DataProcConfig" */
export interface DataProcConfigAggregateOrderBy {
    avg?: Maybe<DataProcConfigAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfigMaxOrderBy>;

    min?: Maybe<DataProcConfigMinOrderBy>;

    stddev?: Maybe<DataProcConfigStddevOrderBy>;

    stddev_pop?: Maybe<DataProcConfigStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcConfigStddevSampOrderBy>;

    sum?: Maybe<DataProcConfigSumOrderBy>;

    var_pop?: Maybe<DataProcConfigVarPopOrderBy>;

    var_samp?: Maybe<DataProcConfigVarSampOrderBy>;

    variance?: Maybe<DataProcConfigVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcConfig" */
export interface DataProcConfigAvgOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcConfig" */
export interface DataProcConfigMaxOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfig" */
export interface DataProcConfigMinOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcConfig" */
export interface DataProcConfigStddevOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcConfig" */
export interface DataProcConfigStddevPopOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcConfig" */
export interface DataProcConfigStddevSampOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcConfig" */
export interface DataProcConfigSumOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcConfig" */
export interface DataProcConfigVarPopOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcConfig" */
export interface DataProcConfigVarSampOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcConfig" */
export interface DataProcConfigVarianceOrderBy {
    FrequencyMinutes?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "DataProcConfig" */
export interface DataProcConfigArrRelInsertInput {
    data: DataProcConfigInsertInput[];

    on_conflict?: Maybe<DataProcConfigOnConflict>;
}
/** input type for inserting object relation for remote table "DataProcConfig1" */
export interface DataProcConfig1ObjRelInsertInput {
    data: DataProcConfig1InsertInput;

    on_conflict?: Maybe<DataProcConfig1OnConflict>;
}
/** input type for inserting object relation for remote table "DataProcConfig2" */
export interface DataProcConfig2ObjRelInsertInput {
    data: DataProcConfig2InsertInput;

    on_conflict?: Maybe<DataProcConfig2OnConflict>;
}
/** input type for inserting object relation for remote table "DataProcConfig3" */
export interface DataProcConfig3ObjRelInsertInput {
    data: DataProcConfig3InsertInput;

    on_conflict?: Maybe<DataProcConfig3OnConflict>;
}
/** input type for inserting object relation for remote table "DataProcConfigCopyDataForDemo" */
export interface DataProcConfigCopyDataForDemoObjRelInsertInput {
    data: DataProcConfigCopyDataForDemoInsertInput;

    on_conflict?: Maybe<DataProcConfigCopyDataForDemoOnConflict>;
}
/** input type for inserting object relation for remote table "DataProcConfigCopyInflux" */
export interface DataProcConfigCopyInfluxObjRelInsertInput {
    data: DataProcConfigCopyInfluxInsertInput;

    on_conflict?: Maybe<DataProcConfigCopyInfluxOnConflict>;
}
/** order by aggregate values of table "DataProcSignal" */
export interface DataProcSignalAggregateOrderBy {
    avg?: Maybe<DataProcSignalAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcSignalMaxOrderBy>;

    min?: Maybe<DataProcSignalMinOrderBy>;

    stddev?: Maybe<DataProcSignalStddevOrderBy>;

    stddev_pop?: Maybe<DataProcSignalStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcSignalStddevSampOrderBy>;

    sum?: Maybe<DataProcSignalSumOrderBy>;

    var_pop?: Maybe<DataProcSignalVarPopOrderBy>;

    var_samp?: Maybe<DataProcSignalVarSampOrderBy>;

    variance?: Maybe<DataProcSignalVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcSignal" */
export interface DataProcSignalAvgOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcSignal" */
export interface DataProcSignalMaxOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    TableName?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcSignal" */
export interface DataProcSignalMinOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    TableName?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcSignal" */
export interface DataProcSignalStddevOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcSignal" */
export interface DataProcSignalStddevPopOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcSignal" */
export interface DataProcSignalStddevSampOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcSignal" */
export interface DataProcSignalSumOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcSignal" */
export interface DataProcSignalVarPopOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcSignal" */
export interface DataProcSignalVarSampOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcSignal" */
export interface DataProcSignalVarianceOrderBy {
    AggregationTime?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "DataProcSignal" */
export interface DataProcSignalArrRelInsertInput {
    data: DataProcSignalInsertInput[];

    on_conflict?: Maybe<DataProcSignalOnConflict>;
}
/** input type for inserting object relation for remote table "DataProcState" */
export interface DataProcStateObjRelInsertInput {
    data: DataProcStateInsertInput;

    on_conflict?: Maybe<DataProcStateOnConflict>;
}
/** input type for inserting object relation for remote table "DataProcTagStateDeps" */
export interface DataProcTagStateDepsObjRelInsertInput {
    data: DataProcTagStateDepsInsertInput;

    on_conflict?: Maybe<DataProcTagStateDepsOnConflict>;
}
/** input type for inserting object relation for remote table "GeneratedReports" */
export interface GeneratedReportsObjRelInsertInput {
    data: GeneratedReportsInsertInput;

    on_conflict?: Maybe<GeneratedReportsOnConflict>;
}
/** order by aggregate values of table "LaboScheduleStates" */
export interface LaboScheduleStatesAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<LaboScheduleStatesMaxOrderBy>;

    min?: Maybe<LaboScheduleStatesMinOrderBy>;
}
/** order by max() on columns of table "LaboScheduleStates" */
export interface LaboScheduleStatesMaxOrderBy {
    CreatedAt?: Maybe<OrderBy>;

    DeletedAt?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "LaboScheduleStates" */
export interface LaboScheduleStatesMinOrderBy {
    CreatedAt?: Maybe<OrderBy>;

    DeletedAt?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "LaboScheduleStates" */
export interface LaboScheduleStatesArrRelInsertInput {
    data: LaboScheduleStatesInsertInput[];

    on_conflict?: Maybe<LaboScheduleStatesOnConflict>;
}
/** input type for inserting object relation for remote table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningObjRelInsertInput {
    data: LaboScheduleStatesPlanningInsertInput;

    on_conflict?: Maybe<LaboScheduleStatesPlanningOnConflict>;
}
/** order by aggregate values of table "ProjectConfigurations" */
export interface ProjectConfigurationsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ProjectConfigurationsMaxOrderBy>;

    min?: Maybe<ProjectConfigurationsMinOrderBy>;
}
/** order by max() on columns of table "ProjectConfigurations" */
export interface ProjectConfigurationsMaxOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ProjectLogo?: Maybe<OrderBy>;

    ProjectName?: Maybe<OrderBy>;

    ProjectType?: Maybe<OrderBy>;

    ReportsDrive?: Maybe<OrderBy>;

    ReportsItem?: Maybe<OrderBy>;

    ReportsSite?: Maybe<OrderBy>;

    TimeZone?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ProjectConfigurations" */
export interface ProjectConfigurationsMinOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ProjectLogo?: Maybe<OrderBy>;

    ProjectName?: Maybe<OrderBy>;

    ProjectType?: Maybe<OrderBy>;

    ReportsDrive?: Maybe<OrderBy>;

    ReportsItem?: Maybe<OrderBy>;

    ReportsSite?: Maybe<OrderBy>;

    TimeZone?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "ProjectConfigurations" */
export interface ProjectConfigurationsArrRelInsertInput {
    data: ProjectConfigurationsInsertInput[];

    on_conflict?: Maybe<ProjectConfigurationsOnConflict>;
}
/** input type for inserting object relation for remote table "ProjectConfigurations" */
export interface ProjectConfigurationsObjRelInsertInput {
    data: ProjectConfigurationsInsertInput;

    on_conflict?: Maybe<ProjectConfigurationsOnConflict>;
}
/** order by aggregate values of table "ProjectGroups" */
export interface ProjectGroupsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ProjectGroupsMaxOrderBy>;

    min?: Maybe<ProjectGroupsMinOrderBy>;
}
/** order by max() on columns of table "ProjectGroups" */
export interface ProjectGroupsMaxOrderBy {
    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ProjectGroups" */
export interface ProjectGroupsMinOrderBy {
    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "ProjectGroups" */
export interface ProjectGroupsArrRelInsertInput {
    data: ProjectGroupsInsertInput[];

    on_conflict?: Maybe<ProjectGroupsOnConflict>;
}
/** input type for inserting object relation for remote table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersObjRelInsertInput {
    data: ProjectGroupsSmartlabUsersInsertInput;

    on_conflict?: Maybe<ProjectGroupsSmartlabUsersOnConflict>;
}
/** order by aggregate values of table "ReportProjectFiles" */
export interface ReportProjectFilesAggregateOrderBy {
    avg?: Maybe<ReportProjectFilesAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<ReportProjectFilesMaxOrderBy>;

    min?: Maybe<ReportProjectFilesMinOrderBy>;

    stddev?: Maybe<ReportProjectFilesStddevOrderBy>;

    stddev_pop?: Maybe<ReportProjectFilesStddevPopOrderBy>;

    stddev_samp?: Maybe<ReportProjectFilesStddevSampOrderBy>;

    sum?: Maybe<ReportProjectFilesSumOrderBy>;

    var_pop?: Maybe<ReportProjectFilesVarPopOrderBy>;

    var_samp?: Maybe<ReportProjectFilesVarSampOrderBy>;

    variance?: Maybe<ReportProjectFilesVarianceOrderBy>;
}
/** order by avg() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesAvgOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by max() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesMaxOrderBy {
    Created?: Maybe<OrderBy>;

    DriveItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Modified?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    Size?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesMinOrderBy {
    Created?: Maybe<OrderBy>;

    DriveItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Modified?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    Size?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesStddevOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesStddevPopOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesStddevSampOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesSumOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesVarPopOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesVarSampOrderBy {
    Size?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "ReportProjectFiles" */
export interface ReportProjectFilesVarianceOrderBy {
    Size?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "ReportProjectFiles" */
export interface ReportProjectFilesArrRelInsertInput {
    data: ReportProjectFilesInsertInput[];

    on_conflict?: Maybe<ReportProjectFilesOnConflict>;
}
/** input type for inserting object relation for remote table "ReportProjectFiles" */
export interface ReportProjectFilesObjRelInsertInput {
    data: ReportProjectFilesInsertInput;

    on_conflict?: Maybe<ReportProjectFilesOnConflict>;
}
/** input type for inserting object relation for remote table "ProjectReportTemplates" */
export interface ProjectReportTemplatesObjRelInsertInput {
    data: ProjectReportTemplatesInsertInput;

    on_conflict?: Maybe<ProjectReportTemplatesOnConflict>;
}
/** order by aggregate values of table "ReportTemplates" */
export interface ReportTemplatesAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ReportTemplatesMaxOrderBy>;

    min?: Maybe<ReportTemplatesMinOrderBy>;
}
/** order by max() on columns of table "ReportTemplates" */
export interface ReportTemplatesMaxOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ReportTemplates" */
export interface ReportTemplatesMinOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "ReportTemplates" */
export interface ReportTemplatesArrRelInsertInput {
    data: ReportTemplatesInsertInput[];

    on_conflict?: Maybe<ReportTemplatesOnConflict>;
}
/** order by aggregate values of table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<SmartlabFunctionalitiesMaxOrderBy>;

    min?: Maybe<SmartlabFunctionalitiesMinOrderBy>;
}
/** order by max() on columns of table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesMaxOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesMinOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesArrRelInsertInput {
    data: SmartlabFunctionalitiesInsertInput[];

    on_conflict?: Maybe<SmartlabFunctionalitiesOnConflict>;
}
/** input type for inserting object relation for remote table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesObjRelInsertInput {
    data: SmartlabFunctionalitiesInsertInput;

    on_conflict?: Maybe<SmartlabFunctionalitiesOnConflict>;
}
/** order by aggregate values of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksAggregateOrderBy {
    avg?: Maybe<SmartlabPfdUnitLinksAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<SmartlabPfdUnitLinksMaxOrderBy>;

    min?: Maybe<SmartlabPfdUnitLinksMinOrderBy>;

    stddev?: Maybe<SmartlabPfdUnitLinksStddevOrderBy>;

    stddev_pop?: Maybe<SmartlabPfdUnitLinksStddevPopOrderBy>;

    stddev_samp?: Maybe<SmartlabPfdUnitLinksStddevSampOrderBy>;

    sum?: Maybe<SmartlabPfdUnitLinksSumOrderBy>;

    var_pop?: Maybe<SmartlabPfdUnitLinksVarPopOrderBy>;

    var_samp?: Maybe<SmartlabPfdUnitLinksVarSampOrderBy>;

    variance?: Maybe<SmartlabPfdUnitLinksVarianceOrderBy>;
}
/** order by avg() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksAvgOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by max() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksMaxOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Link?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    PFDTagLink?: Maybe<OrderBy>;

    PFDUnitId?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksMinOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Link?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    PFDTagLink?: Maybe<OrderBy>;

    PFDUnitId?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksStddevOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksStddevPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksStddevSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksSumOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksVarPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksVarSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksVarianceOrderBy {
    Order?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksArrRelInsertInput {
    data: SmartlabPfdUnitLinksInsertInput[];

    on_conflict?: Maybe<SmartlabPfdUnitLinksOnConflict>;
}
/** input type for inserting object relation for remote table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksObjRelInsertInput {
    data: SmartlabPfdUnitLinksInsertInput;

    on_conflict?: Maybe<SmartlabPfdUnitLinksOnConflict>;
}
/** order by aggregate values of table "SmartlabUsers" */
export interface SmartlabUsersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<SmartlabUsersMaxOrderBy>;

    min?: Maybe<SmartlabUsersMinOrderBy>;
}
/** order by max() on columns of table "SmartlabUsers" */
export interface SmartlabUsersMaxOrderBy {
    Id?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SmartlabUsers" */
export interface SmartlabUsersMinOrderBy {
    Id?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** input type for inserting array relation for remote table "SmartlabUsers" */
export interface SmartlabUsersArrRelInsertInput {
    data: SmartlabUsersInsertInput[];

    on_conflict?: Maybe<SmartlabUsersOnConflict>;
}
/** select columns of table "Branding" */
export enum BrandingSelectColumn {
    AlertsEnabled = "AlertsEnabled",
    AlertsTitle = "AlertsTitle",
    AllowedProjects = "AllowedProjects",
    CalendarEnabled = "CalendarEnabled",
    CalendarTitle = "CalendarTitle",
    Domain = "Domain",
    EnableBigLogo = "EnableBigLogo",
    FavIcon = "FavIcon",
    Id = "Id",
    LaboratoryEnabled = "LaboratoryEnabled",
    LaboratoryTitle = "LaboratoryTitle",
    LogbookEnabled = "LogbookEnabled",
    LogbookTitle = "LogbookTitle",
    Logo = "Logo",
    MaintenanceEnabled = "MaintenanceEnabled",
    MaintenanceTitle = "MaintenanceTitle",
    MyPlantsTableTitle = "MyPlantsTableTitle",
    MyPlantsTitle = "MyPlantsTitle",
    Name = "Name",
    OperatorEnabled = "OperatorEnabled",
    OperatorTitle = "OperatorTitle",
    PlantEnabled = "PlantEnabled",
    PlantOverrideWithPfdUnitId = "PlantOverrideWithPFDUnitId",
    PlantTitle = "PlantTitle",
    PrimaryColor = "PrimaryColor",
    ReportsEnabled = "ReportsEnabled",
    ReportsTitle = "ReportsTitle",
    SecondaryColor = "SecondaryColor",
    TechnologiesEnabled = "TechnologiesEnabled",
    TechnologiesTitle = "TechnologiesTitle",
    UtilitiesEnabled = "UtilitiesEnabled",
    UtilitiesTitle = "UtilitiesTitle"
}
/** column ordering options */
export enum OrderBy {
    Asc = "asc",
    AscNullsFirst = "asc_nulls_first",
    AscNullsLast = "asc_nulls_last",
    Desc = "desc",
    DescNullsFirst = "desc_nulls_first",
    DescNullsLast = "desc_nulls_last"
}
/** select columns of table "CalendarEvents" */
export enum CalendarEventsSelectColumn {
    AllDay = "AllDay",
    ColorPrimary = "ColorPrimary",
    ColorSecondary = "ColorSecondary",
    Draggable = "Draggable",
    End = "End",
    Id = "Id",
    MetaLocation = "MetaLocation",
    MetaNotes = "MetaNotes",
    ProjectCode = "ProjectCode",
    ResizableAfterEnd = "ResizableAfterEnd",
    ResizableBeforeStart = "ResizableBeforeStart",
    Start = "Start",
    Title = "Title",
    UserId = "UserId"
}
/** select columns of table "ClientGroups" */
export enum ClientGroupsSelectColumn {
    ClientIds = "ClientIds",
    Id = "Id",
    Name = "Name"
}
/** select columns of table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsersSelectColumn {
    ClientGroupId = "ClientGroupId",
    Id = "Id",
    SmartlabUserId = "SmartlabUserId"
}
/** select columns of table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsersSelectColumn {
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    SmartlabUserId = "SmartlabUserId"
}
/** select columns of table "CumulTokens" */
export enum CumulTokensSelectColumn {
    AuthorizationId = "AuthorizationId",
    AuthorizationToken = "AuthorizationToken",
    CreatedAt = "CreatedAt",
    HashedRequestSignature = "HashedRequestSignature",
    Id = "Id",
    Revoked = "Revoked",
    UserId = "UserId",
    ValidUntil = "ValidUntil"
}
/** select columns of table "DataProcConfig" */
export enum DataProcConfigSelectColumn {
    FrequencyMinutes = "FrequencyMinutes",
    Id = "Id",
    IsStateEnabled = "IsStateEnabled",
    ProjectCode = "ProjectCode"
}
/** select columns of table "DataProcConfigCopyDataForDemo" */
export enum DataProcConfigCopyDataForDemoSelectColumn {
    ConfigurationModelId = "ConfigurationModelId",
    CopyFromDate = "CopyFromDate",
    CopyToDate = "CopyToDate",
    Enabled = "Enabled",
    Id = "Id"
}
/** select columns of table "DataProcConfigCopyInflux" */
export enum DataProcConfigCopyInfluxSelectColumn {
    ConfigurationModelId = "ConfigurationModelId",
    CopyRangeInSecondsPerRun = "CopyRangeInSecondsPerRun",
    Database = "Database",
    Enabled = "Enabled",
    Id = "Id"
}
/** select columns of table "DataProcConfig1" */
export enum DataProcConfig1SelectColumn {
    Account = "Account",
    ConfigurationModelId = "ConfigurationModelId",
    DevId = "DevId",
    Enabled = "Enabled",
    Id = "Id",
    Password = "Password",
    RowThreshold = "RowThreshold",
    Uri = "Uri",
    Username = "Username"
}
/** select columns of table "DataProcConfig2" */
export enum DataProcConfig2SelectColumn {
    ConfigurationModelId = "ConfigurationModelId",
    Database = "Database",
    Id = "Id"
}
/** select columns of table "DataProcConfig3" */
export enum DataProcConfig3SelectColumn {
    ConfigurationModelId = "ConfigurationModelId",
    Enabled = "Enabled",
    HourOffset = "HourOffset",
    Id = "Id"
}
/** select columns of table "DataProcState" */
export enum DataProcStateSelectColumn {
    ConfigurationId = "ConfigurationId",
    CopyDataForDemoEndDate = "CopyDataForDemoEndDate",
    EndedDate = "EndedDate",
    EwonErrorMessage = "EwonErrorMessage",
    EwonLoops = "EwonLoops",
    EwonPointsReceived = "EwonPointsReceived",
    EwonProcessingEndedDate = "EwonProcessingEndedDate",
    EwonProcessingStartedDate = "EwonProcessingStartedDate",
    EwonResult = "EwonResult",
    EwonSuccess = "EwonSuccess",
    EwonTotalPointsReceived = "EwonTotalPointsReceived",
    EwonTransactionId = "EwonTransactionId",
    Id = "Id",
    JobNr = "JobNr",
    KpiDate = "KpiDate",
    KpiValue = "KpiValue",
    StartedDate = "StartedDate",
    TagProcessingEndedDate = "TagProcessingEndedDate",
    TagProcessingErrorMessage = "TagProcessingErrorMessage",
    TagProcessingPointsProcessed = "TagProcessingPointsProcessed",
    TagProcessingStartedDate = "TagProcessingStartedDate",
    TagProcessingTagsDoneProcessing = "TagProcessingTagsDoneProcessing",
    TagProcessingTagsProcessed = "TagProcessingTagsProcessed",
    TagProcessingTotalPointsProcessed = "TagProcessingTotalPointsProcessed",
    Updated = "Updated"
}
/** select columns of table "DataProcTagState" */
export enum DataProcTagStateSelectColumn {
    AggregationTime = "AggregationTime",
    AggregationType = "AggregationType",
    BlockId = "BlockId",
    BlockName = "BlockName",
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    ErrorString = "ErrorString",
    FirstPendingDate = "FirstPendingDate",
    Formula = "Formula",
    InfluxTable = "InfluxTable",
    IsProcessing = "IsProcessing",
    JobNr = "JobNr",
    LastDependencyDate = "LastDependencyDate",
    LastPendingDate = "LastPendingDate",
    LastProcessedDate = "LastProcessedDate",
    OutputSignalId = "OutputSignalId",
    PointsProcessed = "PointsProcessed",
    ProcessedRangeDone = "ProcessedRangeDone",
    ProcessedRangeFrom = "ProcessedRangeFrom",
    ProcessedRangeTo = "ProcessedRangeTo",
    ProcessingEndedDate = "ProcessingEndedDate",
    ProcessingStartedDate = "ProcessingStartedDate",
    Result = "Result",
    TagId = "TagId",
    TagName = "TagName",
    Updated = "Updated"
}
/** select columns of table "DataProcTagStateDeps" */
export enum DataProcTagStateDepsSelectColumn {
    ChildAggregationTime = "ChildAggregationTime",
    ChildAggregationType = "ChildAggregationType",
    ChildTagId = "ChildTagId",
    ParentAggregationTime = "ParentAggregationTime",
    ParentAggregationType = "ParentAggregationType",
    ParentTagId = "ParentTagId"
}
/** select columns of table "DataProcSignal" */
export enum DataProcSignalSelectColumn {
    AggregationTime = "AggregationTime",
    AggregationType = "AggregationType",
    BlockId = "BlockId",
    DataType = "DataType",
    Id = "Id",
    TableName = "TableName",
    TagId = "TagId"
}
/** select columns of table "GeneratedReports" */
export enum GeneratedReportsSelectColumn {
    Created = "Created",
    DocUrl = "DocUrl",
    DynamicDataSnapshot = "DynamicDataSnapshot",
    Errors = "Errors",
    Finished = "Finished",
    Id = "Id",
    ProjectConfigurationSnapshot = "ProjectConfigurationSnapshot",
    ProjectReportTemplateId = "ProjectReportTemplateId",
    ProjectReportTemplateSnapshot = "ProjectReportTemplateSnapshot",
    ReportDateTime = "ReportDateTime",
    ReportTemplateId = "ReportTemplateId",
    State = "State",
    Version = "Version",
    Warnings = "Warnings",
    WebUrl = "WebUrl",
    ProjectCode = "projectCode"
}
/** select columns of table "ProjectReportTemplates" */
export enum ProjectReportTemplatesSelectColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    Frequency = "Frequency",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    Parameters = "Parameters",
    ReportTemplateId = "ReportTemplateId",
    ProjectCode = "projectCode"
}
/** select columns of table "LaboScheduleStates" */
export enum LaboScheduleStatesSelectColumn {
    CreatedAt = "CreatedAt",
    Deleted = "Deleted",
    DeletedAt = "DeletedAt",
    Id = "Id",
    Name = "Name",
    ProjectId = "ProjectId",
    Schedule = "Schedule"
}
/** select columns of table "LaboScheduleStatesPlanning" */
export enum LaboScheduleStatesPlanningSelectColumn {
    Id = "Id",
    Planned = "Planned",
    ProjectId = "ProjectId",
    StateId = "StateId"
}
/** select columns of table "ProjectConfigurations" */
export enum ProjectConfigurationsSelectColumn {
    Id = "Id",
    ProjectCode = "ProjectCode",
    ProjectLogo = "ProjectLogo",
    ProjectName = "ProjectName",
    ProjectType = "ProjectType",
    ReportsDrive = "ReportsDrive",
    ReportsItem = "ReportsItem",
    ReportsSite = "ReportsSite",
    TimeZone = "TimeZone"
}
/** select columns of table "ProjectGroups" */
export enum ProjectGroupsSelectColumn {
    ClientGroupId = "ClientGroupId",
    Id = "Id",
    Name = "Name",
    ProjectCodes = "ProjectCodes"
}
/** select columns of table "ReportProjectFiles" */
export enum ReportProjectFilesSelectColumn {
    Created = "Created",
    DriveItemId = "DriveItemId",
    Id = "Id",
    Modified = "Modified",
    Name = "Name",
    ProjectCode = "ProjectCode",
    Size = "Size",
    Type = "Type",
    WebUrl = "WebUrl"
}
/** select columns of table "ReportTemplates" */
export enum ReportTemplatesSelectColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    Description = "Description",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    Name = "Name",
    ReportTemplateBlocks = "ReportTemplateBlocks",
    Version = "Version"
}
/** select columns of table "SmartlabFunctionalities" */
export enum SmartlabFunctionalitiesSelectColumn {
    AllowedPermissions = "AllowedPermissions",
    Description = "Description",
    Id = "Id",
    Name = "Name"
}
/** select columns of table "SmartlabPFDUnitLinks" */
export enum SmartlabPfdUnitLinksSelectColumn {
    Description = "Description",
    Id = "Id",
    Link = "Link",
    Name = "Name",
    Order = "Order",
    PfdTagLink = "PFDTagLink",
    PfdUnitId = "PFDUnitId",
    Targets = "Targets",
    Type = "Type"
}
/** select columns of table "SmartlabUsers" */
export enum SmartlabUsersSelectColumn {
    Id = "Id",
    UserId = "UserId"
}
/** unique or primary key constraints on table "Branding" */
export enum BrandingConstraint {
    PkBranding = "PK_Branding"
}
/** update columns of table "Branding" */
export enum BrandingUpdateColumn {
    AlertsEnabled = "AlertsEnabled",
    AlertsTitle = "AlertsTitle",
    AllowedProjects = "AllowedProjects",
    CalendarEnabled = "CalendarEnabled",
    CalendarTitle = "CalendarTitle",
    Domain = "Domain",
    EnableBigLogo = "EnableBigLogo",
    FavIcon = "FavIcon",
    Id = "Id",
    LaboratoryEnabled = "LaboratoryEnabled",
    LaboratoryTitle = "LaboratoryTitle",
    LogbookEnabled = "LogbookEnabled",
    LogbookTitle = "LogbookTitle",
    Logo = "Logo",
    MaintenanceEnabled = "MaintenanceEnabled",
    MaintenanceTitle = "MaintenanceTitle",
    MyPlantsTableTitle = "MyPlantsTableTitle",
    MyPlantsTitle = "MyPlantsTitle",
    Name = "Name",
    OperatorEnabled = "OperatorEnabled",
    OperatorTitle = "OperatorTitle",
    PlantEnabled = "PlantEnabled",
    PlantOverrideWithPfdUnitId = "PlantOverrideWithPFDUnitId",
    PlantTitle = "PlantTitle",
    PrimaryColor = "PrimaryColor",
    ReportsEnabled = "ReportsEnabled",
    ReportsTitle = "ReportsTitle",
    SecondaryColor = "SecondaryColor",
    TechnologiesEnabled = "TechnologiesEnabled",
    TechnologiesTitle = "TechnologiesTitle",
    UtilitiesEnabled = "UtilitiesEnabled",
    UtilitiesTitle = "UtilitiesTitle"
}
/** unique or primary key constraints on table "CalendarEvents" */
export enum CalendarEventsConstraint {
    PkCalendarEvents = "PK_CalendarEvents"
}
/** update columns of table "CalendarEvents" */
export enum CalendarEventsUpdateColumn {
    AllDay = "AllDay",
    ColorPrimary = "ColorPrimary",
    ColorSecondary = "ColorSecondary",
    Draggable = "Draggable",
    End = "End",
    Id = "Id",
    MetaLocation = "MetaLocation",
    MetaNotes = "MetaNotes",
    ProjectCode = "ProjectCode",
    ResizableAfterEnd = "ResizableAfterEnd",
    ResizableBeforeStart = "ResizableBeforeStart",
    Start = "Start",
    Title = "Title",
    UserId = "UserId"
}
/** unique or primary key constraints on table "ClientGroups" */
export enum ClientGroupsConstraint {
    PkClientGroups = "PK_ClientGroups"
}
/** update columns of table "ClientGroups" */
export enum ClientGroupsUpdateColumn {
    ClientIds = "ClientIds",
    Id = "Id",
    Name = "Name"
}
/** unique or primary key constraints on table "ProjectGroups" */
export enum ProjectGroupsConstraint {
    PkProjectGroups = "PK_ProjectGroups"
}
/** update columns of table "ProjectGroups" */
export enum ProjectGroupsUpdateColumn {
    ClientGroupId = "ClientGroupId",
    Id = "Id",
    Name = "Name",
    ProjectCodes = "ProjectCodes"
}
/** unique or primary key constraints on table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsersConstraint {
    PkProjectGroupsSmartlabUsers = "PK_ProjectGroupsSmartlabUsers"
}
/** update columns of table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsersUpdateColumn {
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    SmartlabUserId = "SmartlabUserId"
}
/** unique or primary key constraints on table "SmartlabUsers" */
export enum SmartlabUsersConstraint {
    PkSmartlabUsers = "PK_SmartlabUsers",
    SmartlabUsersUserIdKey = "SmartlabUsers_UserId_key"
}
/** update columns of table "SmartlabUsers" */
export enum SmartlabUsersUpdateColumn {
    Id = "Id",
    UserId = "UserId"
}
/** unique or primary key constraints on table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsersConstraint {
    PkClientGroupsSmartlabUsers = "PK_ClientGroupsSmartlabUsers"
}
/** update columns of table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsersUpdateColumn {
    ClientGroupId = "ClientGroupId",
    Id = "Id",
    SmartlabUserId = "SmartlabUserId"
}
/** unique or primary key constraints on table "CumulTokens" */
export enum CumulTokensConstraint {
    PkCumulTokens = "PK_CumulTokens"
}
/** update columns of table "CumulTokens" */
export enum CumulTokensUpdateColumn {
    AuthorizationId = "AuthorizationId",
    AuthorizationToken = "AuthorizationToken",
    CreatedAt = "CreatedAt",
    HashedRequestSignature = "HashedRequestSignature",
    Id = "Id",
    Revoked = "Revoked",
    UserId = "UserId",
    ValidUntil = "ValidUntil"
}
/** unique or primary key constraints on table "DataProcConfig" */
export enum DataProcConfigConstraint {
    PkDataProcConfig = "PK_DataProcConfig"
}
/** update columns of table "DataProcConfig" */
export enum DataProcConfigUpdateColumn {
    FrequencyMinutes = "FrequencyMinutes",
    Id = "Id",
    IsStateEnabled = "IsStateEnabled",
    ProjectCode = "ProjectCode"
}
/** unique or primary key constraints on table "DataProcConfigCopyDataForDemo" */
export enum DataProcConfigCopyDataForDemoConstraint {
    IxDataProcConfigCopyDataForDemoConfigurationModelId = "IX_DataProcConfigCopyDataForDemo_ConfigurationModelId",
    PkDataProcConfigCopyDataForDemo = "PK_DataProcConfigCopyDataForDemo"
}
/** update columns of table "DataProcConfigCopyDataForDemo" */
export enum DataProcConfigCopyDataForDemoUpdateColumn {
    ConfigurationModelId = "ConfigurationModelId",
    CopyFromDate = "CopyFromDate",
    CopyToDate = "CopyToDate",
    Enabled = "Enabled",
    Id = "Id"
}
/** unique or primary key constraints on table "DataProcConfigCopyInflux" */
export enum DataProcConfigCopyInfluxConstraint {
    IxDataProcConfigCopyInfluxConfigurationModelId = "IX_DataProcConfigCopyInflux_ConfigurationModelId",
    PkDataProcConfigCopyInflux = "PK_DataProcConfigCopyInflux"
}
/** update columns of table "DataProcConfigCopyInflux" */
export enum DataProcConfigCopyInfluxUpdateColumn {
    ConfigurationModelId = "ConfigurationModelId",
    CopyRangeInSecondsPerRun = "CopyRangeInSecondsPerRun",
    Database = "Database",
    Enabled = "Enabled",
    Id = "Id"
}
/** unique or primary key constraints on table "DataProcConfig1" */
export enum DataProcConfig1Constraint {
    IxDataProcConfig1ConfigurationModelId = "IX_DataProcConfig1_ConfigurationModelId",
    PkDataProcConfig1 = "PK_DataProcConfig1"
}
/** update columns of table "DataProcConfig1" */
export enum DataProcConfig1UpdateColumn {
    Account = "Account",
    ConfigurationModelId = "ConfigurationModelId",
    DevId = "DevId",
    Enabled = "Enabled",
    Id = "Id",
    Password = "Password",
    RowThreshold = "RowThreshold",
    Uri = "Uri",
    Username = "Username"
}
/** unique or primary key constraints on table "DataProcConfig2" */
export enum DataProcConfig2Constraint {
    IxDataProcConfig2ConfigurationModelId = "IX_DataProcConfig2_ConfigurationModelId",
    PkDataProcConfig2 = "PK_DataProcConfig2"
}
/** update columns of table "DataProcConfig2" */
export enum DataProcConfig2UpdateColumn {
    ConfigurationModelId = "ConfigurationModelId",
    Database = "Database",
    Id = "Id"
}
/** unique or primary key constraints on table "DataProcConfig3" */
export enum DataProcConfig3Constraint {
    IxDataProcConfig3ConfigurationModelId = "IX_DataProcConfig3_ConfigurationModelId",
    PkDataProcConfig3 = "PK_DataProcConfig3"
}
/** update columns of table "DataProcConfig3" */
export enum DataProcConfig3UpdateColumn {
    ConfigurationModelId = "ConfigurationModelId",
    Enabled = "Enabled",
    HourOffset = "HourOffset",
    Id = "Id"
}
/** unique or primary key constraints on table "DataProcState" */
export enum DataProcStateConstraint {
    PkDataProcState = "PK_DataProcState"
}
/** update columns of table "DataProcState" */
export enum DataProcStateUpdateColumn {
    ConfigurationId = "ConfigurationId",
    CopyDataForDemoEndDate = "CopyDataForDemoEndDate",
    EndedDate = "EndedDate",
    EwonErrorMessage = "EwonErrorMessage",
    EwonLoops = "EwonLoops",
    EwonPointsReceived = "EwonPointsReceived",
    EwonProcessingEndedDate = "EwonProcessingEndedDate",
    EwonProcessingStartedDate = "EwonProcessingStartedDate",
    EwonResult = "EwonResult",
    EwonSuccess = "EwonSuccess",
    EwonTotalPointsReceived = "EwonTotalPointsReceived",
    EwonTransactionId = "EwonTransactionId",
    Id = "Id",
    JobNr = "JobNr",
    KpiDate = "KpiDate",
    KpiValue = "KpiValue",
    StartedDate = "StartedDate",
    TagProcessingEndedDate = "TagProcessingEndedDate",
    TagProcessingErrorMessage = "TagProcessingErrorMessage",
    TagProcessingPointsProcessed = "TagProcessingPointsProcessed",
    TagProcessingStartedDate = "TagProcessingStartedDate",
    TagProcessingTagsDoneProcessing = "TagProcessingTagsDoneProcessing",
    TagProcessingTagsProcessed = "TagProcessingTagsProcessed",
    TagProcessingTotalPointsProcessed = "TagProcessingTotalPointsProcessed",
    Updated = "Updated"
}
/** unique or primary key constraints on table "DataProcTagState" */
export enum DataProcTagStateConstraint {
    PkDataProcTagState = "PK_DataProcTagState"
}
/** update columns of table "DataProcTagState" */
export enum DataProcTagStateUpdateColumn {
    AggregationTime = "AggregationTime",
    AggregationType = "AggregationType",
    BlockId = "BlockId",
    BlockName = "BlockName",
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    ErrorString = "ErrorString",
    FirstPendingDate = "FirstPendingDate",
    Formula = "Formula",
    InfluxTable = "InfluxTable",
    IsProcessing = "IsProcessing",
    JobNr = "JobNr",
    LastDependencyDate = "LastDependencyDate",
    LastPendingDate = "LastPendingDate",
    LastProcessedDate = "LastProcessedDate",
    OutputSignalId = "OutputSignalId",
    PointsProcessed = "PointsProcessed",
    ProcessedRangeDone = "ProcessedRangeDone",
    ProcessedRangeFrom = "ProcessedRangeFrom",
    ProcessedRangeTo = "ProcessedRangeTo",
    ProcessingEndedDate = "ProcessingEndedDate",
    ProcessingStartedDate = "ProcessingStartedDate",
    Result = "Result",
    TagId = "TagId",
    TagName = "TagName",
    Updated = "Updated"
}
/** unique or primary key constraints on table "DataProcTagStateDeps" */
export enum DataProcTagStateDepsConstraint {
    PkDataProcTagStateDeps = "PK_DataProcTagStateDeps"
}
/** update columns of table "DataProcTagStateDeps" */
export enum DataProcTagStateDepsUpdateColumn {
    ChildAggregationTime = "ChildAggregationTime",
    ChildAggregationType = "ChildAggregationType",
    ChildTagId = "ChildTagId",
    ParentAggregationTime = "ParentAggregationTime",
    ParentAggregationType = "ParentAggregationType",
    ParentTagId = "ParentTagId"
}
/** unique or primary key constraints on table "DataProcSignal" */
export enum DataProcSignalConstraint {
    PkDataProcSignal = "PK_DataProcSignal"
}
/** update columns of table "DataProcSignal" */
export enum DataProcSignalUpdateColumn {
    AggregationTime = "AggregationTime",
    AggregationType = "AggregationType",
    BlockId = "BlockId",
    DataType = "DataType",
    Id = "Id",
    TableName = "TableName",
    TagId = "TagId"
}
/** unique or primary key constraints on table "GeneratedReports" */
export enum GeneratedReportsConstraint {
    PkGeneratedReports = "PK_GeneratedReports"
}
/** update columns of table "GeneratedReports" */
export enum GeneratedReportsUpdateColumn {
    Created = "Created",
    DocUrl = "DocUrl",
    DynamicDataSnapshot = "DynamicDataSnapshot",
    Errors = "Errors",
    Finished = "Finished",
    Id = "Id",
    ProjectConfigurationSnapshot = "ProjectConfigurationSnapshot",
    ProjectReportTemplateId = "ProjectReportTemplateId",
    ProjectReportTemplateSnapshot = "ProjectReportTemplateSnapshot",
    ReportDateTime = "ReportDateTime",
    ReportTemplateId = "ReportTemplateId",
    State = "State",
    Version = "Version",
    Warnings = "Warnings",
    WebUrl = "WebUrl",
    ProjectCode = "projectCode"
}
/** unique or primary key constraints on table "ProjectReportTemplates" */
export enum ProjectReportTemplatesConstraint {
    PkProjectReportTemplates = "PK_ProjectReportTemplates"
}
/** update columns of table "ProjectReportTemplates" */
export enum ProjectReportTemplatesUpdateColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    Frequency = "Frequency",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    Parameters = "Parameters",
    ReportTemplateId = "ReportTemplateId",
    ProjectCode = "projectCode"
}
/** unique or primary key constraints on table "ReportTemplates" */
export enum ReportTemplatesConstraint {
    PkReportTemplates = "PK_ReportTemplates"
}
/** update columns of table "ReportTemplates" */
export enum ReportTemplatesUpdateColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    Description = "Description",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    Name = "Name",
    ReportTemplateBlocks = "ReportTemplateBlocks",
    Version = "Version"
}
/** unique or primary key constraints on table "LaboScheduleStates" */
export enum LaboScheduleStatesConstraint {
    PkLaboScheduleStates = "PK_LaboScheduleStates"
}
/** update columns of table "LaboScheduleStates" */
export enum LaboScheduleStatesUpdateColumn {
    CreatedAt = "CreatedAt",
    Deleted = "Deleted",
    DeletedAt = "DeletedAt",
    Id = "Id",
    Name = "Name",
    ProjectId = "ProjectId",
    Schedule = "Schedule"
}
/** unique or primary key constraints on table "LaboScheduleStatesPlanning" */
export enum LaboScheduleStatesPlanningConstraint {
    PkLaboScheduleStatesPlanning = "PK_LaboScheduleStatesPlanning"
}
/** update columns of table "LaboScheduleStatesPlanning" */
export enum LaboScheduleStatesPlanningUpdateColumn {
    Id = "Id",
    Planned = "Planned",
    ProjectId = "ProjectId",
    StateId = "StateId"
}
/** unique or primary key constraints on table "ProjectConfigurations" */
export enum ProjectConfigurationsConstraint {
    PkProjectConfigurations = "PK_ProjectConfigurations"
}
/** update columns of table "ProjectConfigurations" */
export enum ProjectConfigurationsUpdateColumn {
    Id = "Id",
    ProjectCode = "ProjectCode",
    ProjectLogo = "ProjectLogo",
    ProjectName = "ProjectName",
    ProjectType = "ProjectType",
    ReportsDrive = "ReportsDrive",
    ReportsItem = "ReportsItem",
    ReportsSite = "ReportsSite",
    TimeZone = "TimeZone"
}
/** unique or primary key constraints on table "ReportProjectFiles" */
export enum ReportProjectFilesConstraint {
    PkReportProjectFiles = "PK_ReportProjectFiles"
}
/** update columns of table "ReportProjectFiles" */
export enum ReportProjectFilesUpdateColumn {
    Created = "Created",
    DriveItemId = "DriveItemId",
    Id = "Id",
    Modified = "Modified",
    Name = "Name",
    ProjectCode = "ProjectCode",
    Size = "Size",
    Type = "Type",
    WebUrl = "WebUrl"
}
/** unique or primary key constraints on table "SmartlabFunctionalities" */
export enum SmartlabFunctionalitiesConstraint {
    PkSmartlabFunctionalities = "PK_SmartlabFunctionalities"
}
/** update columns of table "SmartlabFunctionalities" */
export enum SmartlabFunctionalitiesUpdateColumn {
    AllowedPermissions = "AllowedPermissions",
    Description = "Description",
    Id = "Id",
    Name = "Name"
}
/** unique or primary key constraints on table "SmartlabPFDUnitLinks" */
export enum SmartlabPfdUnitLinksConstraint {
    PkSmartlabPfdUnitLinks = "PK_SmartlabPFDUnitLinks"
}
/** update columns of table "SmartlabPFDUnitLinks" */
export enum SmartlabPfdUnitLinksUpdateColumn {
    Description = "Description",
    Id = "Id",
    Link = "Link",
    Name = "Name",
    Order = "Order",
    PfdTagLink = "PFDTagLink",
    PfdUnitId = "PFDUnitId",
    Targets = "Targets",
    Type = "Type"
}

export type Jsonb = any;

export type Uuid = any;

export type Timestamptz = any;

export type _Text = any;

export type Timestamp = any;

export type Numeric = any;

export type Float8 = any;

export type Bigint = any;

/** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type Date = any;

/** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type DateTime = any;

/** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type DateTimeOffset = any;

export type Decimal = any;

/** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
export type Milliseconds = any;

/** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
export type Seconds = any;

// ====================================================
// Documents
// ====================================================

export namespace CreateDataProcConf {
    export type Variables = {
        dataProcConfInput: ConfigurationInput;
    };

    export type Mutation = {
        __typename?: "Mutation";

        createDataProcConf: Maybe<CreateDataProcConf>;
    };

    export type CreateDataProcConf = {
        __typename?: "ConfigurationType";

        id: Maybe<string>;

        frequencyMinutes: Maybe<number>;

        ewon: Maybe<Ewon>;

        influx: Maybe<Influx>;
    };

    export type Ewon = {
        __typename?: "EwonConfigurationType";

        uri: string;

        account: string;

        devId: string;

        username: string;

        password: string;

        rowThreshold: number;
    };

    export type Influx = {
        __typename?: "InfluxConfigurationType";

        database: string;
    };
}

export namespace CreateNotification {
    export type Variables = {
        notificationInput: NotificationInput;
    };

    export type Mutation = {
        __typename?: "Mutation";

        createNotification: Maybe<CreateNotification>;
    };

    export type CreateNotification = {
        __typename?: "NotificationType";

        id: Maybe<string>;

        text: string;

        title: string;

        data: Maybe<string>;
    };
}

export namespace DeleteCalendarEvents {
    export type Variables = {
        CalendarEvents_bool_exp: CalendarEventsBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        delete_CalendarEvents: Maybe<DeleteCalendarEvents>;
    };

    export type DeleteCalendarEvents = {
        __typename?: "CalendarEvents_mutation_response";

        affected_rows: number;
    };
}

export namespace DeleteLaboScheduleStates {
    export type Variables = {
        LaboScheduleStates_bool_exp: LaboScheduleStatesBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        delete_LaboScheduleStates: Maybe<DeleteLaboScheduleStates>;
    };

    export type DeleteLaboScheduleStates = {
        __typename?: "LaboScheduleStates_mutation_response";

        affected_rows: number;
    };
}

export namespace DeleteLaboScheduleStatesPlanning {
    export type Variables = {
        LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanningBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        delete_LaboScheduleStatesPlanning: Maybe<
            DeleteLaboScheduleStatesPlanning
        >;
    };

    export type DeleteLaboScheduleStatesPlanning = {
        __typename?: "LaboScheduleStatesPlanning_mutation_response";

        affected_rows: number;
    };
}

export namespace InsertCalendarEvents {
    export type Variables = {
        CalendarEvents_insert_input: CalendarEventsInsertInput[];
    };

    export type Mutation = {
        __typename?: "Mutation";

        insert_CalendarEvents: Maybe<InsertCalendarEvents>;
    };

    export type InsertCalendarEvents = {
        __typename?: "CalendarEvents_mutation_response";

        returning: Returning[];
    };

    export type Returning = {
        __typename?: "CalendarEvents";

        UserId: Maybe<string>;

        Title: Maybe<string>;

        Start: Timestamptz;

        ResizableBeforeStart: boolean;

        ResizableAfterEnd: boolean;

        ProjectCode: Maybe<string>;

        MetaNotes: Maybe<string>;

        MetaLocation: Maybe<string>;

        Id: Uuid;

        End: Timestamptz;

        Draggable: boolean;

        ColorSecondary: Maybe<string>;

        ColorPrimary: Maybe<string>;

        AllDay: boolean;
    };
}

export namespace InsertLaboScheduleStates {
    export type Variables = {
        LaboScheduleStates_insert_input: LaboScheduleStatesInsertInput[];
    };

    export type Mutation = {
        __typename?: "Mutation";

        insert_LaboScheduleStates: Maybe<InsertLaboScheduleStates>;
    };

    export type InsertLaboScheduleStates = {
        __typename?: "LaboScheduleStates_mutation_response";

        returning: Returning[];
    };

    export type Returning = {
        __typename?: "LaboScheduleStates";

        Schedule: Maybe<Jsonb>;

        ProjectId: string;

        Name: string;

        Id: Uuid;

        DeletedAt: Timestamp;

        Deleted: boolean;

        CreatedAt: Timestamp;
    };
}

export namespace InsertLaboScheduleStatesPlanning {
    export type Variables = {
        LaboScheduleStatesPlanning_insert_input: LaboScheduleStatesPlanningInsertInput[];
    };

    export type Mutation = {
        __typename?: "Mutation";

        insert_LaboScheduleStatesPlanning: Maybe<
            InsertLaboScheduleStatesPlanning
        >;
    };

    export type InsertLaboScheduleStatesPlanning = {
        __typename?: "LaboScheduleStatesPlanning_mutation_response";

        returning: Returning[];
    };

    export type Returning = {
        __typename?: "LaboScheduleStatesPlanning";

        Id: Uuid;

        Planned: Timestamp;

        StateId: Uuid;

        ProjectId: string;
    };
}

export namespace UpdateCalendarEvents {
    export type Variables = {
        CalendarEvents_set_input: CalendarEventsSetInput;
        CalendarEvents_bool_exp: CalendarEventsBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        update_CalendarEvents: Maybe<UpdateCalendarEvents>;
    };

    export type UpdateCalendarEvents = {
        __typename?: "CalendarEvents_mutation_response";

        affected_rows: number;
    };
}

export namespace UpdateDataProcConfs {
    export type Variables = {
        id: string;
        dataProcConfInput: ConfigurationInput;
    };

    export type Mutation = {
        __typename?: "Mutation";

        updateDataProcConf: Maybe<UpdateDataProcConf>;
    };

    export type UpdateDataProcConf = {
        __typename?: "ConfigurationType";

        id: Maybe<string>;

        frequencyMinutes: Maybe<number>;

        ewon: Maybe<Ewon>;

        influx: Maybe<Influx>;
    };

    export type Ewon = {
        __typename?: "EwonConfigurationType";

        uri: string;

        account: string;

        devId: string;

        username: string;

        password: string;

        rowThreshold: number;
    };

    export type Influx = {
        __typename?: "InfluxConfigurationType";

        database: string;
    };
}

export namespace UpdateLaboScheduleStates {
    export type Variables = {
        LaboScheduleStates_set_input: LaboScheduleStatesSetInput;
        LaboScheduleStates_bool_exp: LaboScheduleStatesBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        update_LaboScheduleStates: Maybe<UpdateLaboScheduleStates>;
    };

    export type UpdateLaboScheduleStates = {
        __typename?: "LaboScheduleStates_mutation_response";

        affected_rows: number;
    };
}

export namespace UpdateNotification {
    export type Variables = {
        id: string;
        notificationInput: NotificationInput;
    };

    export type Mutation = {
        __typename?: "Mutation";

        updateNotification: Maybe<UpdateNotification>;
    };

    export type UpdateNotification = {
        __typename?: "NotificationType";

        id: Maybe<string>;

        text: string;

        title: string;

        data: Maybe<string>;
    };
}

export namespace BrandingWhere {
    export type Variables = {
        Branding_bool_exp: BrandingBoolExp;
        Branding_order_by?: Maybe<BrandingOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        Branding: Branding[];
    };

    export type Branding = {
        __typename?: "Branding";

        AlertsEnabled: boolean;

        AlertsTitle: Maybe<string>;

        CalendarEnabled: boolean;

        CalendarTitle: Maybe<string>;

        Domain: string;

        LaboratoryEnabled: boolean;

        LaboratoryTitle: Maybe<string>;

        LogbookEnabled: boolean;

        LogbookTitle: Maybe<string>;

        Logo: Maybe<string>;

        EnableBigLogo: boolean;

        MaintenanceEnabled: boolean;

        MaintenanceTitle: Maybe<string>;

        MyPlantsTitle: Maybe<string>;

        MyPlantsTableTitle: Maybe<string>;

        Name: string;

        OperatorEnabled: boolean;

        OperatorTitle: Maybe<string>;

        PlantEnabled: boolean;

        PlantTitle: Maybe<string>;

        PrimaryColor: Maybe<string>;

        ReportsEnabled: boolean;

        ReportsTitle: Maybe<string>;

        SecondaryColor: Maybe<string>;

        TechnologiesEnabled: boolean;

        TechnologiesTitle: Maybe<string>;

        UtilitiesEnabled: boolean;

        UtilitiesTitle: Maybe<string>;

        PlantOverrideWithPFDUnitId: Maybe<string>;

        FavIcon: Maybe<string>;

        AllowedProjects: Maybe<Jsonb>;
    };
}

export namespace CalendarEventsWhere {
    export type Variables = {
        CalendarEvents_bool_exp: CalendarEventsBoolExp;
        CalendarEvents_order_by?: Maybe<CalendarEventsOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        CalendarEvents: CalendarEvents[];
    };

    export type CalendarEvents = {
        __typename?: "CalendarEvents";

        UserId: Maybe<string>;

        Title: Maybe<string>;

        Start: Timestamptz;

        ResizableBeforeStart: boolean;

        ResizableAfterEnd: boolean;

        ProjectCode: Maybe<string>;

        MetaNotes: Maybe<string>;

        MetaLocation: Maybe<string>;

        Id: Uuid;

        End: Timestamptz;

        Draggable: boolean;

        ColorSecondary: Maybe<string>;

        ColorPrimary: Maybe<string>;

        AllDay: boolean;
    };
}

export namespace ClientGroupsSmartlabUsersWhere {
    export type Variables = {
        ClientGroupsSmartlabUsers_bool_exp: ClientGroupsSmartlabUsersBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        ClientGroupsSmartlabUsers: ClientGroupsSmartlabUsers[];
    };

    export type ClientGroupsSmartlabUsers = {
        __typename?: "ClientGroupsSmartlabUsers";

        ClientGroup: ClientGroup;
    };

    export type ClientGroup = {
        __typename?: "ClientGroups";

        Id: Uuid;

        Name: Maybe<string>;
    };
}

export namespace DataProcConfs {
    export type Variables = {
        filter?: Maybe<string>;
        id?: Maybe<string>;
    };

    export type Query = {
        __typename?: "Query";

        dataProcConfs: Maybe<(Maybe<DataProcConfs>)[]>;
    };

    export type DataProcConfs = {
        __typename?: "ConfigurationType";

        id: Maybe<string>;

        frequencyMinutes: Maybe<number>;

        ewon: Maybe<Ewon>;

        influx: Maybe<Influx>;
    };

    export type Ewon = {
        __typename?: "EwonConfigurationType";

        uri: string;

        account: string;

        devId: string;

        username: string;

        password: string;

        rowThreshold: number;
    };

    export type Influx = {
        __typename?: "InfluxConfigurationType";

        database: string;
    };
}

export namespace LaboScheduleStatesPlanningWhere {
    export type Variables = {
        Limit: number;
        LaboScheduleStatesPlanning_order_by?: Maybe<
            LaboScheduleStatesPlanningOrderBy[]
        >;
        LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanningBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        LaboScheduleStatesPlanning: LaboScheduleStatesPlanning[];
    };

    export type LaboScheduleStatesPlanning = {
        __typename?: "LaboScheduleStatesPlanning";

        Id: Uuid;

        Planned: Timestamp;

        StateId: Uuid;

        ProjectId: string;

        LaboScheduleState: LaboScheduleState;
    };

    export type LaboScheduleState = {
        __typename?: "LaboScheduleStates";

        Schedule: Maybe<Jsonb>;

        Id: Uuid;

        Name: string;
    };
}

export namespace LaboScheduleStatesWhere {
    export type Variables = {
        LaboScheduleStates_bool_exp: LaboScheduleStatesBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        LaboScheduleStates: LaboScheduleStates[];
    };

    export type LaboScheduleStates = {
        __typename?: "LaboScheduleStates";

        Schedule: Maybe<Jsonb>;

        ProjectId: string;

        Name: string;

        Id: Uuid;

        DeletedAt: Timestamp;

        Deleted: boolean;

        CreatedAt: Timestamp;
    };
}

export namespace Notifications {
    export type Variables = {
        filter?: Maybe<string>;
        id?: Maybe<string>;
    };

    export type Query = {
        __typename?: "Query";

        notifications: Maybe<(Maybe<Notifications>)[]>;
    };

    export type Notifications = {
        __typename?: "NotificationType";

        id: Maybe<string>;

        text: string;

        title: string;

        recipients: Maybe<(Maybe<string>)[]>;

        sender: Maybe<string>;

        data: Maybe<string>;
    };
}

export namespace ProjectGroupsSmartlabUsersWhere {
    export type Variables = {
        ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsersBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        ProjectGroupsSmartlabUsers: ProjectGroupsSmartlabUsers[];
    };

    export type ProjectGroupsSmartlabUsers = {
        __typename?: "ProjectGroupsSmartlabUsers";

        ProjectGroup: ProjectGroup;
    };

    export type ProjectGroup = {
        __typename?: "ProjectGroups";

        Id: Uuid;

        Name: Maybe<string>;
    };
}

export namespace ReportProjectFilesWhere {
    export type Variables = {
        ReportProjectFiles_bool_exp: ReportProjectFilesBoolExp;
        ReportProjectFiles_order_by?: Maybe<ReportProjectFilesOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        ReportProjectFiles: ReportProjectFiles[];
    };

    export type ReportProjectFiles = {
        __typename?: "ReportProjectFiles";

        Created: Maybe<Timestamptz>;

        DriveItemId: Maybe<string>;

        Modified: Maybe<Timestamptz>;

        Name: Maybe<string>;

        ProjectCode: Maybe<string>;

        Size: Maybe<Bigint>;

        Type: Maybe<string>;

        WebUrl: Maybe<string>;
    };
}

export namespace SmartlabPfdUnitLinksWhere {
    export type Variables = {
        SmartlabPFDUnitLinks_bool_exp: SmartlabPfdUnitLinksBoolExp;
        SmartlabPFDUnitLinks_order_by?: Maybe<SmartlabPfdUnitLinksOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        SmartlabPFDUnitLinks: SmartlabPfdUnitLinks[];
    };

    export type SmartlabPfdUnitLinks = {
        __typename?: "SmartlabPFDUnitLinks";

        Type: Maybe<string>;

        PFDUnitId: string;

        Name: Maybe<string>;

        Id: Uuid;

        Description: Maybe<string>;

        Link: Maybe<string>;

        Order: number;

        Targets: Maybe<_Text>;

        PFDTagLink: Maybe<string>;
    };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";

// ====================================================
// Apollo Services
// ====================================================

@Injectable({
    providedIn: "root"
})
export class CreateDataProcConfGQL extends Apollo.Mutation<
    CreateDataProcConf.Mutation,
    CreateDataProcConf.Variables
> {
    document: any = gql`
        mutation createDataProcConf($dataProcConfInput: ConfigurationInput!) {
            createDataProcConf(dataProcConfInput: $dataProcConfInput) {
                id
                frequencyMinutes
                ewon {
                    uri
                    account
                    devId
                    username
                    password
                    rowThreshold
                }
                influx {
                    database
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class CreateNotificationGQL extends Apollo.Mutation<
    CreateNotification.Mutation,
    CreateNotification.Variables
> {
    document: any = gql`
        mutation CreateNotification($notificationInput: NotificationInput!) {
            createNotification(notificationInput: $notificationInput) {
                id
                text
                title
                data
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class DeleteCalendarEventsGQL extends Apollo.Mutation<
    DeleteCalendarEvents.Mutation,
    DeleteCalendarEvents.Variables
> {
    document: any = gql`
        mutation delete_CalendarEvents(
            $CalendarEvents_bool_exp: CalendarEvents_bool_exp!
        ) {
            __typename
            delete_CalendarEvents(where: $CalendarEvents_bool_exp) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class DeleteLaboScheduleStatesGQL extends Apollo.Mutation<
    DeleteLaboScheduleStates.Mutation,
    DeleteLaboScheduleStates.Variables
> {
    document: any = gql`
        mutation delete_LaboScheduleStates(
            $LaboScheduleStates_bool_exp: LaboScheduleStates_bool_exp!
        ) {
            __typename
            delete_LaboScheduleStates(where: $LaboScheduleStates_bool_exp) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class DeleteLaboScheduleStatesPlanningGQL extends Apollo.Mutation<
    DeleteLaboScheduleStatesPlanning.Mutation,
    DeleteLaboScheduleStatesPlanning.Variables
> {
    document: any = gql`
        mutation delete_LaboScheduleStatesPlanning(
            $LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanning_bool_exp!
        ) {
            __typename
            delete_LaboScheduleStatesPlanning(
                where: $LaboScheduleStatesPlanning_bool_exp
            ) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class InsertCalendarEventsGQL extends Apollo.Mutation<
    InsertCalendarEvents.Mutation,
    InsertCalendarEvents.Variables
> {
    document: any = gql`
        mutation insert_CalendarEvents(
            $CalendarEvents_insert_input: [CalendarEvents_insert_input!]!
        ) {
            __typename
            insert_CalendarEvents(objects: $CalendarEvents_insert_input) {
                returning {
                    UserId
                    Title
                    Start
                    ResizableBeforeStart
                    ResizableAfterEnd
                    ProjectCode
                    MetaNotes
                    MetaLocation
                    Id
                    End
                    Draggable
                    ColorSecondary
                    ColorPrimary
                    AllDay
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class InsertLaboScheduleStatesGQL extends Apollo.Mutation<
    InsertLaboScheduleStates.Mutation,
    InsertLaboScheduleStates.Variables
> {
    document: any = gql`
        mutation insert_LaboScheduleStates(
            $LaboScheduleStates_insert_input: [LaboScheduleStates_insert_input!]!
        ) {
            __typename
            insert_LaboScheduleStates(
                objects: $LaboScheduleStates_insert_input
            ) {
                returning {
                    Schedule
                    ProjectId
                    Name
                    Id
                    DeletedAt
                    Deleted
                    CreatedAt
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class InsertLaboScheduleStatesPlanningGQL extends Apollo.Mutation<
    InsertLaboScheduleStatesPlanning.Mutation,
    InsertLaboScheduleStatesPlanning.Variables
> {
    document: any = gql`
        mutation insert_LaboScheduleStatesPlanning(
            $LaboScheduleStatesPlanning_insert_input: [LaboScheduleStatesPlanning_insert_input!]!
        ) {
            __typename
            insert_LaboScheduleStatesPlanning(
                objects: $LaboScheduleStatesPlanning_insert_input
            ) {
                returning {
                    Id
                    Planned
                    StateId
                    ProjectId
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class UpdateCalendarEventsGQL extends Apollo.Mutation<
    UpdateCalendarEvents.Mutation,
    UpdateCalendarEvents.Variables
> {
    document: any = gql`
        mutation update_CalendarEvents(
            $CalendarEvents_set_input: CalendarEvents_set_input!
            $CalendarEvents_bool_exp: CalendarEvents_bool_exp!
        ) {
            __typename
            update_CalendarEvents(
                _set: $CalendarEvents_set_input
                where: $CalendarEvents_bool_exp
            ) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class UpdateDataProcConfsGQL extends Apollo.Mutation<
    UpdateDataProcConfs.Mutation,
    UpdateDataProcConfs.Variables
> {
    document: any = gql`
        mutation UpdateDataProcConfs(
            $id: ID!
            $dataProcConfInput: ConfigurationInput!
        ) {
            updateDataProcConf(id: $id, dataProcConfInput: $dataProcConfInput) {
                id
                frequencyMinutes
                ewon {
                    uri
                    account
                    devId
                    username
                    password
                    rowThreshold
                }
                influx {
                    database
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class UpdateLaboScheduleStatesGQL extends Apollo.Mutation<
    UpdateLaboScheduleStates.Mutation,
    UpdateLaboScheduleStates.Variables
> {
    document: any = gql`
        mutation update_LaboScheduleStates(
            $LaboScheduleStates_set_input: LaboScheduleStates_set_input!
            $LaboScheduleStates_bool_exp: LaboScheduleStates_bool_exp!
        ) {
            __typename
            update_LaboScheduleStates(
                _set: $LaboScheduleStates_set_input
                where: $LaboScheduleStates_bool_exp
            ) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class UpdateNotificationGQL extends Apollo.Mutation<
    UpdateNotification.Mutation,
    UpdateNotification.Variables
> {
    document: any = gql`
        mutation UpdateNotification(
            $id: ID!
            $notificationInput: NotificationInput!
        ) {
            updateNotification(id: $id, notificationInput: $notificationInput) {
                id
                text
                title
                data
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class BrandingWhereGQL extends Apollo.Query<
    BrandingWhere.Query,
    BrandingWhere.Variables
> {
    document: any = gql`
        query BrandingWhere(
            $Branding_bool_exp: Branding_bool_exp!
            $Branding_order_by: [Branding_order_by!]
        ) {
            __typename
            Branding(where: $Branding_bool_exp, order_by: $Branding_order_by) {
                AlertsEnabled
                AlertsTitle
                CalendarEnabled
                CalendarTitle
                Domain
                LaboratoryEnabled
                LaboratoryTitle
                LogbookEnabled
                LogbookTitle
                Logo
                EnableBigLogo
                MaintenanceEnabled
                MaintenanceTitle
                MyPlantsTitle
                MyPlantsTableTitle
                Name
                OperatorEnabled
                OperatorTitle
                PlantEnabled
                PlantTitle
                PrimaryColor
                ReportsEnabled
                ReportsTitle
                SecondaryColor
                TechnologiesEnabled
                TechnologiesTitle
                UtilitiesEnabled
                UtilitiesTitle
                PlantOverrideWithPFDUnitId
                FavIcon
                AllowedProjects
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class CalendarEventsWhereGQL extends Apollo.Query<
    CalendarEventsWhere.Query,
    CalendarEventsWhere.Variables
> {
    document: any = gql`
        query CalendarEventsWhere(
            $CalendarEvents_bool_exp: CalendarEvents_bool_exp!
            $CalendarEvents_order_by: [CalendarEvents_order_by!]
        ) {
            __typename
            CalendarEvents(
                where: $CalendarEvents_bool_exp
                order_by: $CalendarEvents_order_by
            ) {
                UserId
                Title
                Start
                ResizableBeforeStart
                ResizableAfterEnd
                ProjectCode
                MetaNotes
                MetaLocation
                Id
                End
                Draggable
                ColorSecondary
                ColorPrimary
                AllDay
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ClientGroupsSmartlabUsersWhereGQL extends Apollo.Query<
    ClientGroupsSmartlabUsersWhere.Query,
    ClientGroupsSmartlabUsersWhere.Variables
> {
    document: any = gql`
        query ClientGroupsSmartlabUsersWhere(
            $ClientGroupsSmartlabUsers_bool_exp: ClientGroupsSmartlabUsers_bool_exp!
        ) {
            __typename
            ClientGroupsSmartlabUsers(
                where: $ClientGroupsSmartlabUsers_bool_exp
            ) {
                ClientGroup {
                    Id
                    Name
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class DataProcConfsGQL extends Apollo.Query<
    DataProcConfs.Query,
    DataProcConfs.Variables
> {
    document: any = gql`
        query DataProcConfs($filter: String, $id: ID) {
            dataProcConfs(filter: $filter, id: $id) {
                id
                frequencyMinutes
                ewon {
                    uri
                    account
                    devId
                    username
                    password
                    rowThreshold
                }
                influx {
                    database
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class LaboScheduleStatesPlanningWhereGQL extends Apollo.Query<
    LaboScheduleStatesPlanningWhere.Query,
    LaboScheduleStatesPlanningWhere.Variables
> {
    document: any = gql`
        query LaboScheduleStatesPlanningWhere(
            $Limit: Int!
            $LaboScheduleStatesPlanning_order_by: [LaboScheduleStatesPlanning_order_by!]
            $LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanning_bool_exp!
        ) {
            __typename
            LaboScheduleStatesPlanning(
                limit: $Limit
                order_by: $LaboScheduleStatesPlanning_order_by
                where: $LaboScheduleStatesPlanning_bool_exp
            ) {
                Id
                Planned
                StateId
                ProjectId
                LaboScheduleState {
                    Schedule
                    Id
                    Name
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class LaboScheduleStatesWhereGQL extends Apollo.Query<
    LaboScheduleStatesWhere.Query,
    LaboScheduleStatesWhere.Variables
> {
    document: any = gql`
        query laboScheduleStatesWhere(
            $LaboScheduleStates_bool_exp: LaboScheduleStates_bool_exp!
        ) {
            __typename
            LaboScheduleStates(where: $LaboScheduleStates_bool_exp) {
                Schedule
                ProjectId
                Name
                Id
                DeletedAt
                Deleted
                CreatedAt
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class NotificationsGQL extends Apollo.Query<
    Notifications.Query,
    Notifications.Variables
> {
    document: any = gql`
        query Notifications($filter: String, $id: ID) {
            notifications(filter: $filter, id: $id) {
                id
                text
                title
                recipients
                sender
                data
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ProjectGroupsSmartlabUsersWhereGQL extends Apollo.Query<
    ProjectGroupsSmartlabUsersWhere.Query,
    ProjectGroupsSmartlabUsersWhere.Variables
> {
    document: any = gql`
        query ProjectGroupsSmartlabUsersWhere(
            $ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsers_bool_exp!
        ) {
            __typename
            ProjectGroupsSmartlabUsers(
                where: $ProjectGroupsSmartlabUsers_bool_exp
            ) {
                ProjectGroup {
                    Id
                    Name
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ReportProjectFilesWhereGQL extends Apollo.Query<
    ReportProjectFilesWhere.Query,
    ReportProjectFilesWhere.Variables
> {
    document: any = gql`
        query ReportProjectFilesWhere(
            $ReportProjectFiles_bool_exp: ReportProjectFiles_bool_exp!
            $ReportProjectFiles_order_by: [ReportProjectFiles_order_by!]
        ) {
            __typename
            ReportProjectFiles(
                where: $ReportProjectFiles_bool_exp
                order_by: $ReportProjectFiles_order_by
            ) {
                Created
                DriveItemId
                Modified
                Name
                ProjectCode
                Size
                Type
                WebUrl
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class SmartlabPfdUnitLinksWhereGQL extends Apollo.Query<
    SmartlabPfdUnitLinksWhere.Query,
    SmartlabPfdUnitLinksWhere.Variables
> {
    document: any = gql`
        query SmartlabPFDUnitLinksWhere(
            $SmartlabPFDUnitLinks_bool_exp: SmartlabPFDUnitLinks_bool_exp!
            $SmartlabPFDUnitLinks_order_by: [SmartlabPFDUnitLinks_order_by!]
        ) {
            __typename
            SmartlabPFDUnitLinks(
                where: $SmartlabPFDUnitLinks_bool_exp
                order_by: $SmartlabPFDUnitLinks_order_by
            ) {
                Type
                PFDUnitId
                Name
                Id
                Description
                Link
                Order
                Targets
                PFDTagLink
            }
        }
    `;
}

// ====================================================
// END: Apollo Angular template
// ====================================================
